import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import useAxios from "../hooks/useAxios";

function DelliganceAcceptance() {
  const [deligData, setDeligData] = useState();
  const toast = useRef(null);
  const { http } = useAxios();
  const getDueDeligance = async () => {
    const response = await http.get(
      `/DueDiligence/GetAllDueDiligenceQueue?Filters=status==1`
    );
    setDeligData(response.data);
  };
  useEffect(() => {
    getDueDeligance();
  }, []);
  const toastFunction = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
  const answerRequest = async (data, deligance) => {
    try {
      const response = await http.put(`/DueDiligence/${data.id}`, {
        status: deligance,
      });
      toastFunction(
        "success",
        "Success",
        `Permission ${deligance == 2 ? "accepted" : "denied"} successfully`
      );
      getDueDeligance();
    } catch (err) {
      console.error(err);
    }
  };
  const actionBodyTemplate = (data) => {
    return (
      <div>
        <i
          onClick={() => {
            answerRequest(data, 2);
          }}
          className='pi cursor-pointer pi-check text-green-600 p-5'
        ></i>
        <i
          onClick={() => {
            answerRequest(data, 3);
          }}
          className='pi cursor-pointer pi-times text-red-600 p-5'
        ></i>
      </div>
    );
  };
  return (
    <div className='w-screen flex justify-center p-10'>
      <div className='min-w-[70vw]'>
        <Toast ref={toast} />
        <DataTable value={deligData}>
          <Column field='id' header='Code'></Column>
          <Column field='userName' header='User Name'></Column>
          <Column header='Action' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default DelliganceAcceptance;
