import React, { useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useSwitchOrdering } from "../../store";

function PinStatusPanal() {
  const msgs = useRef(null);
  const { isOrderingMode } = useSwitchOrdering();
  const navigate = useNavigate();
  const navigateTo = !isOrderingMode
    ? "/ordering/settings/Security"
    : "/rendering/settings/Security";
  useEffect(() => {
    if (msgs?.current) {
      msgs.current.clear();
    }
    msgs.current.show({
      sticky: true,
      severity: "info",
      detail: (
        <span>
          Ensure only you can approve orders as a provider. Your unique PIN will
          be required to sign off on orders placed by your staff.
          <span
            onClick={() =>
              navigate("/ordering/settings/Security", {
                replace: true,
                state: { activeIndex: 1 },
              })
            }
            label="Click Here"
            link
            className="ml-2 cursor-pointer underline font-bold"
          >
            Click Here
          </span>
        </span>
      ),
      closable: false,
    });
  }, []);

  return <Messages className="mt-[-1%]" ref={msgs} />;
}

export default PinStatusPanal;
