import { Skeleton } from "primereact/skeleton";

const CreateServiceSkeleton = () => {
  return (
    <div className="grid h-full grid-cols-12 gap-4">
      <div
        className="col-span-9 flex flex-col gap-8  overflow-y-auto rounded-lg bg-white px-5 py-10"
        style={{
          height: "calc(100vh - 5rem)",
        }}
      >
        <Skeleton height="28px" width="30%" />
        <div className="flex flex-col gap-6 rounded-lg border border-slate-100 p-5">
          <div className="flex flex-col gap-2">
            <Skeleton height="14px" width="20%" />
            <Skeleton height="28px" />
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton height="14px" width="20%" />
            <Skeleton height="128px" />
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton height="14px" width="20%" />
            <Skeleton height="36px" />
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton height="14px" width="20%" />
            <Skeleton height="88px" />
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton height="14px" width="20%" />
            <Skeleton height="36px" />
          </div>
        </div>
      </div>
      <div className="sticky top-0 col-span-3 hidden h-2/3 flex-col gap-4 rounded-lg bg-white p-5 md:flex">
        {Array.from({ length: 7 }, (_, index) => (
          <div className="flex flex-col gap-2">
            <Skeleton height="28px" />
            <Skeleton height="14px" width="65%" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateServiceSkeleton;
