import React, { useState } from "react";
import { Remits } from "./Remits";
import { Claims } from "./Claims";
import { TabPanel, TabView } from "primereact/tabview";

export const Transactions = () => {
  const [headerActiveIndex, setHeaderActiveIndex] = useState(0);
  const headerMenuItems = [{ label: "Claims" }, { label: "Remits" }];
  return (
    <div className="rounded-t-lg">
      <TabView>
        <TabPanel header="Claims">
          <Claims />
        </TabPanel>
        <TabPanel header="Remits">
          <Remits />
        </TabPanel>
      </TabView>
    </div>
  );
};
