import { useEffect, useRef, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { ProgressBar } from "primereact/progressbar";
import { Accordion, AccordionTab } from "primereact/accordion";

import useAxios from "../hooks/useAxios";
import alldone from "../images/alldone.svg";
import useTokenData from "../hooks/useTokenData";
import { useChecklist, useCurrentEntity } from "../store";
import completeProfile from "../images/profile-complete.png";

export default function CompletionChecklist({ view, isWelcome }) {
  const { http } = useAxios();
  const location = useLocation();

  const { currentEntity } = useCurrentEntity();
  const { shouldUpdateProgress, setShouldUpdateProgress } = useChecklist();
  const {
    userRoles = {},
    entityId = {},
    entityType,
    VerifyProvider,
  } = useTokenData();
  const isMounted = useRef(false);

  const [checklistItems, setChecklistItems] = useState([]);
  const [practiceCompletion, setPracticeCompletion] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(!!isWelcome);

  useEffect(() => {
    if (!isMounted.current) {
      getPracticeChecklist(entityId);
      isMounted.current = true;
    } else {
      if (shouldUpdateProgress) {
        getPracticeChecklist(entityId);
        setShouldUpdateProgress(false);
      }
    }
  }, [entityId, shouldUpdateProgress]);

  const getPracticeChecklist = async (entityId) => {
    try {
      const response = await http.get(
        `Entities/GetPracticeCheckList/${entityId}`,
      );
      const {
        verifiedProvider,
        practiceApproval,
        individualDataCompleted,
        providerDataCompleted,
        isCurrentUserPhoneNumberVerified,
        ...rest
      } = response.data;

      const checklistData =
        view === "order"
          ? {
              practiceApproval,
              verifiedProvider,
              // isCurrentUserPhoneNumberVerified,
            }
          : {
              ...rest,
              practiceApproval,
              // isCurrentUserPhoneNumberVerified,
            };

      if (entityType == 2 && userRoles === "Admin") {
        checklistData.individualDataCompleted = individualDataCompleted;
      }
      const trueCount = Object.values(checklistData).filter(
        (value) => value === true,
      ).length;
      const totalCount = Object.keys(checklistData).length;
      const practiceCompletion = Math.round((trueCount / totalCount) * 100);

      setPracticeCompletion(practiceCompletion);

      const renderingChecklistItems = [
        {
          label: "Locations",
          completed: checklistData.location,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>
                Please, make sure all your practice locations are correctly
                listed and up to date.
              </p>
              <NavLink to="/rendering/mylocations/">
                <Button label="Manage Locations"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Billable Items",
          completed: checklistData.billableItems,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>Add & update billable items for your services</p>
              <NavLink to="/Service/billableItems">
                <Button label="Manage Billable Items"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Create Your First Service",
          completed: checklistData.service,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>
                Ready to begin? Let's create your first service and showcase
                what you have to offer!
              </p>
              <NavLink to="/Service/MyServices/createNewService">
                <Button label="Create Service"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Deposit Method",
          completed: checklistData.depositMethod,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>Set up your deposit method to receive payments</p>
              <NavLink to="/rendering/settings/DepositSettings/">
                <Button label="Set Up Deposit Method"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Practice Approval",
          completed: checklistData.practiceApproval,
          body: (
            <div>
              {checklistData.practiceApproval ? (
                <p>
                  Congratulations! Your practice has been approved. You're now
                  ready to start publishing your services.
                </p>
              ) : (
                <p>
                  Your practice is currently under review. Once approved, you'll
                  be able to publish your services. Thanks for your patience!
                </p>
              )}
            </div>
          ),
        },
        {
          label: "Publish Your First Service",
          completed: checklistData.isServicePublished,
          body: (
            <div>
              {checklistData.isServicePublished ? (
                <div className="flex flex-col gap-3">
                  <p>
                    Congratulations! Your first service has been successfully
                    published! Keep adding more services to expand your
                    practice's offerings.
                  </p>
                  <NavLink to="/service/MyServices" state={{ activeIndex: 1 }}>
                    <Button
                      onClick={(e) => {
                        if (location.pathname === "/service/MyServices") {
                          setShowCompletionDialog(false);
                        }
                      }}
                      label="Add More Services"
                    ></Button>
                  </NavLink>
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <p>
                    Ready to showcase your services to the world? Click below to
                    publish your first service and start reaching your patients.
                  </p>
                  <NavLink to="/service/MyServices" state={{ activeIndex: 1 }}>
                    <Button
                      onClick={(e) => {
                        if (location.pathname === "/service/MyServices") {
                          setShowCompletionDialog(false);
                        }
                      }}
                      label="Publish Service"
                    ></Button>
                  </NavLink>
                </div>
              )}
            </div>
          ),
        },
      ];
      const orderingChecklistItems = [
        {
          label: "Provider Verification",
          completed: checklistData.verifiedProvider,
          body: (
            <>
              <div className="flex flex-col items-start gap-4">
                {checklistData.practiceApproval &&
                VerifyProvider !== "Approved" ? (
                  <p>
                    You can place an order now that your practice has a verified
                    provider. To verify yourself, click the button.
                  </p>
                ) : checklistData.practiceApproval &&
                  VerifyProvider === "Approved" ? (
                  <p>
                    Your practice now has at least one verified provider, you
                    can place an order!
                  </p>
                ) : (
                  <p>
                    To place an order, your practice must have at least one
                    verified provider.
                  </p>
                )}

                {VerifyProvider !== "Approved" && (
                  <NavLink to="/ordering/settings/verifyProvider">
                    <Button
                      outlined
                      label="Verify Yourself As A Provider"
                    ></Button>
                  </NavLink>
                )}
              </div>

              {userRoles === "Admin" && (
                <div className="mt-2 flex flex-col items-start gap-4">
                  <p>
                    If you intend to invite someone else as a provider, click on{" "}
                    <span className="font-base text-light-purple">
                      {" "}
                      'Invite as Provider'
                    </span>{" "}
                    provide their email address, and send the invitation.
                  </p>

                  <NavLink to="/ordering/staff">
                    <Button outlined label="Invite as Provider"></Button>
                  </NavLink>
                </div>
              )}
            </>
          ),
        },
        {
          label: "Practice Approval",
          completed: checklistData.practiceApproval,
          body: (
            <div>
              {checklistData.practiceApproval ? (
                <p>
                  Congratulations! Your practice has been approved. You're all
                  set to start placing orders!
                </p>
              ) : (
                <p>
                  Your practice is currently under review. Once approved, you'll
                  be able to order services. Thanks for your patience!
                </p>
              )}
            </div>
          ),
        },
      ];

      if (entityType == 2 && userRoles === "Admin") {
        const completeIdentityTemplate = {
          label: "Complete Your Identity",
          completed: checklistData.individualDataCompleted,
          body: (
            <div className="flex flex-col items-start justify-start gap-4">
              {checklistData.individualDataCompleted ? (
                <p>Congratulations! You completed your basic practice setup.</p>
              ) : (
                <div className="flex flex-col gap-4">
                  <p>
                    Looks like you haven't filled in the identity and selfie
                    data for your practice yet. Please fill them to ensure your
                    practice can undergo admin review.
                  </p>
                  <NavLink
                    to={
                      view === "render"
                        ? "/rendering/settings/individualSetting"
                        : "/ordering/settings/individualSetting"
                    }
                  >
                    <Button outlined label="Complete identity" />
                  </NavLink>
                </div>
              )}
            </div>
          ),
        };
        orderingChecklistItems.unshift(completeIdentityTemplate);
        renderingChecklistItems.unshift(completeIdentityTemplate);
      }

      setChecklistItems(
        view === "order" ? orderingChecklistItems : renderingChecklistItems,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const checklistHeaderTemplate = (title, completed) => {
    return (
      <div className="flex items-center">
        {completed ? (
          <>
            <i className="pi pi-check-square mr-2 text-lg font-bold text-light-purple"></i>
            <span className="text-gray-500">{title}</span>
          </>
        ) : (
          <>
            <i className="pi pi-stop mr-2 text-lg font-bold"></i>
            <span className="text-gray-700">{title}</span>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <Dialog
        header="Complete your profile"
        blockScroll
        dismissableMask
        draggable={false}
        visible={showCompletionDialog}
        showHeader={false}
        onHide={() => {
          setShowCompletionDialog(false);
        }}
        contentStyle={{ padding: "0", borderRadius: "1rem" }}
        className="h-[40rem] w-11/12 max-w-5xl lg:w-[60vw]"
      >
        <div className="grid h-full grid-cols-12">
          <div className="hidden h-full flex-col items-center justify-center gap-4 bg-slate-50 py-20 md:col-span-5 md:flex">
            <div className="h-36 w-36 overflow-hidden rounded-full border border-slate-200 shadow-lg">
              {currentEntity?.imageUrl ? (
                <img
                  src={currentEntity?.imageUrl}
                  alt="entityImage"
                  className="h-full w-full object-cover"
                ></img>
              ) : (
                <Avatar
                  label={currentEntity?.name && currentEntity?.name[0]}
                  shape="circle"
                  className="h-full w-full bg-blue-500 text-4xl text-white"
                />
              )}
            </div>
            <h2 className="text-lg font-semibold">{currentEntity?.name}</h2>
            <div className="text-center">
              <p className="text-gray-500">{practiceCompletion}% Complete</p>
              <p className="text-lg font-semibold">You're Almost Done!</p>
            </div>
          </div>
          <div className="col-span-12 flex flex-col md:col-span-7">
            <div className="flex flex-col gap-4 border-b border-slate-200 p-8 pb-0">
              <div className="flex items-center justify-between">
                <h2 className="text-center text-2xl font-semibold text-light-purple">
                  Complete your profile
                </h2>
                <i
                  className="pi pi-times cursor-pointer text-lg"
                  onClick={() => {
                    setShowCompletionDialog(false);
                  }}
                ></i>
              </div>
              <p>
                To publish any services you create you have to complete your
                profile
              </p>
              <div className="h-96 overflow-y-auto p-4">
                <Accordion>
                  {checklistItems?.map((item) => {
                    return (
                      <AccordionTab
                        key={item.label}
                        headerTemplate={checklistHeaderTemplate(
                          item.label,
                          item.completed,
                        )}
                      >
                        {item.body}
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              </div>
            </div>
            <div className="flex flex-grow items-center justify-end px-6">
              <Button
                onClick={() => {
                  setShowCompletionDialog(false);
                }}
                label="Close"
                severity="Primary"
                rounded
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div className="flex w-full flex-col items-center justify-center gap-3 rounded-lg border border-slate-100 bg-white p-4 py-8">
        <div>
          <img
            src={practiceCompletion === 100 ? alldone : completeProfile}
            alt="profile completion"
          />
        </div>
        <h2 className="text-center font-bold">
          {practiceCompletion === 0
            ? "Getting Started"
            : practiceCompletion > 0 && practiceCompletion <= 50
              ? "Making Progress"
              : practiceCompletion > 50 && practiceCompletion < 100
                ? "Your Profile Almost Done"
                : "All Done"}
        </h2>

        <p className="px-4 text-center text-sm font-semibold text-gray-400">
          {practiceCompletion === 100
            ? view === "order"
              ? "You've completed all steps. Your practice is now ready to order services!"
              : "You've completed all steps. Your practice is now ready to render services!"
            : "To publish your services, please take a moment to complete your profile."}
        </p>
        <div className="flex w-10/12 items-center gap-3">
          <ProgressBar
            showValue={false}
            value={practiceCompletion}
            className="h-2 w-full"
          ></ProgressBar>
          <span className="text-sm font-bold text-light-purple">
            {practiceCompletion}%
          </span>
        </div>
        {practiceCompletion < 100 && (
          <p
            onClick={() => {
              setShowCompletionDialog(true);
            }}
            className="cursor-pointer font-bold text-light-purple underline underline-offset-2"
          >
            Complete your profile
          </p>
        )}
      </div>
    </>
  );
}
