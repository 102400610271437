import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { blockSpecialCharsPattern, formatDate } from "./utility/Utils";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

const ReusableTable = ({
  columns,
  actionTemplates,
  sendSearchData,
  searchValue,
  fromFront = false,
  RemitsCheck = false,
  title,
  headerAction,
  selectionRowFunction = false,
  onSelectionChange,
  disableSearch = false,
  searchTitle,
  dropdownValues = false,
  tableData,
  globalFilterValue,
  onGlobalFilterChange,
  filters,
  scrollable,
  scrollHeight,
  emptyMessage,
  isLoading,
  selectedRows = [],
  setSelectedRemits,
  isRowSelectable,
  rowClassName,
  rightToolbarTemplate,
}) => {
  const [SelectedRows, setSelectedRows] = useState([]);

  const renderActionTemplate = (rowData, index) => {
    // Customize this function based on your actionTemplate logic
    return actionTemplates[index].template(rowData);
  };

  useEffect(() => {
    if (!RemitsCheck) {
      setSelectedRows(selectedRows);
    } else {
      setSelectedRemits(selectedRows);
    }
  }, []);
  useEffect(() => {
    if (selectedRows?.length) {
      const ids = selectedRows?.map((item) => item.id);
      setSelectedRows(() => {
        return tableData.filter((item) => ids.includes(item.id));
      });
    }
  }, [tableData]);

  const header = !RemitsCheck ? (
    <div className="flex flex-col items-start gap-4">
      <p className="font-inter font-semibold">{title}</p>
      <div className="flex w-full items-center justify-between">
        <div>
          {!disableSearch && (
            <div className="flex items-center gap-4">
              <span className="p-input-icon-left min-w-[300px]">
                <i className="pi pi-search" />
                <InputText
                  className="w-full"
                  keyfilter={
                    dropdownValues?.selectedValue === "OrderTrackingNumber@=*"
                      ? "int"
                      : blockSpecialCharsPattern
                  }
                  placeholder={searchTitle || "Search"}
                  value={!fromFront ? searchValue : globalFilterValue}
                  onChange={!fromFront ? sendSearchData : onGlobalFilterChange}
                />
              </span>
              {dropdownValues && (
                <div className="flex items-center gap-2">
                  <span>Search by</span>
                  <Dropdown
                    className="w-[200px]"
                    options={dropdownValues?.options}
                    onChange={(e) => dropdownValues?.setSelectedValue(e.value)}
                    value={dropdownValues?.selectedValue}
                    optionLabel="name"
                    optionValue="value"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {headerAction}
      </div>
    </div>
  ) : (
    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
  );

  return (
    <>
      <DataTable
        value={tableData}
        paginator={fromFront}
        rows={5}
        header={header}
        scrollable={scrollable}
        emptyMessage={
          emptyMessage ? (
            emptyMessage
          ) : searchValue ? (
            <p className="text-center">No results found.</p>
          ) : undefined
        }
        pt={{
          loadingOverlay: {
            style: {
              backgroundColor: "#b9b5ff60",
            },
          },
        }}
        loading={isLoading}
        scrollHeight={scrollable && scrollHeight ? scrollHeight : undefined}
        filters={fromFront ? filters : undefined}
        onRowClick={
          RemitsCheck
            ? undefined
            : (rowData) => {
                if (selectionRowFunction) {
                  if (onSelectionChange(rowData)) return;
                  if (
                    !SelectedRows?.some((data) => data.id === rowData.data.id)
                  ) {
                    setSelectedRows((prevData) => [...prevData, rowData.data]);
                  } else {
                    const index = SelectedRows?.findIndex(
                      (obj) => obj.id == rowData.data.id,
                    );
                    const selectedClones = SelectedRows;
                    selectedClones.splice(index, 1);
                    setSelectedRows(selectedClones);
                  }
                }
              }
        }
        onSelectionChange={
          RemitsCheck
            ? (e) => {
                setSelectedRows(e.value);
                setSelectedRemits(e.value);
              }
            : undefined
        }
        globalFilterFields={fromFront ? [columns[0].value] : undefined}
        selectionMode={onSelectionChange ? "multiple" : undefined}
        selection={SelectedRows}
        isDataSelectable={isRowSelectable}
        rowClassName={rowClassName}
        className="hidden sm:block"
      >
        {RemitsCheck && (
          <Column selectionMode="multiple" exportable={false}></Column>
        )}

        {columns.map((column) => (
          <Column
            key={column.name}
            header={column.name}
            headerClassName="whitespace-nowrap"
            style={column.style ? column.style : null}
            body={(rowData) => {
              if (column.name?.toLowerCase().includes("date")) {
                return (
                  rowData[column.value] && formatDate(rowData[column.value])
                );
              } else if (
                column.template &&
                typeof column.template === "function"
              ) {
                return column.template(rowData);
              } else {
                return rowData[column.value];
              }
            }}
          />
        ))}

        {actionTemplates &&
          actionTemplates.map((template, index) => (
            <Column
              key={`action_${index}`}
              header={template.header}
              headerClassName="whitespace-nowrap"
              style={template.style ? template.style : null}
              body={(rowData) => renderActionTemplate(rowData, index)}
            />
          ))}
      </DataTable>
    </>
  );
};
export default ReusableTable;
