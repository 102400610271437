import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { ProviderInfoCard } from "../ProviderInfoCard";
import { ProviderInfoCardSkeleton } from "../../skeletons/ProviderInfoCardSkeleton";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

export const Provider = () => {
  const { id } = useParams();
  const { http } = useAxios();
  const [providerData, setProviderData] = useState();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imageProviderImageSrc, setProviderImageSrc] = useState();
  const [showDeclineReasonDialog, setShowDeclineReasonDialog] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: { id: "", status: 3, denialReason: "" },
  });

  const getProviderById = async () => {
    const response = await http.get(`/DueDiligence/GetDueDiligenceById/${id}`);

    setProviderImageSrc(response.data.entity.imageUrl);
    setProviderData(response.data);
  };

  useEffect(() => {
    getProviderById();
  }, []);
  const approve = (id) => {
    confirmDialog({
      message: "Are you sure you want to approve?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        setIsLoading(true);
        try {
          const response = await http.put(`/DueDiligence/${id}`, { status: 2 });
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Approved Successfully",
            life: 3000,
          });
          navigate(-1, { replace: true });
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error.message || "Something went wrong. Please try again later",
            life: 3000,
          });
        }
        setIsLoading(false);
      },
    });
  };

  const decline = (id) => {
    confirmDialog({
      message: "Are you sure you want to decline?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setValue("id", id);
        setShowDeclineReasonDialog(true);
      },
    });
  };
  const submitDeclineReason = async (formData) => {
    const id = formData.id;
    delete formData.id;

    try {
      const response = await http.put(`/DueDiligence/${id}`, formData);
      reset();
      setShowDeclineReasonDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Declined Successfully",
        life: 3000,
      });
      navigate(-1, { replace: true });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };

  return (
    <div className="rounded-lg">
      <Toast ref={toast} />
      <Dialog
        visible={showDeclineReasonDialog}
        blockScroll
        draggable={false}
        onHide={() => {
          setShowDeclineReasonDialog(false);
        }}
        className="min-w-max sm:w-[50vw]"
      >
        <div>
          <form onSubmit={handleSubmit(submitDeclineReason)}>
            <div className="flex flex-col gap-2">
              <label className="font-bold text-lg" htmlFor="denialReason">
                Denial Reason<span className="text-red-500"> *</span>
              </label>
              <Controller
                name="denialReason"
                control={control}
                rules={{ required: "Denial Reason Is required" }}
                render={({ field }) => (
                  <InputTextarea
                    id="denialReason"
                    placeholder="Enter Denial Reason"
                    rows={5}
                    cols={30}
                    className="w-full"
                    {...field}
                  />
                )}
              />
              {errors.denialReason && (
                <div className="text-red-500">
                  {errors.denialReason.message}
                </div>
              )}
            </div>
            <div className="flex justify-end gap-3 py-3">
              <Button
                severity="danger"
                type="button"
                label="Cancel"
                disabled={isSubmitting}
                onClick={() => {
                  setShowDeclineReasonDialog(false);
                  reset();
                }}
              />
              <Button label="Save" loading={isSubmitting}></Button>
            </div>
          </form>
        </div>
      </Dialog>
      <ConfirmDialog />
      {providerData ? (
        <ProviderInfoCard
          isLoading={isLoading}
          name={
            providerData.entity.entityType === 1
              ? providerData.entity.name
              : providerData.userName
          }
          tag={providerData.entity.entityType === 1 ? "practice" : "individual"}
          createdAt={providerData.entity.createdAt}
          taxId={providerData.identification.taxID}
          entityName={providerData.entity.name}
          ownerPhoneNumber={providerData.ownerPhoneNumber}
          entityAddress={
            providerData.entity.entityPrincipalAddress &&
            providerData.entity.entityPrincipalAddress.lineOne +
              ", " +
              providerData.entity.entityPrincipalAddress.state +
              ", " +
              providerData.entity.entityPrincipalAddress.city +
              ", " +
              providerData.entity.entityPrincipalAddress.zipCode
          }
          NPIs={providerData.npIs}
          stateLicences={providerData.medicalLicenses}
          taxonomies={providerData.assignTaxonomies}
          entityId={providerData?.identification?.entityId}
          agentAddress={providerData.entity.registeredAgentAddress}
          contactInfo={[
            providerData.entity.technicalSupportName,
            providerData.entity.technicalSupportPhoneNumber,
            providerData.entity.technicalSupportEmail,
          ]}
          clickable={false}
          registeredBy={[
            providerData.userName,
            providerData.registeredPhoneNumber,
            providerData.registeredEmail,
          ]}
          onApproveClick={() => approve(id)}
          onDeclineClick={() => decline(id)}
          identityImage={`${providerData.identification.identityImage}`}
          selfieImage={`${providerData.identification.selfieImage}`}
          imageProviderImageSrc={imageProviderImageSrc}
          status={providerData.status}
        />
      ) : (
        <ProviderInfoCardSkeleton infoCount={7} />
      )}
    </div>
  );
};
