import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";

import useAxios from "../../../hooks/useAxios";
import { ProviderInfoCard } from "../ProviderInfoCard";
import useHandleResize from "../../../hooks/useHandleResize";
import { ProviderInfoCardSkeleton } from "../../skeletons/ProviderInfoCardSkeleton";

export const VerificationQueuePractice = ({ url, tag, searchValue }) => {
  const [practiceData, setPracticeData] = useState(null);
  const [practiceCount, setPracticeCount] = useState(null);
  const [dataStatus, setDataStatus] = useState("idle");
  const toast = useRef(null);
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState();
  const [showDeclineReasonDialog, setShowDeclineReasonDialog] = useState(false);

  // Handle Paginator
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const { isMobile } = useHandleResize();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: { id: "", status: 3, denialReason: "" },
  });

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    if (first !== event.first) {
      getPractices(undefined, event.page + 1, rows);
    }
  };

  const getPractices = async (searchText, page, pageSize) => {
    setDataStatus("loading");
    try {
      const response = await http.get(
        `${url}${searchText || ""}&Page=${page || 1}&PageSize=${pageSize || 10}`,
      );
      setPracticeData(response.data.verificationQueues);

      setPracticeCount(response.data.count);
      if (response.data.verificationQueues[0]) {
        setDataStatus("success");
      } else {
        setDataStatus("no-data");
      }
    } catch (error) {
      console.error(error);
      setDataStatus("error");
    }
  };
  const [Fee, setFee] = useState();

  const approve = (id) => {
    confirmDialog({
      message: "Are you sure you want to approve?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        setIsLoading(id);
        try {
          const response = await http.put(`/DueDiligence/${id}`, { status: 2 });
          setFee(response.data.fee);
          if (response.data.showFeeToaster === true) {
            setVisible(true);
            getPractices();
            toast.current.show({
              severity: "success",
              summary: "success",
              detail: "Updated successfully ",
              life: 3000,
            });
          } else {
            getPractices();
            toast.current.show({
              severity: "success",
              summary: "success",
              detail: "Updated successfully",
              life: 3000,
            });
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error.message || "Something went wrong. Please try again later",
            life: 3000,
          });
        }
        setIsLoading(false);
      },
    });
  };

  const decline = (id) => {
    confirmDialog({
      message: "Are you sure you want to decline?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setValue("id", id);
        setShowDeclineReasonDialog(true);
      },
    });
  };

  const submitDeclineReason = async (formData) => {
    const id = formData.id;
    delete formData.id;

    try {
      const response = await http.put(`/DueDiligence/${id}`, formData);
      getPractices();
      reset();
      setShowDeclineReasonDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Declined Successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };

  const navigate = useNavigate();
  const ModifyFee = () => {
    navigate("/admin/ourPractices", {
      replace: true,
    });
  };
  useEffect(() => {
    setFirst(0);
    const delayTimer = setTimeout(() => {
      getPractices(searchValue);
    }, 700);

    return () => clearTimeout(delayTimer);
  }, [searchValue]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={showDeclineReasonDialog}
        blockScroll
        draggable={false}
        onHide={() => {
          setShowDeclineReasonDialog(false);
        }}
        className="min-w-max sm:w-[50vw]"
      >
        <div>
          <form onSubmit={handleSubmit(submitDeclineReason)}>
            <div className="flex flex-col gap-2">
              <label className="font-bold text-lg" htmlFor="denialReason">
                Denial Reason<span className="text-red-500"> *</span>
              </label>
              <Controller
                name="denialReason"
                control={control}
                rules={{ required: "Denial reason is required" }}
                render={({ field }) => (
                  <InputTextarea
                    id="denialReason"
                    placeholder="Enter Denial Reason"
                    rows={5}
                    cols={30}
                    className="w-full"
                    {...field}
                  />
                )}
              />
              {errors.denialReason && (
                <div className="text-red-500">
                  {errors.denialReason.message}
                </div>
              )}
            </div>
            <div className="flex justify-end gap-3 py-3">
              <Button
                severity="danger"
                type="button"
                label="Cancel"
                disabled={isSubmitting}
                onClick={() => {
                  setShowDeclineReasonDialog(false);
                  reset();
                }}
              />
              <Button label="Save" loading={isSubmitting}></Button>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        header="Modify Fee"
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        className="min-w-[30rem]"
      >
        <div>
          <p className="">
            The fees are set up to {Fee}%, if you wish to modify it.
            <span
              className="cursor-pointer pl-2 underline capitalize text-blue-500"
              onClick={ModifyFee}
            >
              Click here
            </span>
          </p>
        </div>
      </Dialog>
      {dataStatus === "loading" ? (
        <ProviderInfoCardSkeleton />
      ) : dataStatus === "no-data" ? (
        <p className="mt-1 rounded border-b-2 bg-white p-2 text-gray-400">
          No results found
        </p>
      ) : (
        practiceData?.map((data) => (
          <div key={data.id} className="border-b py-1">
            <ProviderInfoCard
              isLoading={data.id === isLoading ? true : false}
              name={tag === "practice" ? data.entity.name : data.userName}
              providerId={data.id}
              createdAt={data.entity.createdAt}
              status={data.status}
              ownerEmail={data.entity.ownerEmail}
              onApproveClick={() => approve(data.id)}
              onDeclineClick={() => decline(data.id)}
              imageProviderImageSrc={data.entity.imageUrl}
            />
          </div>
        ))
      )}
      {practiceCount > 0 && (
        <Paginator
          first={first}
          rows={rows}
          totalRecords={practiceCount}
          onPageChange={onPageChange}
          template={
            isMobile
              ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
              : {
                  layout:
                    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                }
          }
        />
      )}
    </>
  );
};
