import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useAxios from "../hooks/useAxios";
import { useShowSessionExpiredDialog } from "../store";

export default function LogOutDialog() {
  const { logOut } = useAxios();
  const { sessionExpiredData, setSessionExpiredData } =
    useShowSessionExpiredDialog();
  const navigate = useNavigate();

  const dialogContent = {
    401: {
      icon: "pi pi-history",
      header: "Your session has expired",
      content: "Please login again",
      buttonLabel: "Back to login",
    },
    403: {
      icon: "pi pi-lock",
      header: "Access denied",
      content:
        "You do not have the required privileges to access this resource",
      buttonLabel: "Back to dashboard",
    },
  };

  return (
    <Dialog
      draggable={false}
      visible={sessionExpiredData?.visible}
      className="w-11/12 max-w-2xl"
      onHide={() => {
        setSessionExpiredData({ visible: false, code: 0 });
      }}
      pt={{
        header: {
          style: {
            display: "none",
          },
        },
        content: {
          style: {
            background: "#e2e8f0",
            padding: "4rem 0",
          },
        },
      }}
      closable={false}
    >
      <div className="flex w-full flex-col items-center justify-center gap-8 text-gray-500">
        <div className="flex flex-col items-center gap-4">
          <i
            className={`${dialogContent[sessionExpiredData?.code]?.icon} rounded-full text-5xl`}
          ></i>
          <p className="text-center text-4xl text-gray-700">
            {dialogContent[sessionExpiredData?.code]?.header}
          </p>
          <p className="text-center">
            {dialogContent[sessionExpiredData?.code]?.content}
          </p>
        </div>
        <Button
          onClick={() => {
            setSessionExpiredData({ visible: false, code: 0 });
            if (sessionExpiredData?.code === 401) {
              logOut();
            } else {
              navigate("/Dashboard/");
            }
          }}
          rounded
          className="px-8"
          label={dialogContent[sessionExpiredData?.code]?.buttonLabel}
        />
      </div>
    </Dialog>
  );
}
