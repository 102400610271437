import React, { useEffect, useState, useRef } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { MicrosoftLogin } from "react-microsoft-login";
import { Button } from "primereact/button";


function Google() {

    const [msalInstance, onMsalInstanceChange] = useState();
    const authHandler = async (err, data, msal) => {
        const tokenDataString = sessionStorage.getItem('00000000-0000-0000-0020-1f5669d8ea4a.9188040d-6c67-4c5b-b112-36a304b66dad-login.windows.net-accesstoken-d5f18037-5667-40c6-9498-f37828c30e97-9188040d-6c67-4c5b-b112-36a304b66dad-user.read openid profile--');
        const tokenData = JSON.parse(tokenDataString);
        const accessToken = tokenData?.secret;

        if (!err && data) {
            onMsalInstanceChange(msal);
        }

        try {
            const response = await axios.post(`Authentication/MicrosoftLogin?token=${accessToken}`)
      
        } catch (error) {
            console.error("Microsoft login error:", error);
        }

    };

    const logoutHandler = () => {
        msalInstance.logout();
    };


    return (
        <div>
            <GoogleOAuthProvider clientId="1094559917244-mc9dmm3g6jj1b0d5ejs6pooaevng1235.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        const googleData = {
                            provider: "GOOGLE",
                            idToken: credentialResponse.credential
                        };
            
                        try {
                            const response = axios.post(
                                "Authentication/ExternalLogin", googleData
                            );
                   
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                    onError={() => {
                        // console.log('Login Failed');
                    }}
                    context='signup'

                />


            </GoogleOAuthProvider>
            {msalInstance ?
                (
                    <Button onClick={logoutHandler}>Logout</Button>
                ) : (<MicrosoftLogin clientId='736fdbcc-003f-4597-a7e9-9ef7947c56c9' authCallback={authHandler} />)}



        </div>
    )
}

export default Google