import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { formatDate } from "./utility/Utils";
import useAxios from "../hooks/useAxios";

function PaymentSuccess({ receipt }) {
  const {
    receiptUrl = null,
    ammountPaid = null,
    date = null,
    cardLast4 = null,
    brand = null,
  } = receipt || {};

  const formattedDate = formatDate(date);
  let formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(ammountPaid / 100);

  return (
    <>
      <div className="flex flex-col gap-3">
        {receiptUrl ? (
          <>
            <div className="grid grid-cols-12 gap-4 rounded-lg bg-slate-50 p-4 shadow-md ">
              <div className="col-span-12 flex gap-2 md:col-span-4 md:flex-col">
                <h3 className="font-semibold text-gray-600">Amount Paid</h3>
                <p className="text-gray-500">{formattedAmount}</p>
              </div>
              <div className="col-span-12 flex gap-2 md:col-span-4 md:flex-col">
                <h3 className="font-semibold text-gray-600">Date Paid</h3>
                <p className="text-gray-500">{formattedDate}</p>
              </div>
              <div className="col-span-12 flex gap-2 md:col-span-4 md:flex-col">
                <h3 className="font-semibold text-gray-600">Payment Method</h3>
                <p className="text-gray-500">
                  <strong className="uppercase">{brand}</strong> - {cardLast4}
                </p>
              </div>
            </div>
            <div className="flex justify-end ">
              <a
                href={receiptUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-sm font-semibold italic text-light-purple underline hover:text-dark-purple "
              >
                Show Full Receipt
              </a>
            </div>
          </>
        ) : (
          <p className="text-center text-gray-700">
            Please check your email for the receipt and confirmation details.
          </p>
        )}
      </div>
    </>
  );
}

export default PaymentSuccess;
