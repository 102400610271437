import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import useTokenData from "../../hooks/useTokenData";
import LocationServicesDialog from "../services/LocationServicesDialog";

function LocationCard({
  location,
  setLocation,
  confirmDelete,
  handleEyeToggle,
  entityPage = false,
}) {
  const [showServices, setShowServices] = useState(false);

  const navigate = useNavigate();

  const { userRoles } = useTokenData();

  const showRelatedServices = (location) => {
    setLocation(location);
    setShowServices(true);
  };

  return (
    <>
      <div
        className={`flex max-w-full  rounded-lg border  p-4   ${
          location.isEnabled == true ? "bg-white" : "bg-gray-50"
        }`}
      >
        <Dialog
          blockScroll
          draggable={false}
          visible={showServices}
          onHide={() => setShowServices(false)}
          header={`Services Rendered at ${location.name}`}
          className="w-11/12 lg:w-[60vw]"
        >
          <LocationServicesDialog location={location} />
        </Dialog>
        <div className="flex w-9/12 flex-col gap-2 ">
          <h3 className={` text-xl font-bold capitalize text-gray-700`}>
            {location.name}{" "}
          </h3>
          <div className="flex items-baseline gap-1">
            <i className="pi pi-map-marker text-gray-700" />
            <p className="inline text-gray-600">{`${location.address.city}, ${location.address.state}, ${location.address.zipCode}`}</p>
          </div>
        </div>
        <div className="flex w-3/12 items-end justify-end gap-3">
          {!entityPage ? (
            userRoles == "Admin" ? (
              <>
                <Tooltip target=".show-services" />
                <i
                  className="pi pi-list show-services mr-2 cursor-pointer text-purple-700"
                  onClick={() => showRelatedServices(location)}
                  data-pr-tooltip="Show Services"
                  data-pr-position="bottom"
                />
                {!location.isPrincipal && (
                  <>
                    <Tooltip target=".edit" />
                    <i
                      className="pi pi-pencil edit mr-2 cursor-pointer text-purple-700"
                      onClick={() => {
                        setLocation(location);
                        navigate(`/rendering/mylocations/locationForm/`, {
                          state: { locationData: location },
                        });
                      }}
                      data-pr-tooltip="Edit"
                      data-pr-position="bottom"
                    />
                    <Tooltip target=".delete" />
                    <i
                      onClick={() => {
                        confirmDelete(location.id);
                      }}
                      className="pi pi-trash delete mr-2 cursor-pointer text-red-600"
                      data-pr-tooltip="Delete"
                      data-pr-position="bottom"
                    />

                    {!location.isEnabled ? (
                      <>
                        <Tooltip target=".enable" />
                        <i
                          className="pi pi-eye-slash enable cursor-pointer"
                          onClick={() => {
                            handleEyeToggle(location);
                          }}
                          data-pr-tooltip="Enable"
                          data-pr-position="bottom"
                        />
                      </>
                    ) : (
                      <>
                        <Tooltip target=".disable" />
                        <i
                          className="pi pi-eye disable cursor-pointer"
                          onClick={() => {
                            handleEyeToggle(location);
                          }}
                          data-pr-tooltip="Disable"
                          data-pr-position="bottom"
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : null
          ) : null}
        </div>
      </div>
    </>
  );
}

export default LocationCard;
