import React from "react";
import Wrong from "../../../iconComponents/Wrong";

function InvalidLink() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex w-11/12 max-w-3xl justify-center rounded-3xl bg-light-text p-4">
        <div className="flex flex-col gap-5 rounded-2xl border border-light-purple p-8">
          <span className="flex w-full items-center justify-center rounded-full p-2">
            <Wrong />
          </span>
          <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-red-600">
            Invalid link
          </h4>
          <p className="text-center text-gray-700">
            We're sorry, but the link you tried to access is not valid.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InvalidLink;
