import React from "react";
import { Paginator } from "primereact/paginator";

function Pagination({ currentPage, totalPages, onPageChange, template }) {
  return (
    <Paginator
      first={currentPage}
      rows={5}
      totalRecords={totalPages}
      onPageChange={(e) => onPageChange(e)}
      template={template}
    />
  );
}

export default Pagination;
