import { Skeleton } from "primereact/skeleton";
import React from "react";

export const ServiceLocationsSkeleton = () => {
  return (
    <div>
      {Array(5)
        .fill(0)
        .map((n, i) => (
          <div key={i} className='flex gap-x-2 w-[100%] items-center'>
            <i className='pi pi-map-marker' />
            <Skeleton className='p-3 mt-2' />
          </div>
        ))}
    </div>
  );
};
