import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useTokenData from "../../hooks/useTokenData";
import { validatePassword } from "../utility/Utils";
import useAxios from "../../hooks/useAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

function DialogUpdatePin() {
  const { http } = useAxios();
  const toast = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [oldPinEye, setOldPinEye] = useState(false);
  const [pinEye, setPinEye] = useState(false);
  const [confirmPinEye, setConfirmPinEye] = useState(false);
  const handleOldPinClick = () => setOldPinEye(!oldPinEye);
  const handlePinClick = () => setPinEye(!pinEye);
  const handleConfirmPinClick = () => setConfirmPinEye(!confirmPinEye);
  const isAdmin = location.pathname.includes("admin");
  const { userId } = useTokenData();

  const defaultValues = {
    userId: userId,
    oldPinCode: "",
    NewPinCode: "",
    confirmPinCode: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const res = await http.put(`/Users/UpdateUserPinCode`, formData);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 4000,
      });
      reset();
    } catch (error) {
      console.error("Error creating order:", error);
      const errorMessage =
        error.response?.data?.errors?.NewPinCode &&
        Array.isArray(error.response.data.errors.NewPinCode)
          ? error.response.data.errors.NewPinCode[0]
          : error.response?.data?.message || "An unexpected error occurred";

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Update PIN",
      template: () => <Link to="#">Update PIN</Link>,
    },
  ];
  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <form
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
        id="createPinForm"
      >
        <div className="flex w-full items-center justify-center py-4 sm:px-8">
          <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
            <div>
              <h4 className="mb-4 text-center text-3xl font-bold sm:text-left">
                Update PIN
              </h4>
              <p className="mb-3 text-center text-gray-700 sm:text-left">
                Set your PIN to ensure only you can place and accept orders,
                even if a staff member initiates them
              </p>
            </div>

            <div className="pt-4">
              <label className="block pb-2 text-base capitalize leading-loose">
                current PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="oldPinCode"
                control={control}
                rules={{
                  required: "Current PIN is required",
                  pattern: {
                    value: /^\d{4,12}$/,
                    message: "PIN must be between 4 and 12 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="Current PIN"
                        maxLength={12}
                        keyfilter="num"
                        className="w-full pb-2 pt-2"
                        name="oldPinCode"
                        type={!oldPinEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("confirmPinCode");
                        }}
                      />
                      {!oldPinEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handleOldPinClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handleOldPinClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.oldPinCode && (
                <p className="text-red-500">{errors.oldPinCode.message}</p>
              )}
            </div>
            <div className="pt-7">
              <label className="block pb-2 text-base capitalize leading-loose">
                new PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="NewPinCode"
                control={control}
                rules={{
                  required: "New PIN is required",
                  pattern: {
                    value: /^\d{4,12}$/,
                    message: "PIN must be between 4 and 12 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="New PIN"
                        maxLength={12}
                        keyfilter="num"
                        className="w-full pb-2 pt-2"
                        name="NewPinCode"
                        type={!pinEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("confirmPinCode");
                        }}
                      />
                      {!pinEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handlePinClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handlePinClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.NewPinCode && (
                <p className="text-red-500">{errors.NewPinCode.message}</p>
              )}
            </div>
            <div className="pt-4">
              <label className="block pb-2 text-base capitalize leading-loose">
                Confirm PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="confirmPinCode"
                control={control}
                rules={{
                  validate: (value) => {
                    return value === getValues("NewPinCode")
                      ? true
                      : "PIN does not match";
                  },
                }}
                render={({ field }) => (
                  <span className="p-input-icon-left p-input-icon-right w-full">
                    <i className="pi pi-lock" />
                    <InputText
                      placeholder="Confirm PIN"
                      maxLength={12}
                      className="w-full pb-2 pt-2"
                      name="confirmPinCode"
                      keyfilter="num"
                      type={!confirmPinEye ? "password" : "text"}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger("confirmPinCode");
                      }}
                    />
                    {!confirmPinEye ? (
                      <i
                        className="pi pi-eye-slash cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    ) : (
                      <i
                        className="pi pi-eye cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    )}
                  </span>
                )}
              />

              {errors.confirmPinCode && (
                <p className="text-red-500">{errors.confirmPinCode.message}</p>
              )}
            </div>

            <div className="flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
              <div className="w-full md:w-auto">
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="mt-2 w-full justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple md:mt-9 md:w-auto "
                >
                  Cancel
                </Button>
              </div>
              <div className="w-full items-end justify-end md:w-auto">
                <Button
                  type="submit"
                  loading={isLoading}
                  className="mt-9 w-full justify-center rounded-full bg-light-purple  py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default DialogUpdatePin;
