import React from 'react'
import { useStoreDataCookies, useTokenStore } from '../../store'
import { Navigate, Outlet } from "react-router-dom";
import EmailVerifySuccess from '../signUp/EmailVerifySuccess';
import useTokenData from '../../hooks/useTokenData';

function EmailSuccessGuard() {
    const { token } = useTokenStore();
    const { data } = useStoreDataCookies();
    const { userId } = useTokenData();

    if (token || data) {
        return <EmailVerifySuccess/>;

    } else {
        return <Navigate replace to="/login" />;
    }
    // return <Outlet />;
}

export default EmailSuccessGuard