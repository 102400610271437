import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../hooks/useAxios";
import useTokenData from "../hooks/useTokenData";
import OrderIcon from "../iconComponents/OrderIcon";
import ResultIcon from "../iconComponents/ResultIcon";
import useHandleResize from "../hooks/useHandleResize";
import RevenueIcon from "../iconComponents/RevenueIcon";
import ReorderIcon from "../iconComponents/ReorderIcon";
import CompletionChecklist from "./CompletionChecklist";
import useDelayedInputChange from "../hooks/useDelayedInputChange";
import ReusableDataList from "./ReusableDataList/ReusableDataList";
import {
  downloadFileFromResponse,
  formatDate,
  formatDateTime,
} from "./utility/Utils";
import { InputTextarea } from "primereact/inputtextarea";

export default function Dashboard() {
  const [menuvisible, setMenuVisible] = useState(false);
  const buttonRef = useRef(null);
  const { entityId } = useTokenData();
  const [cardData, setCardData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(0);
  const toast = useRef();
  const { http } = useAxios();
  const [refetch, setRefetch] = useState(false);
  const [refetchTopOrdering, setRefetchTopOrdering] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderData, setOrderData] = useState();
  const componentMount = useRef(false);
  const location = useLocation();
  const isWelcome = location?.state?.isWelcome;
  const [isLoading, setIsLoading] = useState(false);
  const [serviceName, setServiceName] = useState();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [uploadDialog, setUploadDialog] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [storedFiles, setStoredFiles] = useState();
  const [date, setDate] = useState(null);
  const { isMobile } = useHandleResize();
  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [error, setError] = useState("");
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [confirmData, setConfirmData] = useState();
  const [cancelLoading, setCancelLoading] = useState(false);
  // range of days
  let days = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Last Day",
      value: 1,
    },
    {
      name: "Last Week",
      value: 7,
    },
    {
      name: "Last 2 Weeks",
      value: 14,
    },
    {
      name: "Last 3 Weeks",
      value: 21,
    },
    {
      name: "Last Month",
      value: 30,
    },
    {
      name: "Last 2 Months",
      value: 60,
    },
    {
      name: "Last Year",
      value: 365,
    },
  ];
  const dropdownValues = {
    options: [
      { name: "Service", value: "Service.Name@=*" },
      { name: "Patient Name", value: "patientName@=*" },
      { name: "Ordered By", value: "Entity.Name@=*" },
    ],
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const newOrdersRef = useRef(null);
  const menuRight = useRef({});

  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );

  const getUploadedFiles = async (rowData) => {
    const res = await http.get(`/Orders/GetOrderResultFiles/${rowData?.id}`);
    setStoredFiles(res.data);
  };

  const handleScroll = () => {
    setMenuVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: menuvisible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  /*********************cancel order ********************************** */
  const handleCancelOrder = async () => {
    try {
      setCancelLoading(true);
      const data = new FormData();
      data.append("Status", 11);
      data.append("CancelationResone", cancelReason);
      const response = await http.put(
        `/Orders/UpdateOrder/${confirmData.id}`,
        data,
      );
      setRefetch((e) => !refetch);
      setCancelDialogVisible(false);
      setCancelReason("");
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message || "order cancel successfully",
        life: 3000,
      });
    } catch (error) {
      console.error("Error updating order:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    } finally {
      setCancelLoading(false);
    }
  };
  const confirm = (rowData) => {
    setCancelDialogVisible(true);
    setConfirmData(rowData);
  };
  const dialogCancelOrder = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => {
          setCancelDialogVisible(false);
          setCancelReason("");
        }}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={handleCancelOrder}
        loading={cancelLoading}
        disabled={!cancelReason.trim()}
        autoFocus
      />
    </div>
  );

  const fetchBestSellingServicesData = async () => {
    try {
      const response = await http.get(
        selectedDay
          ? `/Entities/GetBestSellingServices/${entityId}?days=${Number(
              selectedDay,
            )}`
          : `/Entities/GetBestSellingServices/${entityId}`,
      );
      createChart(response.data);
    } catch (e) {
      console.error("Error in Fetching data chart", e);
    }
  };

  const createChart = (chartData) => {
    const dataChart = {
      labels: chartData.map((item) => item.serviceName),
      datasets: [
        {
          data: chartData.map((item) => item.ordersCount),
        },
      ],
    };
    const options = {
      cutout: "60%",
    };
    setChartData(dataChart);
    setServiceName(dataChart.labels);
    setChartOptions(options);
  };

  const getCardData = async (url) => {
    const response = await http.get(url);
    setCardData(response.data);
  };
  /*********************** schedule order**************** */
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [preferredLocation, setPreferredLocation] = useState(null);
  const [Locations, setLocations] = useState([]);
  const getLocations = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/GetOrderRenderedLocations/${rowData.id}`,
      );
      const locationData = response.data;
      setLocations(locationData);
      const allRenderProviderFalse = locationData.every(
        (loc) => !loc.isSelectedByRenderProvider,
      );

      const preferredLocation = locationData.find(
        (loc) => loc.isPreferredByPatient,
      );
      setPreferredLocation(preferredLocation);
      const defaultLocation = locationData.find(
        (loc) => loc.isSelectedByRenderProvider,
      );

      let selectedLocation;

      if (allRenderProviderFalse && preferredLocation) {
        selectedLocation = preferredLocation.locationId;
      } else if (defaultLocation) {
        selectedLocation = defaultLocation.locationId;
      }

      if (selectedLocation) {
        setSelectedLocation(selectedLocation);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleLocationChange = (e) => {
    setSelectedLocation(e.value);
    if (e.value) {
      setError("");
    }
  };
  const handleSchedule = (rowData) => {
    const scheduledDate = rowData.scheduledDate;
    const currentDate = scheduledDate ? new Date(scheduledDate) : new Date();
    setDate(currentDate);
    setVisible(true);
    setOrderData(rowData);
    getLocations(rowData);
  };
  const dateRequest = async (date, selectedLocation) => {
    if (!selectedLocation) {
      setError("Please select a location.");
    } else {
      const data = new FormData();
      data.append("Status", 9);
      data.append("ScheduledDate", date.toLocaleString());
      data.append("SelectedLocationsByRender", selectedLocation);
      try {
        setIsLoading(true);
        await http.put(`/Orders/UpdateOrder/${orderData.id}`, data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      setRefetch((e) => !refetch);
      setVisible(false);
    }
  };
  /**********************************************/
  const navigate = useNavigate();

  const downloadPatientInfo = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    }
  };

  const downloadPatientAttachments = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadPatientAttachmentsByOrderId/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {}
  };

  const orderInfo = async (rowData) => {
    navigate(`/rendering/Orders/${rowData.id}`, {
      state: {
        orderNumber: rowData.orderTrackingNumber,
        serviceName: rowData.serviceName,
      },
    });
  };
  const downloadReceipt = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadRendringRecieptSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message || "File download successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };
  const getMenuItems = (rowData) => {
    const items = [];

    if (rowData.status !== 8) {
      items.push({
        label: "Upload Result",
        icon: "pi pi-upload",
        command: () => {
          setUploadDialog(true);
          getUploadedFiles(rowData);
          setOrderData(rowData);
        },
      });
    }

    if (rowData.status === 8) {
      items.push({
        label: "Schedule",
        icon: "pi pi-calendar",
        command: () => handleSchedule(rowData),
      });
    }

    items.push({
      label: "Cancel",
      icon: "pi pi-times",
      // command: () => cancelOrder(rowData),
      command: () => {
        confirm(rowData);
        setCancelDialogVisible(true);
        setCancelReason("");
      },
    });

    items.push({
      label: "Download Order Summary",
      icon: "pi pi-download",
      command: () => downloadPatientInfo(rowData),
    });

    if (rowData.hasAttachmentFiles) {
      items.push({
        label: "Download Patient Attachments",
        icon: "pi pi-download",
        command: () => downloadPatientAttachments(rowData),
      });
    }

    items.push({
      label: "Order Info",
      icon: "pi pi-info-circle",
      command: () => orderInfo(rowData),
    });
    items.push({
      label: "Download receipt Summary",
      icon: "pi pi-download",
      command: () => downloadReceipt(rowData),
    });
    return items;
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          className="p-button-outlined mr-2 rounded-full"
          onClick={(event) => {
            setMenuVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={menuvisible ? "" : "hidden"}
        />
      </>
    );
  };

  useEffect(() => {
    setCardData(null);
    const url = selectedDay
      ? `/Entities/GetDashboardCards/${entityId}?days=${selectedDay}`
      : `/Entities/GetDashboardCards/${entityId}`;
    getCardData(url);
    fetchBestSellingServicesData();
    componentMount.current && setRefetchTopOrdering(!refetchTopOrdering);
    // componentMount.current && setRefetch(!refetch);
    componentMount.current = true;
  }, [entityId, selectedDay]);

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status === 8 ? (
          <div className="inline rounded-md bg-sky-100 p-2 text-center text-sm text-sky-700 sm:block sm:p-3 sm:text-base">
            Ordered
          </div>
        ) : (
          <div className="inline rounded-md bg-light-rose p-2 text-center text-sm text-dark-rose sm:block sm:p-3 sm:text-base">
            Scheduled
          </div>
        )}
      </>
    );
  };

  const cardBestOrderingProviderTemplate = (row) => {
    return (
      <>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Practice Name:&nbsp;
          </span>
          {row.orderPracticeName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordering Provider Name:&nbsp;
          </span>
          {row.orderProviderName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Orders Number:&nbsp;
          </span>
          {row.ordersCount}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Last Order Date:&nbsp;
          </span>
          {row.lastOrderDate && (
            <span>{formatDate(row.lastOrderDate)}&nbsp;</span>
          )}
        </p>
      </>
    );
  };
  const cardNewOrdersTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="font-semibold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Patient Name:&nbsp;
          </span>
          {row.patientName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Scheduled Date:&nbsp;
          </span>
          {row.scheduledDate && (
            <>
              <span>{formatDate(row.scheduledDate)}&nbsp;</span>
              <span>{formatDateTime(row.scheduledDate)}</span>
            </>
          )}
        </p>
        <div className="flex justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="font-semibold text-slate-500">Status:&nbsp;</span>

            {statusTemplate(row)}
          </div>
          {actionTemplate(row)}
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div className="mb-20 flex flex-col gap-4 overflow-y-auto">
        <div>
          <h3 className="text-md mb-3 text-center font-bold">Search By</h3>
          <div className="flex flex-col gap-2">
            {dropdownValues?.options?.map((option) => {
              return (
                <div key={option.name} className="flex items-center">
                  <RadioButton
                    inputId={option.name}
                    name="name"
                    value={option.value}
                    onChange={(e) => {
                      dropdownValues.setSelectedValue(e.target.value);
                    }}
                    checked={dropdownValues.selectedValue === option.value}
                  />
                  <label htmlFor={option.name} className="ml-2 capitalize">
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        blockScroll
        draggable={false}
        header="Schedule Date and Location"
        visible={visible}
        onHide={() => {
          setUploadedFiles([]);
          setStoredFiles([]);
          setLocations([]);
          setSelectedLocation(null);
          setVisible(false);
        }}
        className="w-[35rem]"
      >
        <Calendar
          value={date}
          dateFormat="mm/dd/yy"
          onChange={(e) => {
            setDate(e.value);
          }}
          minDate={new Date()}
          inline
          showTime
          hourFormat="12"
          pt={{
            day: {
              style: { padding: "0", margin: "0" },
            },
            header: {
              style: { padding: "0", margin: "0" },
            },
            table: {
              style: { padding: "0", margin: "0" },
            },
            timePicker: {
              style: { padding: "0", margin: "0" },
            },
            hourPicker: {
              style: { padding: "0", margin: "0" },
            },
            panel: {
              style: { padding: "0", margin: "0" },
            },
            minute: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
            hour: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
            ampm: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
          }}
          className="mb-2 w-full p-0"
        />

        <span className="col-span-1 flex flex-col gap-2">
          <label className="block text-lg font-medium capitalize text-dark-purple">
            select location <span className="text-red-500">*</span>
          </label>
          <Dropdown
            id="selected-location"
            value={selectedLocation}
            placeholder="Locations"
            options={Locations?.map((option) => ({
              value: option.locationId,
              label: option.name,
              address: option.address,
              isSelectedByRenderProvider: option.isSelectedByRenderProvider,
              isPreferredByPatient: option.isPreferredByPatient,
            }))}
            onChange={handleLocationChange}
            itemTemplate={(option) => (
              <div className="grid w-full grid-cols-8 items-center rounded">
                <div className="col-span-1 flex items-center justify-center">
                  {option.value === selectedLocation ? (
                    <i className="pi pi-check pr-2 text-xl text-green-500"></i>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-span-7">
                  <h4 className="flex items-center gap-2 font-medium capitalize text-gray-500">
                    <span>{option.label}</span>
                    {option.isPreferredByPatient && (
                      <span className="text-xs font-normal italic">
                        (Preferred by Patient)
                      </span>
                    )}
                  </h4>
                  <p className="font-normal capitalize text-gray-500">{`${
                    option.address.lineOne
                  }, ${
                    option.address.lineTwo ? option.address.lineTwo + "," : ""
                  } ${option.address.city}, ${option.address.state} ${
                    option.address.zipCode
                  }`}</p>
                </div>
              </div>
            )}
            pt={{
              panel: {
                style: {
                  border: "solid 2px #e5e7eb",
                  borderTopStyle: "none",
                },
              },
            }}
            className="md:w-14rem w-full"
          />
          {error && <p className="text-red-500">{error}</p>}
          {preferredLocation && (
            <p className="border-l-2 px-2 text-sm text-gray-500">
              <strong>Note:</strong> While the patient's preferred location is
              pre-selected, you have the flexibility to choose a different
              location that suits your scheduling and service requirements.
            </p>
          )}
        </span>

        <div className="text-end">
          <Button
            loading={isLoading}
            label="Schedule"
            className="mt-3"
            onClick={() => dateRequest(date, selectedLocation)}
          />
        </div>
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        header="Confirmation to cancel order"
        visible={cancelDialogVisible}
        onHide={() => {
          setCancelDialogVisible(false);
          setCancelReason("");
        }}
        footer={dialogCancelOrder}
        className="w-[35rem]"
      >
        <p className="mb-3 font-semibold">
          Are you sure you want to proceed with cancelling this order?{" "}
        </p>
        <span className="block capitalize"> Please provide the reason. </span>
        <p className="mt-2">
          <InputTextarea
            value={cancelReason}
            placeholder="cancelation reason"
            onChange={(e) => {
              setCancelReason(e.target.value);
            }}
            className="w-full"
          />
        </p>
      </Dialog>

      <div className="flex flex-col gap-5">
        <div className="flex grid-cols-12 flex-col-reverse gap-5 md:grid">
          <div className="col-span-8 flex flex-col gap-4 xl:col-span-9">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-semibold">Overview</h1>
              {/* endPoint */}
              <Dropdown
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.value)}
                options={days}
                optionLabel="name"
                className="h-10 w-52 items-center"
              />
            </div>
            {/* should endpoint */}
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 mt-4 md:col-auto">
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 0 },
                    })
                  }
                  className="cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-pink-600">
                      {cardData ? (
                        cardData.newOrdersCount
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <OrderIcon />
                  </div>
                  <p className="text-base font-medium">New Orders</p>
                </div>
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 2 },
                    })
                  }
                  className="mt-4 cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#009B2B]">
                      {cardData ? (
                        cardData.resultedOrdersCount
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <ResultIcon />
                  </div>
                  <p className="text-base font-medium">Resulted Services</p>
                </div>
              </div>
              <div className="col-span-2 md:col-auto">
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 1 },
                    })
                  }
                  className="mt-4 cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#594FC4]">
                      {cardData ? (
                        `${cardData.inProgressOrdersCount} ${cardData.maxOrdersQuantity ? "/ " + cardData.maxOrdersQuantity : ""}`
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <ReorderIcon />
                  </div>
                  <p className="text-base font-medium">In-progress Orders</p>
                </div>
                <div className="mt-4 cursor-pointer rounded-md bg-white p-4">
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#0EA5E9]">
                      {cardData ? (
                        <div className="flex items-center">
                          <i className="pi pi-dollar"></i>
                          <p className="mr-1">{cardData.revenue}</p>
                        </div>
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <RevenueIcon />
                  </div>
                  <p className="text-base font-medium">Revenue</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 flex xl:col-span-3">
            <CompletionChecklist view="render" isWelcome={isWelcome} />
          </div>
        </div>

        {/* Table with diagram */}
        <div className="flex grid-cols-12 flex-col gap-5 md:grid">
          <div className="col-span-8 xl:col-span-9">
            <ReusableDataList
              title={"Top Ordering Providers"}
              fromFront={true}
              dataLink={`/Entities/GetTopOrderingProviders/${entityId}?days=${Number(
                selectedDay,
              )}`}
              columns={[
                { name: "Practice Name", value: "orderPracticeName" },
                { name: "Ordering Provider Name", value: "orderProviderName" },
                { name: "Last Order Date", value: "lastOrderDate" },
                { name: "Orders Count", value: "ordersCount" },
              ]}
              sendSearchData={handleInputChange}
              refetch={refetchTopOrdering}
              searchTitle="search by practice name"
              actionTemplates={
                isMobile && [{ template: cardBestOrderingProviderTemplate }]
              }
            />
          </div>

          <div className="col-span-4 flex-col gap-3 rounded-lg border border-slate-100 bg-white p-4 xl:col-span-3">
            <h1 className="mb-2 font-semibold">Best Selling Services</h1>
            <div className="w-full">
              {chartData?.labels && chartData?.labels[0] ? (
                <Chart
                  type="doughnut"
                  data={chartData}
                  options={chartOptions}
                  className="flex justify-center align-middle md:max-h-[20rem]"
                />
              ) : (
                <p className="pt-[130px] text-center">
                  You haven't sold any services yet...
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="" ref={newOrdersRef}>
          <ReusableDataList
            title={"New Orders"}
            sendSearchData={handleInputChange}
            searchValue={searchValue}
            dropdownValues={dropdownValues}
            dataLink={`/Orders/GetOrders/${entityId}?Filters=${
              searchValue ? selectedValue : ""
            }${searchValue}%2Cstatus==8`}
            columns={[
              {
                name: "Service",
                value: "serviceName",
                style: { width: "13%" },
              },
              {
                name: "Ordered By",
                value: "orderedBy",
                style: { width: "13%" },
              },
              { name: "Date", value: "orderedDate", style: { width: "13%" } },
              {
                name: "Patient Name",
                value: "patientName",
                style: { width: "13%" },
              },
            ]}
            actionTemplates={
              isMobile
                ? [{ template: cardNewOrdersTemplate }]
                : [
                    {
                      template: actionTemplate,
                      header: "Actions",
                      style: { width: "13%" },
                    },
                  ]
            }
            filterTemplate={filterTemplate}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
}
