import { Skeleton } from "primereact/skeleton";
import React from "react";

export const ProviderInfoCardSkeleton = ({ infoCount = 2 }) => {
  return (
    <div className='p-3 bg-white'>
      <div className='flex w-full justify-between'>
        <div className='flex w-[200px] gap-4'>
          <Skeleton size='50px' shape='circle' />
          <div className='flex flex-col gap-2 w-[80%]'>
            <Skeleton className='p-3' width='70%' />
            <Skeleton className='p-2' />
          </div>
        </div>
        <div className='flex w-72 gap-4 mb-6'>
          <Skeleton className='p-6 rounded-full' />
          <Skeleton className='p-6 rounded-full' />
        </div>
      </div>
      {Array(infoCount)
        .fill(0)
        .map((n, i) => (
          <div key={i} className='mt-3 w-full'>
            <div className='flex w-[80%] gap-4'>
              <Skeleton className='p-3' width='30%' />
              <Skeleton className='p-3' />
            </div>
          </div>
        ))}
    </div>
  );
};
