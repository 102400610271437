import { Tooltip } from "primereact/tooltip";
import React from "react";
import useTokenData from "../../../hooks/useTokenData";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

export const ManageServiceCard = ({
  name,
  status,
  description,
  price,
  onEditClick,
  onDeleteClick,
  onPublishClick,
  onCradClick,
  isPublished,
  tooltip,
  onTestClick,
  onCopyClick,
  toast,
}) => {
  const { IsEntityApproved } = useTokenData();
  const serviceStatus =
    status &&
    (status === "new" ? (
      <p className="flex items-center justify-center rounded-lg bg-[#E0E7FF] p-2 text-xs text-[#6366F1]">
        New
      </p>
    ) : (
      <p className="flex items-center justify-center rounded-lg bg-pink-100 p-2 text-xs text-pink-500">
        Updated
      </p>
    ));
  const handleDisabledAction = (action) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail:
        IsEntityApproved === "InComplete"
          ? `${action} is disabled until you complete your practice data and be approved`
          : `${action} is disabled until your practice is approved`,
      life: 3000,
    });
  };
  return (
    <div className="mt-4 flex  min-h-[9rem] flex-col justify-between	rounded-lg border bg-white p-5 shadow-md">
      {tooltip && <Tooltip target=".action-button" showDelay={100} />}
      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
        <div className="flex items-center gap-3">
          <p className="font-semibold">{name} </p>
          {serviceStatus}
        </div>
        <div className="flex items-center gap-3">
          {!isPublished ? (
            <Message
              severity="contrast"
              text="Draft"
              className="ml-0 rounded-[1.5rem] bg-gray-300 px-[20px]  py-[6px] text-[#303030] md:ml-2"
            />
          ) : (
            <Message
              severity="contrast"
              text="Published"
              className="ml-0 rounded-[1.5rem] bg-green-100 px-[20px]  py-[6px] text-green-600 md:ml-2"
            />
          )}
          <i
            onClick={onTestClick}
            className="action-button pi pi-exclamation-triangle flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-[1px] border-slate-200 text-orange-800 hover:bg-orange-50"
            data-pr-tooltip="Test"
            data-pr-position="top"
          ></i>
          <i
            onClick={onCopyClick}
            className="action-button pi pi-copy flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-[1px] border-slate-200 text-green-800 hover:bg-green-50"
            data-pr-tooltip="Clone & Edit"
            data-pr-position="top"
          ></i>
          <i
            onClick={onEditClick}
            className="action-button pi pi-pencil flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-[1px] border-slate-200 text-blue-800 hover:bg-blue-50"
            data-pr-tooltip="Edit"
            data-pr-position="top"
          ></i>
          <i
            onClick={onDeleteClick}
            className="action-button pi pi-trash flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-[1px] border-slate-200 text-red-800 hover:bg-red-50"
            data-pr-tooltip="Delete"
            data-pr-position="top"
          ></i>
        </div>
      </div>
      <div
        onClick={onCradClick}
        className="w-[75%] cursor-pointer pt-4 text-justify"
      >
        <p>{description}</p>
      </div>
      <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-end">
        {/* <div className="flex gap-8 pt-4">
          <div className="max-[640]:justify-items-start flex items-center">
            <i className="pi pi-dollar mr-1 flex items-center justify-center rounded-full text-[#009B2B]"></i>
            <p>{price}</p>
          </div>
        </div> */}
        <div className="w-full sm:w-auto">
          {isPublished ? (
            <Button
              onClick={() => {
                if (
                  IsEntityApproved === "Pending" ||
                  IsEntityApproved === "Denied" ||
                  IsEntityApproved === "InComplete"
                ) {
                  handleDisabledAction("Unpublishing");
                  return;
                }
                onPublishClick();
              }}
              className="flex w-full justify-center px-[12px] py-[8px] sm:w-auto"
              tooltip={
                (IsEntityApproved === "Pending" ||
                  IsEntityApproved === "Denied" ||
                  IsEntityApproved === "InComplete") &&
                "Unpublishing is disabled until your practice is approved"
              }
              tooltipOptions={{
                showDelay: 1000,
                hideDelay: 300,
                position: "bottom",
              }}
              severity={
                IsEntityApproved === "Pending" ||
                IsEntityApproved === "Denied" ||
                IsEntityApproved === "InComplete"
                  ? "secondary"
                  : "info"
              }
            >
              Unpublish
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  if (
                    IsEntityApproved === "Pending" ||
                    IsEntityApproved === "Denied" ||
                    IsEntityApproved === "InComplete"
                  ) {
                    handleDisabledAction("Publishing");
                    return;
                  }
                  onPublishClick();
                }}
                tooltip={
                  (IsEntityApproved === "Pending" ||
                    IsEntityApproved === "Denied" ||
                    IsEntityApproved === "InComplete") &&
                  "Publishing is disabled until your practice is approved"
                }
                tooltipOptions={{
                  showDelay: 700,
                  hideDelay: 300,
                  position: "bottom",
                }}
                className="grid w-full px-[12px] py-[8px] sm:w-auto"
                severity={
                  IsEntityApproved === "Pending" ||
                  IsEntityApproved === "Denied" ||
                  IsEntityApproved === "InComplete"
                    ? "secondary"
                    : "success"
                }
              >
                Publish Now
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
