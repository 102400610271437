import { Link } from "react-router-dom";

import { Button } from "primereact/button";

import { HealthCare } from "../../../iconComponents/HealthCare";

export const BillableItemHeader = ({ onCreateBillableItemClick }) => {
  return (
    <div>
      <div className="flex justify-between rounded-lg bg-white p-5 font-inter">
        <div className="flex flex-1 flex-col items-start justify-around gap-2">
          <p className="text-xl font-semibold text-[#3D3877]">
            Manage Billable Items
          </p>
          <div className="flex w-full gap-2">
            <Button
              className="hidden h-12 w-12 gap-2 p-4 font-inter sm:flex sm:h-auto sm:w-auto"
              onClick={onCreateBillableItemClick}
            >
              <i className="pi pi-plus" />
              <span className="hidden sm:inline-flex">
                Create Billable Item
              </span>
            </Button>
            <Link to="/Service/billableItems/report" className="flex sm:hidden">
              <Button icon="pi pi-book" label="Show Full Report" outlined />
            </Link>
          </div>
        </div>
        <div>
          <HealthCare />
        </div>
      </div>
    </div>
  );
};
