import React from "react";
import { Skeleton } from "primereact/skeleton";

export const NoteSkeleton = () => {
  return (
    <div className='grid grid-cols-[1.25fr_3fr] gap-2 border p-5 border-gray-400 rounded-md mt-2'>
      <Skeleton className='p-3' />
      <Skeleton className='p-3' />
    </div>
  );
};
