import React from 'react'
import useTokenData from '../../hooks/useTokenData'
import { Navigate, Outlet } from "react-router-dom";
import { useSwitchOrdering } from '../../store';

function IsHiddenGuard() {
    const { IsHidden , UserJourneyStatus} = useTokenData();
    const { isOrderingMode } = useSwitchOrdering();

    if (isOrderingMode == true && IsHidden === 'True') {
        return <Navigate replace to="/ordering/Orders/" />;
    } else if (isOrderingMode == false && IsHidden === 'True' && UserJourneyStatus ==='2') {
        return <Navigate replace to="/Dashboard" />;
    }
    return <Outlet />;
}

export default IsHiddenGuard