import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  useExternalLogin,
  useFirstTimeVisit,
  useRefreshTokenStore,
  useRegisterFormStore,
  useStoreMslMicrosoft,
  useTokenStore,
} from "../../store";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Toast } from "primereact/toast";
import { addYears } from "date-fns";
import { ProgressSpinner } from "primereact/progressspinner";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import useAxios from "../../hooks/useAxios";
import { MicrosoftLogin } from "react-microsoft-login";
import { validatePassword } from "./../utility/Utils";
import EmailVerifyIcon from "../../iconComponents/EmailVerifyIcon";
import ConfirmPhoneNumberDialog from "./ConfirmPhoneNumberDialog";
import MSAuthButton from "../MSAuthButton";

const genderOptions = [
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
];

function SignUp() {
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailDisabled, setIsEmailDisabled] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const { setToken, token } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { onMsalInstanceChange, setIsMicrosoftLoggedIn } =
    useStoreMslMicrosoft();
  const { setIsCompletedRegister } = useExternalLogin();
  useEffect(() => {
    const email = params.get("E");

    if (email) {
      reset({ ...defaultValues, email });
      setIsEmailDisabled(true);
      setEmail(email);
    } else {
      setEmail("");
      setIsEmailDisabled(false);
    }
  }, [email]);
  const EId = params?.get("EId");
  const date = params?.get("IDate");
  const role = params?.get("SR");
  const isProvider = params?.get("IP");
  const InvId = params?.get("InvID");

  // declaration all const
  const toast = useRef(null);
  const navigate = useNavigate();
  const setFormData = useRegisterFormStore((state) => state.setFormData);
  // const[formData,setFormData]=useRegisterFormStore();
  const [selectedGender, setSelectedGender] = useState(null);
  //default value of registration form
  const defaultValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: null,
    password: "",
    confirmPassword: "",
    checked: false,
    receiveSmsMessages: false,
  };

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const handleGenderChange = (e) => {
    setSelectedGender(e.value);
  };

  // create validation into names

  const validateName = (value) => {
    const regex = /^[A-Za-z]+$/;
    if (value.includes(" ")) {
      return "Name should not contain spaces";
    }
    if (!value.match(regex)) {
      return "Name should only contain letters";
    }
    return true;
  };

  const handleCreate = async (formData) => {
    const modifyData = {
      gender: formData.gender === "Male" ? 1 : 2,
    };
    const combinedFormData = { ...formData, ...modifyData };
    setIsLoading(true);

    // method request register
    try {
      await http
        .post(
          EId
            ? `/Authentication/RegisterAsync?EntityId=${EId}&InvitationDate=${date}&staffRole=${role}&IsProvider=${isProvider || ""}`
            : `/Authentication/RegisterAsync`,
          combinedFormData,
        )
        .then((response) => {
          setFormData(response.data);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.message,
            life: 4000,
          });
          setToken(response.data.token);
          setRefreshToken(response.data.refreshToken);
          reset(defaultValues);
          if (isEmailDisabled) {
            if (response.data.isInvitedAsAProvider) {
              navigate("/welcome-provider");
            } else {
              navigate("/Dashboard");
            }
          } else {
            navigate("/verifyEmail", {
              state: {
                email: response.data.email,
                phoneNumber: formData.phoneNumber,
              },
              replace: true,
            });
          }
        });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const onSubmit = async (formData) => {
    handleCreate(formData);
  };
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const handleConfirmPassword = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };
  function getAccessTokenFromSessionStorage() {
    const staticAccessKey = "login.windows.net-accesstoken";
    let accessToken = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticAccessKey)) {
        accessToken = sessionStorage.getItem(key);
        break;
      }
    }
    return accessToken;
  }

  function getIdTokenFromSessionStorage() {
    const staticIdKey = "login.windows.net-idtoken";
    let idToken = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticIdKey)) {
        idToken = sessionStorage.getItem(key);
        break;
      }
    }
    return idToken;
  }

  const authHandler = async (err, data, msal) => {
    const accessTokenData = getAccessTokenFromSessionStorage();
    const idTokenData = getIdTokenFromSessionStorage();
    const accessData = JSON.parse(accessTokenData);
    const accessToken = accessData?.secret;
    const idData = JSON.parse(idTokenData);
    const idToken = idData?.secret;
    if (!err && data) {
      onMsalInstanceChange(msal);
      setIsMicrosoftLoggedIn(true);
    }
    const microsoftData = {
      provider: "MICROSOFT",
      idToken: idToken,
      accessToken: accessToken,
    };
    axios
      .post(
        !EId
          ? "/Authentication/ExternalLogin"
          : `/Authentication/ExternalLogin?InvitationId=${InvId}&EntityId=${EId}&InvitationDate=${date}&staffRole=${role}&IsProvider=${isProvider || ""}`,
        microsoftData,
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.message,
          life: 4000,
        });
        // new Promise((resolve) => setTimeout(resolve, 2000));
        setToken(response.data.token);
        setRefreshToken(response.data.refreshToken);
        setIsCompletedRegister(response.data.isCompletedRegister);
        if (
          response.data.isNew === true ||
          response.data.isCompletedRegister === false
        ) {
          if (InvId) {
            const currentUrl = response.data.registerationReturnLink;
            const registrationIndex = currentUrl.indexOf("/");
            const trimmedUrl =
              registrationIndex !== -1
                ? currentUrl.slice(registrationIndex)
                : currentUrl;
            navigate(trimmedUrl, {
              replace: "true",
            });
          } else {
            navigate("/completeRegistration", {
              replace: true,
            });
          }
        } else {
          navigate("/chooseEntity/", {
            replace: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.message || "An error occurred",
          life: 4000,
        });
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="mb-9 flex items-center justify-center">
        <div className="xsm:w-9/12 mt-10 w-11/12 rounded-3xl bg-light-text py-8 pl-10 pr-10 shadow shadow-slate-300 md:w-6/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
          <h4 className="text-center font-philosopher text-title font-extrabold text-dark-purple">
            {" "}
            Sign Up to MedX{" "}
          </h4>

          {/* {!email && ( */}
          <div className="flex flex-col gap-2 p-4">
            <div className="flex justify-center">
              <MicrosoftLogin
                clientId="736fdbcc-003f-4597-a7e9-9ef7947c56c9"
                authCallback={authHandler}
              >
                <MSAuthButton label="Register with Microsoft" />
              </MicrosoftLogin>
            </div>
            <div className="flex justify-center">
              <GoogleOAuthProvider clientId="1094559917244-mc9dmm3g6jj1b0d5ejs6pooaevng1235.apps.googleusercontent.com">
                <GoogleLogin
                  text="signup_with"
                  onSuccess={(credentialResponse) => {
                    const googleData = {
                      provider: "GOOGLE",
                      idToken: credentialResponse.credential,
                    };
                    axios
                      .post(
                        !EId
                          ? "/Authentication/ExternalLogin"
                          : `/Authentication/ExternalLogin?InvitationId=${InvId}&EntityId=${EId}&InvitationDate=${date}&staffRole=${role}&IsProvider=${isProvider || ""}`,
                        googleData,
                      )
                      .then((response) => {
                        toast.current.show({
                          severity: "success",
                          summary: "Success",
                          detail: response.data.message,
                          life: 4000,
                        });
                        // new Promise((resolve) => setTimeout(resolve, 2000));
                        setToken(response.data.token);
                        setRefreshToken(response.data.refreshToken);
                        setIsCompletedRegister(
                          response.data.isCompletedRegister,
                        );
                        if (
                          response.data.isNew === true ||
                          response.data.isCompletedRegister === false
                        ) {
                          if (InvId) {
                            const currentUrl =
                              response.data.registerationReturnLink;
                            const registrationIndex = currentUrl.indexOf("/");
                            const trimmedUrl =
                              registrationIndex !== -1
                                ? currentUrl.slice(registrationIndex)
                                : currentUrl;
                            navigate(trimmedUrl, {
                              replace: "true",
                            });
                          } else {
                            navigate("/completeRegistration", {
                              replace: true,
                            });
                          }
                        } else {
                          navigate("/chooseEntity/", {
                            replace: true,
                          });
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        toast.current.show({
                          severity: "error",
                          summary: "Error",
                          detail:
                            error.response?.data?.message ||
                            "An error occurred",
                          life: 4000,
                        });
                      });
                  }}
                  onError={() => {}}
                  context="signup"
                />
              </GoogleOAuthProvider>
            </div>
          </div>
          {/* )} */}

          <div className="grid grid-cols-3 items-center p-6 text-gray-400">
            <hr className="border-gray-500" />
            <p className="text-center text-base">OR</p>
            <hr className="border-gray-500" />
          </div>

          <div className="form-container mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container mb-3">
                <label>
                  First Name <span className="text-red-600">*</span>
                </label>
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-user" />
                  <InputText
                    name="firstName"
                    placeholder="First Name"
                    className="w-full pb-2 pt-2"
                    {...register("firstName", {
                      required: "First name is required",
                      validate: validateName,
                    })}
                  />
                </span>
                {errors.firstName && (
                  <p className="mt-1 text-red-500">
                    {errors.firstName.message}
                  </p>
                )}

                <div className="mb-4 mt-3 grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5">
                  <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label>Middle Name</label>
                    <span>
                      <InputText
                        placeholder="Middle Name"
                        name="middleName"
                        {...register("middleName", {
                          pattern: /^[A-Za-z]+$/,
                        })}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.middleName && (
                        <p className="mt-1 text-red-500">
                          Only letters are allowed, no spaces
                        </p>
                      )}
                    </span>
                  </div>
                  <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label>
                      Last Name <span className="text-red-600">*</span>
                    </label>
                    <span>
                      <InputText
                        placeholder="Last Name"
                        name="lastName"
                        {...register("lastName", {
                          required: "Last name is required",
                          validate: validateName,
                        })}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.lastName && (
                        <p className="mt-1 text-red-500">
                          {errors.lastName.message}
                        </p>
                      )}
                    </span>
                  </div>
                  <div className="lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <label>Suffix</label>
                    <span>
                      <InputText
                        placeholder="Suffix"
                        name="suffix"
                        {...register("suffix", {
                          pattern: /^[A-Za-z.]+$/,
                        })}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.suffix && (
                        <p className="mt-1 text-red-500">
                          Only letters are allowed, no spaces
                        </p>
                      )}
                    </span>
                  </div>
                </div>
                <label>
                  Date of Birth <span className="text-red-600">*</span>
                </label>
                <span className="p-float-label mt-1 w-full">
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{
                      required: "Date of birth is required",
                      validate: {
                        validDate: (value) => {
                          const currentDate = new Date();
                          const minDate = addYears(currentDate, -18);
                          return (
                            new Date(value) <= minDate ||
                            "You must be at least 18 years old."
                          );
                        },
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          min={"1900-01-01"}
                          max={new Date().toISOString().split("T")[0]}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          style={{
                            border: "1px solid rgb(203 213 225)",
                            outline: "none",
                          }}
                          className="w-full rounded p-2"
                          placeholder="mm/dd/yy"
                        />
                      </>
                    )}
                  />
                </span>

                {errors.dateOfBirth && (
                  <p className="mt-1 text-red-500">
                    {errors.dateOfBirth.message}
                  </p>
                )}

                <div className="pt-5">
                  <label>
                    Gender <span className="text-red-600">*</span>
                  </label>
                  <Dropdown
                    name="gender"
                    value={selectedGender}
                    options={genderOptions}
                    optionLabel="name"
                    placeholder="Gender"
                    {...register("gender", {
                      required: "Please select your gender",
                    })}
                    onChange={handleGenderChange}
                    className="md:w-14rem w-full"
                  />
                  {errors.gender && (
                    <p className="mt-1 text-red-500">{errors.gender.message}</p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Mobile Phone Number <span className="text-red-600">*</span>
                  </label>
                  <div>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field }) => (
                        <InputMask
                          {...field}
                          mask="999-999-9999"
                          className="w-full"
                          placeholder="Mobile Phone Number"
                          onClick={(e) => {
                            if (e.target.value === "___-___-____") {
                              e.target.setSelectionRange(0, 0);
                            }
                          }}
                        />
                      )}
                      rules={{
                        required: "Phone number is required",
                        pattern: {
                          value: /^\d{3}-\d{3}-\d{4}$/,
                          message: "The phone number entered is incorrect",
                        },
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className="mt-1 text-red-500">
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="pt-4">
                  <label>
                    Email <span className="text-red-600">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-envelope" />
                    <InputText
                      disabled={isEmailDisabled}
                      placeholder="Email Address"
                      name="email"
                      {...register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                          message: "Invalid Email Address",
                        },
                      })}
                      className="w-full pb-2 pt-2"
                    />
                  </span>
                  {errors.email && (
                    <p className="mt-1 text-red-500">{errors.email.message}</p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Password <span className="text-red-600">*</span>
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required",
                      validate: validatePassword,
                    }}
                    render={({ field }) => (
                      <>
                        <span className="p-input-icon-left p-input-icon-right w-full">
                          <i className="pi pi-lock" />
                          <InputText
                            placeholder="Password (8 or more characters)"
                            className="w-full pb-2 pt-2"
                            name="password"
                            type={!passwordEye ? "password" : "text"}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              trigger("confirmPassword");
                            }}
                          />
                          {!passwordEye ? (
                            <i
                              className="pi pi-eye-slash"
                              onClick={handlePasswordClick}
                            />
                          ) : (
                            <i
                              className="pi pi-eye"
                              onClick={handlePasswordClick}
                            />
                          )}
                        </span>
                      </>
                    )}
                  />
                  {errors.password && (
                    <p className="mt-1 text-red-500">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="pt-4">
                  <label>
                    Confirm Password <span className="text-red-600">*</span>
                  </label>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Confirm password is required",
                      validate: (value, formValues) => {
                        return value === formValues.password
                          ? true
                          : "Passwords do not match";
                      },
                    }}
                    render={({ field }) => (
                      <span className="p-input-icon-left p-input-icon-right w-full">
                        <i className="pi pi-lock" />
                        <InputText
                          placeholder="Confirm Password"
                          className="w-full pb-2 pt-2"
                          name="confirmPassword"
                          type={!confirmPasswordEye ? "password" : "text"}
                          {...field}
                        />
                        {!confirmPasswordEye ? (
                          <i
                            className="pi pi-eye-slash"
                            onClick={handleConfirmPassword}
                          />
                        ) : (
                          <i
                            className="pi pi-eye"
                            onClick={handleConfirmPassword}
                          />
                        )}
                      </span>
                    )}
                  />
                  {errors.confirmPassword && (
                    <p className="mt-1 text-red-500">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="mt-4 flex">
                  <Controller
                    name="checked"
                    control={control}
                    rules={{
                      required:
                        "You must agree to MedXServices terms to proceed",
                    }}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                          className="pt-1"
                        />
                      </>
                    )}
                  />
                  <p className="pl-2 text-gray-800">
                    Yes, I understand and agree to the{" "}
                    <span className="text-light-purple">
                      <Link to={"/legal/tos"} target="_blank">
                        MedXServices Terms of Service,{" "}
                      </Link>
                    </span>
                    and{" "}
                    <span className="text-light-purple">
                      {" "}
                      <Link to={"/legal/privacy"} target="_blank">
                        Privacy Policy
                      </Link>
                    </span>
                    .
                  </p>
                </div>
                <div>
                  {errors.checked && (
                    <p className="mb-3 mt-1 text-red-500">
                      {errors.checked.message}
                    </p>
                  )}
                </div>
                <div className="flex">
                  <Controller
                    name="receiveSmsMessages"
                    control={control}
                    render={({ field }) => (
                      <div className="flex gap-2">
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                          className="pt-1"
                        />
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="receiveSmsMessages"
                            className="text-gray-800"
                          >
                            I{" "}
                            <span className="font-medium uppercase">
                              {" "}
                              agree{" "}
                            </span>
                            to receive critical text{" "}
                            <span className="text-light-purple">
                              (SMS)
                            </span>{" "}
                            messages specific to activity on my account from AHS
                            <span className="text-light-purple">
                              {" "}
                              MedXServices{" "}
                            </span>
                            at the mobile phone number provided above .
                          </label>
                          <p className="text-xs text-gray-500">
                            Such communication will always be related to my
                            account, including but not limited to: two factor
                            authentication codes (2FA), one time passwords
                            (OTP), order updates, order authorization requests,
                            account updates, and confirmation requests.{" "}
                            <span className="font-medium">
                              {" "}
                              Message and data rates may apply .{" "}
                            </span>
                            <span className="font-medium">
                              Message frequency varies.
                            </span>
                            Please{" "}
                            <span className="font-medium text-light-purple">
                              reply STOP
                            </span>{" "}
                            to opt out at any time OR you can update your
                            preferences in your profile at any time.
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                >
                  create my account{" "}
                </Button>
              )}
            </form>
          </div>

          <h5 className="text-center">
            Already have an account?{" "}
            <Button className="p-0" link>
              {" "}
              <Link to={"/login"} className="ml-1">
                Login{" "}
              </Link>{" "}
            </Button>
          </h5>
          <div className="mt-20 flex flex-row justify-between">
            <span className="mr-3 text-center text-sm text-gray-600 md:text-left">
              © <span id="currentYear">{new Date().getFullYear()}</span>{" "}
              MedX™. All Rights Reserved.
            </span>
            <a href="/legal" target="_blank">
              <span className="text-center text-sm text-gray-600 underline md:text-left">
                Legal Notices and Terms
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
