import React from 'react';
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
function DeclineInvitation() {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex justify-center items-center h-screen ">
                <div className='w-3/6 py-16  bg-light-text flex justify-center rounded-3xl'>
                    <div className="2xl:w-5/5 Xl:w-4/5 lg:w-4/5 md:w-5/5 sm:w-4/5  border border-light-purple border-solid rounded-3xl p-10 py-8">
                        <span className="rounded-full mb-5 m-auto bg-gray-300 flex justify-center items-center p-2 w-max">
                            <MedicalHistoryIcon />
                        </span>
                        <h4 className='font-inter text-dark-purple text-title font-extrabold text-center capitalize'>You declined this join invitation  </h4>

                        <p className='w-4/5 mt-9 text-center m-auto font-normal flex justify-center items-center'>
                            <span >
                                <Button className='text-light-purple pr-2 ' onClick={() => {
                                    navigate('/registration', {
                                        replace: true,
                                    })
                                }} link>
                                    Click here
                                </Button>
                            </span>
                            To go to MedX
                        </p>

                    </div>
                </div>

            </div>
        </>
    )
}

export default DeclineInvitation