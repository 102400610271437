import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import axios from "axios";
import {
  useJoinRequest,
  useJoinUserId,
  useRefreshTokenStore,
  useStoreIdFromUrl,
} from "../../store";
import { Navigate, useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import entityImg from "../../images/Screenshot 2023-07-12 160020.png";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { Avatar } from "primereact/avatar";
import { ProgressSpinner } from "primereact/progressspinner";

function ExistingGroupPractices() {
  const navigate = useNavigate();
  const { setJoinEntityId, setJoinUserId, joinEntityId, joinUserId } =
    useJoinUserId();
  const { entityJoined, setEntityJoined } = useJoinRequest();
  const [existingGroups, setExistingGroups] = useState([]);
  const { http, logOut } = useAxios();
  const { userId } = useTokenData();
  const [isLoading, setIsLoading] = useState(false);
  // get Id from url
  useEffect(() => {
    setJoinUserId(userId);
  }, []);
  useEffect(() => {
    getEntity();
  }, []);

  //get data from api
  const getEntity = async () => {
    setIsLoading(true);
    try {
      const response = await http.get(
        `/Entities/GetAllEntitiesToBeJoined/${userId}`,
      );
      const entitiesData = response.data;
      setExistingGroups(entitiesData);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const toast = useRef(null);
  const handleJoinRequest = async (joinEntityId) => {
    try {
      const confirmation = await confirmDialog({
        message: "Are you sure you want to Join?",
        icon: "pi pi-exclamation-triangle",
        position: "top",
        accept: async () => {
          const joinRequest = {
            applicationUserId: userId,
            entityId: joinEntityId,
          };
          try {
            const response = await http.post(
              `/JoiningRequests/AddJoinRequest`,
              joinRequest,
            );
            // setEntityJoined(response.data);
            // Show toast notification
            toast.current.show({
              severity: "success",
              summary: "success",
              detail: "Joined successfully",
              life: 4000,
            });
            navigate(`/joinEntity`, {
              state: { entityJoined: response.data },
              replace: true,
            });
          } catch (error) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.response.data.message,
              life: 4000,
            });
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [searchLetter, setSearchLetter] = useState("");
  const handleSearch = (e) => {
    setSearchLetter(e.target.value);
  };
  const filterData = (data) => {
    return data.filter((item) =>
      item.name.toLowerCase().includes(searchLetter.toLowerCase()),
    );
  };
  const filteredData = filterData(existingGroups);

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="grid justify-items-center">
        <div className="2xl:px-30 xl:px-30 md:px-15 my-10 w-11/12 rounded-3xl border bg-light-text p-6 py-9 shadow shadow-slate-300 sm:px-9 lg:w-4/6 lg:px-20 xl:w-4/6 2xl:w-4/6">
          <div className="grid grid-cols-1 place-items-center items-center text-center ">
            <div className="col-span-2 xl:col-span-1 ">
              <div className="flex h-36 w-36 items-center justify-center  overflow-hidden rounded-full border-2 border-dashed border-gray-300 bg-slate-100 ">
                <MedicalHistoryIcon />
              </div>
            </div>
            <div className="col-span-4 ">
              <h4 className="font-inter text-title font-extrabold capitalize text-dark-purple ">
                Join an existing group practice
              </h4>
              <p className="mt-2 font-Poppins text-lg text-p leading-loose text-blue-500">
                Please pick the clinic/provider you are working with.
              </p>
            </div>
          </div>
          {!isLoading ? (
            <>
              <div className="mt-14 mb-9 grid grid-cols-6 gap-3">
                <div className="col-span-4 xl:col-span-5">
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search existing clinics/providers"
                      className="w-full"
                      value={searchLetter}
                      onChange={handleSearch}
                    />
                  </span>
                </div>

                <div className="col-span-2 xl:col-span-1">
                  <Button label="Search" className="w-full" />
                </div>
              </div>

              <div>
                {filteredData.length > 0 ? (
                  <div className="my-2 h-96 overflow-y-auto">
                    {filteredData.map((Entities, index) => (
                      <div key={index} className="cards mt-9 ">
                        <Card>
                          <div className="flex items-center gap-5">
                            <div>
                              {Entities.entityImageURL ? (
                                <img
                                  src={Entities.entityImageURL}
                                  alt="entity "
                                  className="h-14 w-14 rounded-full "
                                />
                              ) : (
                                <Avatar
                                  label={Entities.name[0]}
                                  shape="circle"
                                  className="h-14 w-14 bg-blue-500 text-white"
                                />
                              )}
                            </div>
                            <div>
                              <h4> {Entities.name} </h4>
                            </div>
                          </div>
                          <p className="mt-2">{Entities.description}</p>
                          <div className="mt-5 flex flex-col gap-3 md:flex-row md:items-end md:justify-between md:gap-0">
                            <div className="justify-items-end">
                              <span>
                                {" "}
                                <i
                                  className="pi pi-map-marker"
                                  style={{ fontSize: "1rem" }}
                                ></i>{" "}
                                {Entities.address.lineOne} ,{" "}
                              </span>
                              <span>
                                {" "}
                                <i
                                  className="pi pi-map pl-2"
                                  style={{ fontSize: "1rem" }}
                                ></i>{" "}
                                {Entities.address.state}{" "}
                              </span>
                            </div>
                            {Entities.isMember == true ? (
                              <div className="rounded-md bg-slate-200 px-5 py-3">
                                <p className="capitalize text-light-purple">
                                  Already joined
                                </p>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  severity="success"
                                  className="grid w-full md:w-auto"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setJoinEntityId(Entities.id);
                                    handleJoinRequest(Entities.id);
                                  }}
                                >
                                  {" "}
                                  Request to join
                                </Button>
                              </div>
                            )}
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="h-96 overflow-y-auto">
                    <h3 className="mt-4 font-Poppins text-lg text-p font-medium capitalize text-dark-purple">
                      No matching entities found. Please try a different search
                      term
                    </h3>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <ProgressSpinner />
            </div>
          )}
          <div className="text-end">
            <Button
              onClick={logOut}
              label="Logout"
              icon="pi pi-sign-out"
              className="mt-14"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExistingGroupPractices;
