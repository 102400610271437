import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRefreshTokenStore } from "../../store";


function LandingPageGuard() {
  const { refreshToken } = useRefreshTokenStore();
  return  refreshToken ? <Navigate to={"/Dashboard/"} /> : <Outlet />;
}

export default LandingPageGuard;
