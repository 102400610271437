import React, { forwardRef } from "react";

const QuestionsWrapper = forwardRef(({ children, ...props }, ref) => {
  return (
    <ul className="w-full h-full" ref={ref} {...props}>
      {children}
    </ul>
  );
});

export default QuestionsWrapper;
