import { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import TaxonomyTree from "../heplers/TaxonomyTree";
import useTokenData from "../../hooks/useTokenData";
import { useRefreshTokenStore, useTokenStore } from "../../store";
import useRefreshTokenHook from "../../hooks/useRefreshTokenHook";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";

function IndividualFinishStep() {
  const { IdentifierId, entityId } = useTokenData();
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { refresh } = useRefreshTokenHook();
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  //   fetch state data from api
  const [selectedState, setSelectedState] = useState([]);

  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setSelectedState(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  // medicals expand form
  const [medicalLicenseList, setMedicalLicenseList] = useState([
    {
      licenseNumber: "",
      stateId: "",
    },
  ]);

  const defaultValues = {
    identifierId: IdentifierId,
    npIs: [
      {
        npiNumber: "",
      },
    ],
    medicalLicenses: medicalLicenseList,
    assignTaxonomies: selectedChooseList,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: defaultValues });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "medicalLicenses", // unique name for your Field Array
  });

  const addMedicalLicense = () => {
    append({
      licenseNumber: "",
      stateId: "",
    });
  };

  const onSubmit = async (formData) => {
    const modifiedOptionsList = selectedChooseList.map((option) => {
      const { label, ...rest } = option;
      return rest;
    });
    formData.assignTaxonomies = modifiedOptionsList;

    setIsLoading(true);
    try {
      const response = await http.post(
        "/Identifications/AssignDueDiligence",
        formData,
      );
      const currentRefreshToken = JSON.parse(
        localStorage.getItem("refreshToken"),
      ).state.refreshToken;
      const refreshTokenResponse = await refresh.post(
        `/Authentication/RefreshToken/${entityId}`,
        {
          refreshToken: currentRefreshToken,
        },
      );
      setToken(refreshTokenResponse.data.token);
      setRefreshToken(refreshTokenResponse.data.refreshToken);
      Cookies.remove("userData");
      reset();
      navigate(`/welcome`, {
        replace: true,
        state: { fromSetup: true },
      });
    } catch (error) {
      if (error.message === "Please select at least one taxonomy as primary") {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select at least one taxonomy as primary",
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    }
    setIsLoading(false);
  };

  const validateLicenseNumber = (value, allValues) => {
    if (!value) {
      return "License Number is required";
    }

    const licenseNumbers = allValues.medicalLicenses.map(
      (license) => license.licenseNumber,
    );

    const count = licenseNumbers.filter((num) => num === value).length;
    if (count > 1) {
      return "License Number must be unique";
    } else if (!value.trim()) {
      return "License Number can not be empty";
    }

    return undefined;
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex h-full w-full items-center justify-center">
        <div className="w-full rounded-3xl bg-light-text p-10 sm:w-full md:w-3/4 lg:w-9/12 ">
          <div className="grid grid-cols-2 items-center sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6">
            <div className="col-span-1 sm:col-span-2">
              <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-slate-100 sm:h-14 sm:w-14">
                <MedicalHistoryIcon />
              </div>
            </div>
            <div className="col-span-1 sm:col-span-3">
              <h4 className="font-inter text-base font-extrabold capitalize text-dark-purple sm:text-sm">
                Set up my individual provider profile
              </h4>
            </div>
          </div>
          <div className="mt-2">
            <p>
              Please populate all the NPI's associated with your practice below,
              along with the medical license and taxonomies
            </p>
          </div>

          <div className="form-container">
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            >
              <div className="mt-4">
                <label
                  htmlFor="npiNumber"
                  className="font-Poppins text-base capitalize leading-loose text-gray-500"
                >
                  individual NPI <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-right w-full">
                  <InputText
                    id="npiNumber"
                    placeholder="0000000000"
                    className="w-full"
                    keyfilter={/^\d+$/}
                    {...register("npIs[0].npiNumber", {
                      required: "NPI is required",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "NPI must be exactly 10 digits",
                      },
                    })}
                    maxLength={10}
                  />
                </span>
                {errors.npIs && errors.npIs[0] && errors.npIs[0].npiNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.npIs[0].npiNumber.message}
                  </p>
                )}
              </div>
              <label className="mt-4 block font-Poppins text-base capitalize leading-loose text-gray-500">
                individual medical license{" "}
                <span className="text-red-500">*</span>
              </label>
              <div>
                {fields.map((field, index) => {
                  return (
                    <div
                      key={field.id}
                      className="grid grid-cols-12 gap-2 py-2 "
                    >
                      <div className="col-span-12 flex flex-col gap-2 md:col-span-4 ">
                        <Controller
                          name={`medicalLicenses.${index}.stateId`}
                          control={control}
                          rules={{
                            required: "Please select a state",
                          }}
                          render={({ field }) => (
                            <div key={field.id}>
                              <Dropdown
                                name={field.name}
                                id={field.name}
                                value={field.value}
                                placeholder="State"
                                focusInputRef={field.ref}
                                options={selectedState.map((option) => ({
                                  value: option.id,
                                  label: option.name,
                                }))}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                className="w-full"
                              />
                            </div>
                          )}
                        />
                        {errors?.medicalLicenses?.[index]?.stateId && (
                          <div className="text-red-500">
                            {errors?.medicalLicenses?.[index]?.stateId?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-span-12 flex flex-col gap-2 md:col-span-4 ">
                        <Controller
                          name={`medicalLicenses.${index}.licenseNumber`}
                          control={control}
                          rules={{
                            validate: validateLicenseNumber,
                            required: "License Number is required",
                          }}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="E-12345"
                              className="w-full"
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                        {errors?.medicalLicenses?.[index]?.licenseNumber && (
                          <div className="text-red-500">
                            {
                              errors?.medicalLicenses?.[index]?.licenseNumber
                                ?.message
                            }
                          </div>
                        )}
                      </div>
                      <div className="col-span-12 flex flex-col gap-2 md:col-span-4 ">
                        <div className="flex items-center gap-2">
                          {fields.length > 1 && (
                            <span
                              className="flex items-center justify-center py-3"
                              onClick={() => remove(index)}
                            >
                              <i
                                className="pi pi-trash cursor-pointer text-red-500"
                                style={{ fontSize: "1.3rem" }}
                              ></i>
                            </span>
                          )}
                          {fields.length - 1 === index && (
                            <Button
                              type="button"
                              link
                              onClick={(e) => {
                                addMedicalLicense();
                              }}
                              label="Add medical license"
                              icon="pi pi-plus"
                            ></Button>
                          )}
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-3">
                <label className="block font-Poppins text-base capitalize leading-loose text-gray-500">
                  individual taxonomy <span className="text-red-500">*</span>
                </label>
                <span className="text-sm">
                  Click the arrow icon to select a taxonomy.
                </span>
                <TaxonomyTree
                  selectedChooseList={selectedChooseList}
                  selectedOptionsList={selectedOptionsList}
                  setSelectedChooseList={setSelectedChooseList}
                  setSelectedOptionsList={setSelectedOptionsList}
                />
                {!selectedChooseList[0] && (
                  <p className="mt-1 text-left  text-red-500">
                    Select taxonomy & at least one is primary
                  </p>
                )}
              </div>

              <div className="mt-5 text-left">
                {isLoading ? (
                  <div className="text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex justify-end gap-8">
                      <Button
                        disabled={!selectedChooseList[0]}
                        type="submit"
                        className="mt-9 justify-center rounded-full  bg-light-purple py-2.5 font-inter  font-normal capitalize text-light-text "
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualFinishStep;
