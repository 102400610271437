import React from "react";
import useTokenData from "../../hooks/useTokenData";
import { Navigate, Outlet } from "react-router-dom";
import { useSwitchOrdering } from "../../store";

function UserRoleGuard() {
  const { userRoles, IsHidden, IsEntityApproved ,UserJourneyStatus} = useTokenData();
  const { isOrderingMode } = useSwitchOrdering();

  if (userRoles === "Staff" && isOrderingMode == true) {
    return <Navigate replace to="/ordering/FindServices" />;
  } else if (userRoles === "Staff" && isOrderingMode == false && UserJourneyStatus ==='2') {
    return <Navigate replace to="/Dashboard/" />;
  }

  return <Outlet />;
}

export default UserRoleGuard;
