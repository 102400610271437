import React from "react";

export default function RevenueIcon() {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 529'>
        <rect
          width='40'
          height='40'
          rx='20'
          fill='#4CB8FF'
          fillOpacity='0.15'
        />
        <path
          id='Vector'
          d='M24.9859 23.2697C24.9859 21.4278 23.6421 19.7937 21.8034 19.406L18.4653 18.6859C17.8713 18.5612 17.4328 18.035 17.4328 17.4257C17.4328 16.7194 18.0269 16.1378 18.7482 16.1378H20.9406C21.3932 16.1378 21.8458 16.3316 22.1429 16.664C22.2843 16.8163 22.3975 16.9964 22.4682 17.1902C22.6945 17.8134 23.3876 18.1319 24.0099 17.9103C24.6464 17.6888 24.9717 17.0102 24.7454 16.4009C24.5757 15.9162 24.3069 15.473 23.9392 15.0853C23.232 14.3098 22.2419 13.8666 21.1952 13.7974V12.191C21.1952 11.5401 20.6577 11 19.9788 11C19.2999 11 18.7624 11.5262 18.7624 12.191V13.7697H18.7341C16.669 13.7697 15 15.4176 15 17.4257C15 19.129 16.2306 20.6385 17.942 20.9985L21.2801 21.7187C22.0156 21.871 22.553 22.5219 22.553 23.2697C22.553 24.1421 21.8317 24.8484 20.9406 24.8484H19.0594C18.3805 24.8484 17.7581 24.4191 17.5318 23.7959C17.3055 23.1727 16.6124 22.8542 15.9901 23.0758C15.3536 23.2974 15.0283 23.9759 15.2546 24.5853C15.7921 26.0809 17.1924 27.1057 18.7907 27.2026V28.809C18.7907 29.4599 19.3281 30 20.0071 30C20.686 30 21.2235 29.4738 21.2235 28.809V27.2026C23.3168 27.0641 25 25.3608 25 23.2697H24.9859Z'
          fill='#0EA5E9'
        />
      </g>
    </svg>
  );
}
