import { useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import useHandleResize from "../../../hooks/useHandleResize";
import ReusableDataList from "../../ReusableDataList/ReusableDataList";
import useDelayedInputChange from "../../../hooks/useDelayedInputChange";

import "react-tagsinput/react-tagsinput.css";

export default function ManageAdmins() {
  //all const
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState();
  const [refetchInvitations, setRefetchInvitations] = useState(false);
  const { isMobile } = useHandleResize();
  const { userId } = useTokenData();
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );
  const toast = useRef(null);
  const { http } = useAxios();
  const columns = [
    { name: "Name ", value: "userName", style: { width: "30%" } },
  ];
  const [visible, setVisible] = useState(false);
  const menuRight = useRef({});
  const invitationCardMenuRef = useRef({});

  // implement react hook form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adminEmail: "",
    },
  });
  // create invitation system admin
  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      await http.post(
        `/Authentication/InviteSystemAdmin?email=${formData.adminEmail}`,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Invitation sent successfully",
        life: 4000,
      });
      setValue("adminEmail", "");
      setRefetchInvitations(!refetchInvitations);
    } catch (error) {
      setIsLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const invitationsCardTemplate = (rowData) => {
    return (
      <div className="flex flex-col gap-4 p-2">
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            <i className="pi pi-user" />
            <span className="text-slate-500">&nbsp;email:&nbsp;</span>
            <span>{rowData.email}</span>
          </p>
          {invitationsCardActionTemplate(rowData)}
        </div>
        {invitationStatusTemplate(rowData)}
      </div>
    );
  };

  const invitationsCardActionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-v"
          text
          rounded
          onClick={(event) => {
            invitationCardMenuRef.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getInvitationsMenuItems(rowData)}
          popup
          ref={(el) => (invitationCardMenuRef.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
        />
      </>
    );
  };
  const getInvitationsMenuItems = (rowData) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash text-red-500",
        command: () => handleDeleteInvitation(rowData),
      },
      {
        label: "Resend",
        icon: "pi pi-undo",
        command: () => {
          handleResendInvitation(rowData.id);
        },
      },
    ];

    return items;
  };

  const invitationStatusTemplate = (rowData) => {
    const statusData = {
      1: { className: "bg-orange-100 text-orange-600", content: "Pending" },
      2: { className: "bg-green-200 text-green-700", content: "Approved" },
      4: { className: "bg-red-200 text-red-700", content: "Declined" },
    };

    const status = statusData[rowData.status];
    return status ? (
      <div
        className={`max-w-[200px] rounded-md p-2 text-center text-sm font-bold sm:p-3 sm:text-base ${status.className}`}
      >
        {status.content}
      </div>
    ) : null;
  };

  const invitationActionsTemplate = (rowData) => {
    return (
      <div className="flex items-center">
        <Button
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          rounded
          text
          onClick={() => handleDeleteInvitation(rowData)}
          severity="danger"
        />
        <Button
          tooltip="Resend"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-undo"
          rounded
          text
          onClick={() => handleResendInvitation(rowData.id)}
        />
      </div>
    );
  };

  const handleDeleteInvitation = async (rowData) => {
    confirmDialog({
      header: "Delete Invitation",
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        await deleteInvitation(rowData.id);
      },
    });
  };

  const deleteInvitation = async (id) => {
    try {
      const res = await http.delete(`/Invitations/${id}`);
      setRefetchInvitations(!refetchInvitations);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data,
        life: 2000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error?.response?.data?.message,
        life: 2000,
      });
    }
  };

  const handleResendInvitation = async (id) => {
    try {
      const res = await http.post(
        `/Invitations/ResendSystemAdminInvitation/${id}`,
      );
      setRefetchInvitations(!refetchInvitations);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 2000,
      });
    }
  };

  // responsive card actions
  const menuTemplate = (item) => {
    return (
      <div onClick={item.command} className="p-menuitem-content">
        <button className="align-items-center p-menuitem-link flex w-full">
          <span className={`${item.icon} ${item.styles}`} />
          <span className="mx-2">{item.label}</span>
        </button>
      </div>
    );
  };
  const getMenuItems = (rowData) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        styles: "text-red-500",
        command: () => handleDelete(rowData),
        template: menuTemplate,
      },
      {
        label: "Enable/Disable",
        icon: !rowData.isEnabled ? "pi pi-eye-slash" : "pi pi-eye",
        styles: !rowData.isEnabled ? "text-red-700" : "text-emerald-500",
        command: () => {
          handleToggleDisable(rowData);
        },
        template: menuTemplate,
      },
    ];

    return items;
  };

  const cardActionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };
  const cardTemplate = (row) => {
    const isDisabled = row.id === userId;
    return (
      <>
        <div
          className={`flex justify-between p-2 ${isDisabled ? "p-disabled" : ""}`}
        >
          <div>
            <div className="flex items-center justify-between gap-2">
              <p className="font-semibold">
                <i className="pi pi-user" />
                <span className="text-slate-500">&nbsp;name:&nbsp;</span>
                <span>{row.userName}</span>
                {isDisabled && (
                  <span className="pl-2 capitalize text-light-purple">
                    (Me)
                  </span>
                )}
              </p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <p className="font-semibold">
                <i className="pi pi-user" />
                <span className="text-slate-500">&nbsp;email:&nbsp;</span>
                <span>{row.email}</span>
              </p>
            </div>
          </div>

          <div>{cardActionTemplate(row)}</div>
        </div>
      </>
    );
  };

  //delete action table
  const deleteRow = async (rowData) => {
    try {
      const endPointUrl = `/Users/DeleteSystemAdmin/${rowData.id} `;
      const res = await http.delete(endPointUrl);
      setRefetch(!refetch);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 2000,
      });
    }
  };
  const handleDelete = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        setIsLoading(true);
        try {
          await new Promise((resolve) => {
            deleteRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      },
    });
  };
  // enabled disabled action table
  const toggleDisableRow = async (rowData) => {
    try {
      const endPointUrl = `/Users/EnableOrDisableSystemAdmin/${rowData.id} `;
      const res = await http.put(endPointUrl, {
        isEnabled: !rowData.isEnabled,
      });
      setRefetch(!refetch);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 2000,
      });
    }
  };
  const handleToggleDisable = async (rowData) => {
    confirmDialog({
      message: `Are you sure you want to ${rowData.isEnabled ? "disable" : " enable"}?`,
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        setIsLoading(true);
        try {
          await new Promise((resolve) => {
            toggleDisableRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      },
    });
  };
  // apply action table
  const actionsTemplate = (rowData) => {
    return (
      <>
        <Button
          onClick={() => handleDelete(rowData)}
          link
          tooltip="delete"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className="pi pi-trash text-red-500"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => handleToggleDisable(rowData)}
          link
          tooltip={`${rowData.isEnabled ? "disabled" : "enabled"}`}
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className={`pi ${!rowData.isEnabled ? "pi-eye-slash text-red-700" : "pi-eye text-green-500"} `}
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
      </>
    );
  };
  const EmailOwnerTemplate = (rowData) => (
    <>
      <p className="text-p">{rowData.email}</p>
      <h4 className="text-mobileTitle">
        {rowData.id === userId && (
          <div>
            <span className="text-sm font-bold capitalize text-light-purple sm:block sm:text-base">
              me
            </span>
          </div>
        )}
      </h4>
    </>
  );
  const rowClassName = (rowData) => {
    if (rowData.id === userId) {
      return "p-disabled";
    }
    return "";
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="left mr-8 mt-4 min-w-[345px]">
        <h5 className="font-inter text-lg font-extrabold">Invite New Admin</h5>
        <p className="text-p text-normal-text">
          We will email him/her instructions and send a link to sign up.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="btn-invite flex flex-row flex-nowrap items-center py-4">
          <div className="flex-column flex-grow flex-nowrap items-center justify-end">
            <div className="flex w-full flex-col gap-2 sm:flex sm:flex-row">
              <InputText
                placeholder="Admin Email"
                className="mr-4 w-full basis-4/12 py-3"
                {...register("adminEmail", {
                  required: "Please enter a valid email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              />
              <Button
                className="w-full rounded-md bg-light-purple text-center font-inter sm:w-auto"
                loading={isLoading}
                type="submit"
                label="Send Invitation"
              ></Button>
            </div>
            {errors && (
              <div className="mt-1 block w-full text-red-500">
                {errors?.adminEmail?.message}
              </div>
            )}
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-10">
        <ReusableDataList
          title={"All Invitations"}
          fromFront
          dataLink="/Invitations/GetSystemAdminInvitations"
          columns={[{ name: "Email", value: "email", style: { width: "50%" } }]}
          actionTemplates={
            isMobile
              ? [{ template: invitationsCardTemplate }]
              : [
                  { template: invitationStatusTemplate, header: "Status" },
                  { template: invitationActionsTemplate, header: "Action" },
                ]
          }
          refetch={refetchInvitations}
          cardFilters={["email"]}
        />
        <ReusableDataList
          title={"All Admins "}
          fromFront={true}
          sendSearchData={handleInputChange}
          searchValue={searchValue}
          dataLink={`/Users/GetAllAdmins`}
          columns={columns}
          actionTemplates={
            isMobile
              ? [{ template: cardTemplate }]
              : [
                  {
                    template: EmailOwnerTemplate,
                    header: "Email",
                    style: { width: "30%" },
                  },
                  { template: actionsTemplate, header: "Actions" },
                ]
          }
          refetch={refetch}
          rowClassName={rowClassName}
          cardFilters={["name", "email"]}
        />
      </div>
    </>
  );
}
