import React, { Suspense, lazy } from "react";
import LoadingPage from "../../LoadingPage";

export default function RenderTransactionTracking() {
  const TransactionTracking = lazy(() => import("./TransactionTracking"));
  return (
    <Suspense fallback={LoadingPage}>
      <TransactionTracking />
    </Suspense>
  );
}
