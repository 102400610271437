import { useRef, useState, useEffect } from "react";
import { useLocationStore, useStoreEntityIdFromUrl } from "../../store";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router";
import { Toast } from "primereact/toast";
import useAxios from "../../hooks/useAxios";
import { useNavigate, useLocation } from "react-router-dom";
import useTokenData from "../../hooks/useTokenData";
import { trimValidation } from "../utility/Utils";
function LocationForm({
  fromService = false,
  locationRefetch,
  setLocationRefetch,
  setLocationVisible,
}) {
  const params = useParams();
  const toast = useRef(null);
  const { http } = useAxios();
  const [states, setStates] = useState([]);
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { entityId } = useTokenData();
  const navigate = useNavigate();
  const locationData = useLocation()?.state?.locationData;

  const toastFunction = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
  const { setLocationsChangeBar } = useLocationStore();
  const defaultValues = {
    name: "",
    address: {
      lineOne: "",
      lineTwo: null,
      state: "",
      city: "",
      zipCode: "",
    },
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setStates(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const submitForm = async (e) => {
    try {
      !locationData
        ? await http.post(`/Locations/CreateEntityLocation/${entityId}`, {
            name: e.name,
            address: e.address,
          })
        : await http.put(`/Locations/UpdateEntityLocation/${locationData.id}`, {
            name: e.name,
            address: e.address,
          });
      setLocationsChangeBar(false);
      toastFunction(
        "success",
        "Success",
        locationData
          ? "Location Edited Successfully"
          : "Location Added Successfully",
      );
      if (fromService) {
        setLocationRefetch(!locationRefetch);
      } else {
        navigate(`/rendering/mylocations/`);
      }
    } catch (err) {
      toastFunction("error", "Error", "Failed Updating Locations");
    }
  };

  useEffect(() => {
    setEntityId(params.id);
    getStates();
    locationData && reset(locationData);
  }, []);

  const handleCancel = () => {
    if (fromService) {
      setLocationVisible(false);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="flex items-center justify-center ">
        <div
          className={
            !fromService
              ? "rounded-3xl bg-light-text p-0 shadow shadow-slate-300 sm:py-8 sm:pl-10 sm:pr-10"
              : " p-0 sm:py-8 sm:pl-10 sm:pr-10"
          }
        >
          <div className=" form-container mb-3">
            <h3 className="mb-2 text-xl font-bold">
              {locationData ? "Edit Location" : "Set up Locations"}
            </h3>
            <p className="mb-7">
              {locationData
                ? "Edit locations where you currently operate your services."
                : "Add locations where you currently operate your services."}
            </p>
            <form className="flex flex-col" onSubmit={handleSubmit(submitForm)}>
              <div>
                <div className="mt-5">
                  <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                    Location Name<span className="ml-1 text-red-600">*</span>
                  </label>
                  <span className=" w-full">
                    <InputText
                      {...register("name", {
                        required: "Location name is required",
                        validate: (value) =>
                          trimValidation(value, "Location Name"),
                      })}
                      placeholder="Location Name"
                      className="w-full pb-2 pt-2"
                    />
                    {errors.name && errors.name && (
                      <p className="mt-1 text-red-500">{errors.name.message}</p>
                    )}
                  </span>
                  <div className="mt-4">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                      Address Line One
                      <span className="ml-1 text-red-600">*</span>
                    </label>
                    <span className="w-full">
                      <InputText
                        {...register("address.lineOne", {
                          required: "Address line one is required",
                          validate: (value) =>
                            trimValidation(value, "Address Line One"),
                        })}
                        placeholder="address line one"
                        className="w-full pb-2 pt-2"
                      />
                      {errors.address && errors.address.lineOne && (
                        <p className="mt-1 text-red-500">
                          {errors.address.lineOne.message}
                        </p>
                      )}
                    </span>
                    <div className="mt-3">
                      <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                        Address Line Two
                      </label>
                      <span className=" w-full">
                        <InputText
                          {...register("address.lineTwo")}
                          placeholder="address line two"
                          className="w-full pb-2 pt-2"
                        />
                      </span>
                    </div>

                    <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ">
                      <div>
                        <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                          City
                          <span className="ml-1 text-red-600">*</span>
                        </label>
                        <span className=" col-span-1">
                          <InputText
                            {...register("address.city", {
                              required: "City is required",
                              validate: (value) =>
                                trimValidation(value, "City"),
                            })}
                            placeholder="City"
                            className="w-full"
                          />
                          {errors.address && errors.address.city && (
                            <p className="mt-1 text-red-500">
                              {errors.address.city.message}
                            </p>
                          )}
                        </span>
                      </div>

                      <div>
                        <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                          State
                          <span className="ml-1 text-red-600">*</span>
                        </label>
                        <span className=" col-span-1 ">
                          <Controller
                            name="address.state"
                            control={control}
                            rules={{ required: "Please select a state" }}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                value={field.value}
                                placeholder="State"
                                focusInputRef={field.ref}
                                options={states.map((option) => ({
                                  value: option.name,
                                  label: option.name,
                                }))}
                                onChange={(e) => field.onChange(e.value)}
                                className="md:w-14rem w-full "
                              />
                            )}
                          />
                          {errors.address && errors.address.state && (
                            <p className="mt-1 text-red-500">
                              {errors.address.state.message}
                            </p>
                          )}
                        </span>
                      </div>

                      <div>
                        <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                          ZIP Code
                          <span className="ml-1 text-red-600">*</span>
                        </label>
                        <span className=" col-span-1 ">
                          <Controller
                            name="address.zipCode"
                            control={control}
                            rules={{
                              required: "ZIP code is required",
                              pattern: {
                                value: /^\d{5}$/,
                                message: "ZIP Code must be exactly 5 digits",
                              },
                            }}
                            render={({ field }) => (
                              <InputText
                                value={field.value}
                                maxLength={5}
                                keyfilter="num"
                                placeholder="ZIP Code"
                                className="w-full"
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                              />
                            )}
                          />
                          {errors.address && errors.address.zipCode && (
                            <p className="mt-1 text-red-500">
                              {errors.address.zipCode.message}
                            </p>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2 sm:flex sm:flex-row sm:justify-end">
                <Button
                  type="button"
                  onClick={handleCancel}
                  className="w-full justify-center rounded-full bg-white px-5 py-2 font-inter font-medium capitalize text-light-purple sm:w-auto"
                >
                  cancel
                </Button>
                {locationData ? (
                  <Button
                    type="submit"
                    className="w-full rounded-full bg-light-purple p-2 px-3 font-medium text-white sm:w-auto"
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="w-full justify-center rounded-full bg-light-purple p-2 px-3 font-medium text-white sm:w-auto"
                  >
                    Add Location
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationForm;
