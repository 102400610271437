import { useEffect, useLayoutEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

import { PrimeReactProvider } from "primereact/api";

import AppRoutes from "./AppRoutes";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import "./index.css";
import LogOutDialog from "./components/SessionExpiredModal";
import useHandleResize from "./hooks/useHandleResize";
import useAxios from "./hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { useLayoutToast, useSystemAdmin } from "./store";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import {
  useRefreshTokenStore,
  useStoreSystemAdmin,
  useTokenStore,
} from "./store";

export default function App() {
  const { http } = useAxios();
  const toast = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useHandleResize();
  const { setLayoutToast } = useLayoutToast();
  const { IsAdmin, setIsAdmin } = useSystemAdmin();
  const { token, setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { adminId, setAdminId } = useStoreSystemAdmin();

  const unPersonate = async () => {
    try {
      const response = await http.post(
        `/Entities/EndEntityImpersonation/${adminId}`,
      );
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);
      setAdminId(null);
      setIsAdmin(false);
      navigate(`/admin/ourPractices`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    toast.current && setLayoutToast(toast);
  }, []);

  useLayoutEffect(() => {
    document
      .getElementById("body-container")
      .scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <PrimeReactProvider>
      <div
        id="body-container"
        className={`${isMobile ? "h-[100svh]" : "h-[100vh]"} overflow-auto`}
      >
        {IsAdmin && (
          <div className="pointer-events-none absolute top-0 z-[10003] h-full w-full border-8 border-[#F59E0B]">
            <Tag
              severity={"warning"}
              className="absolute left-1/2 -translate-x-1/2 rounded-t-none"
            >
              <div className="flex flex-row">
                <div className="font-inter text-xs">Impersonation mode</div>{" "}
                <div
                  className="pi pi-times pointer-events-auto ml-2 cursor-pointer text-xs"
                  onClick={unPersonate}
                ></div>
              </div>
            </Tag>
          </div>
        )}
        <Toast ref={toast} />
        <LogOutDialog />
        <AppRoutes></AppRoutes>
      </div>
    </PrimeReactProvider>
  );
}
