import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { ProviderInfoCard } from "../ProviderInfoCard";
import { ProviderInfoCardSkeleton } from "../../skeletons/ProviderInfoCardSkeleton";
function PracticeDetails() {
  const { http } = useAxios();
  const location = useLocation();
  const rowData = location.state.rowData;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getPracticeDetails = async () => {
    try {
      const response = await http.get(
        `/DueDiligence/GetDueDiligenceById/${rowData.dueDiligenceId}`,
      );
      setData(response.data);
    } catch (error) {
      console.error("Error completing Fetching data", error);
    }
  };

  useEffect(() => {
    getPracticeDetails();
  }, []);
  let tag = data?.entity?.entityType === 1 ? "practice" : "individual";
  return (
    <>
      {data ? (
        <ProviderInfoCard
          isLoading={isLoading}
          name={data.entity.entityType === 1 ? data.entity.name : data.userName}
          tag={data.entity.entityType === 1 ? "practice" : "individual"}
          createdAt={data.entity.createdAt}
          taxId={data.identification.taxID}
          entityName={data.entity.name}
          ownerPhoneNumber={data.ownerPhoneNumber}
          entityAddress={
            data.entity.entityPrincipalAddress &&
            data.entity.entityPrincipalAddress.lineOne +
              ", " +
              data.entity.entityPrincipalAddress.state +
              ", " +
              data.entity.entityPrincipalAddress.city +
              ", " +
              data.entity.entityPrincipalAddress.zipCode
          }
          NPIs={data.npIs}
          stateLicences={data.medicalLicenses}
          taxonomies={data.assignTaxonomies}
          entityId={data?.identification?.entityId}
          agentAddress={data.entity.registeredAgentAddress}
          contactInfo={[
            data.entity.technicalSupportName,
            data.entity.technicalSupportPhoneNumber,
            data.entity.technicalSupportEmail,
          ]}
          clickable={false}
          registeredBy={[
            data.userName,
            data.registeredPhoneNumber,
            data.registeredEmail,
          ]}
          identityImage={`${data.identification.identityImage}`}
          selfieImage={`${data.identification.selfieImage}`}
          imageProviderImageSrc={data.entity.imageUrl}
          // status={data.status === 1 ? undefined : data.status}
        />
      ) : (
        <ProviderInfoCardSkeleton infoCount={7} />
      )}
    </>
  );
}

export default PracticeDetails;
