import { useRef, useState } from "react";

import { Link, Navigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";

export const ForgetPassword = () => {
  const { http } = useAxios();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let [isSent, setIsSent] = useState(false);
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const response = await http.post(
        `/Authentication/ForgotPassword?email=${formData.email}`,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 2000,
      });
      setIsSent(true);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error.response.data.message ||
          "Something went wrong. Please try again later",
        life: 2000,
      });

      setIsDisabled(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex h-[80vh] w-[100vw] items-center justify-center">
        <div className=" mt-10 rounded-3xl border border-solid border-light-purple bg-light-text p-16">
          <h4 className="text-center font-philosopher text-2xl font-extrabold capitalize text-dark-purple">
            MedX
          </h4>
          <div className="mt-6 flex flex-col gap-4 text-center">
            <h3 className="font-inter font-bold text-lg">Forgot Your Password?</h3>
          </div>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="email" className="font-inter font-semibold">
                Email<span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <span className="p-input-icon-left mt-1 w-full">
                <i className="pi pi-user" />
                <InputText
                  disabled={isDisabled}
                  id="email"
                  placeholder="Enter your email address"
                  className="w-full pb-2 pt-2"
                  {...register("email", {
                    required: "Please enter your email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                />
              </span>
              {errors.email && (
                <p className="text-red-500 ">{errors.email.message}</p>
              )}
              <div className="flex items-end justify-end gap-2">
                <Button
                  disabled={isDisabled}
                  type="submit"
                  className="mt-9 w-full justify-center self-center rounded-lg bg-light-purple px-8 py-2.5 font-inter font-normal capitalize text-light-text "
                >
                  {isLoading ? "Sending..." : isSent ? "Sent" : "RESET PASSWORD"}
                </Button>
              </div>
              {isSent && (
                <div className="flex flex-col items-start pt-4 text-end">
                  <div className="flex items-center gap-2">
                    <i className="pi pi-info-circle text-green-600"></i>
                    <p className="font-inter text-sm text-gray-500">
                      Reset password link sent to your email successfully,
                    </p>
                  </div>
                  <p className="pl-4 font-inter text-sm text-gray-500">
                    please check your email.
                  </p>
                </div>
              )}
              <div className="text-center font-inter text-sm">
                <p className="pt-4">
                  Did you remember your password?{" "}
                  <Link
                    to={
                      location.pathname.includes("admin")
                        ? "/admin/login"
                        : "/login"
                    }
                    className="text-light-purple hover:underline"
                  >
                    Back to login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
