import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useEffect } from "react";
import ChangePassword from "./ChangePassword";
import useTokenData from "../../hooks/useTokenData";
import DialogUpdatePin from "./DialogUpdatePin";
import { Message } from "primereact/message";
import DialogCreatePin from "./DialogCreatePin";
import { useLocation } from "react-router-dom";
import { useSystemAdmin } from "../../store";
function Security({ fromTap = true }) {
  const { VerifyProvider, HasPin, IsExternalLogin } = useTokenData();
  const { IsAdmin } = useSystemAdmin();
  const location = useLocation();
  const [headerActiveIndex, setHeaderActiveIndex] = useState(
    location.state?.activeIndex || 0,
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state?.activeIndex !== undefined) {
      setHeaderActiveIndex(location.state.activeIndex);
    }
    if (IsExternalLogin === "True") {
      setHeaderActiveIndex(1);
    }
  }, [location.state?.activeIndex, IsExternalLogin]);
  return (
    <>
      {IsAdmin && (
        <Message
          severity="warn"
          text="Warning Message: Updates to this page are disabled for impersonated users and can only be completed by the user themselves."
          className="my-4 justify-normal !border !border-orange-200 font-semibold"
        />
      )}

      <TabView
        activeIndex={headerActiveIndex}
        onTabChange={(e) => setHeaderActiveIndex(e.index)}
      >
        {IsExternalLogin === "False" && (
          <TabPanel header="Change Password">
            <ChangePassword />
          </TabPanel>
        )}
        {VerifyProvider !== "NULL" && (
          <TabPanel header="Provider Authentication PIN">
            {/* {HasPin === "True" ? (
              <DialogUpdatePin />
            ) : ( */}
            <DialogCreatePin
              fromTap={fromTap}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            {/* )} */}
          </TabPanel>
        )}
      </TabView>
    </>
  );
}

export default Security;
