import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { trimValidation } from "../utility/Utils";
import { InputMask } from "primereact/inputmask";
import useAxios from "../../hooks/useAxios";

export default function AdminGlopalInfo() {
  const [isLoading, setIsLoading] = useState(false);
  const { http } = useAxios();

  const defaultValues = {
    supportEmail: null, //required
    legalEmail: null,
    supportUrl: null,
    technicalSupportPhoneNumber: null, //required
    customerAdvocacyPhoneNumber: null,
    legalPhoneNumber: null,
    practiceNoticeEmail: null, //required
    patientNoticeEmail: null, //required
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);

    try {
      await http.put(`/GlobalInfo`, formData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Global information updated successfully",
        life: 2000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.title,
        life: 2000,
      });
    }

    setIsLoading(false);
  };

  const toast = useRef(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/GlobalInfo");
        reset(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div>
            <h4 className="mb-6 text-center text-3xl font-bold sm:text-left">
              Admin Global Information
            </h4>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Support Email <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="supportEmail"
                  control={control}
                  rules={{
                    required: "Support email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                    validate: (value) => trimValidation(value, "Email Address"),
                  }}
                  render={({ field }) => (
                    <>
                      <span className="p-input-icon-left w-6/12">
                        <i className="pi pi-envelope" />
                        <InputText
                          placeholder="support email"
                          className="w-full pb-2 pt-2"
                          {...field}
                        />
                      </span>
                    </>
                  )}
                />
                {errors.supportEmail && (
                  <p className="mt-1 text-red-500">
                    {errors.supportEmail.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Legal Email <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="legalEmail"
                  control={control}
                  rules={{
                    required: "Legal email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <span className="p-input-icon-left w-6/12">
                        <i className="pi pi-envelope" />
                        <InputText
                          placeholder="legal email"
                          className="w-full pb-2 pt-2"
                          {...field}
                          onChange={(e) => {
                            field.onChange(
                              e.target.value == "" ? null : e.target.value,
                            );
                          }}
                        />
                      </span>
                    </>
                  )}
                />
                {errors.legalEmail && (
                  <p className="mt-1 text-red-500">
                    {errors.legalEmail.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Support URL <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="supportUrl"
                  control={control}
                  rules={{
                    required: "Support URL is required",
                    pattern: {
                      value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                      message: "Invalid URL",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-link" />
                      <InputText
                        placeholder="support url"
                        className="w-full pb-2 pt-2"
                        {...field}
                        onChange={(e) => {
                          field.onChange(
                            e.target.value == "" ? null : e.target.value,
                          );
                        }}
                      />
                    </span>
                  )}
                />
                {errors.supportUrl && (
                  <p className="mt-1 text-red-500">
                    {errors.supportUrl.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Technical Support Phone Number{" "}
                  <span className="text-red-600">*</span>
                </label>
                <Controller
                  control={control}
                  name="technicalSupportPhoneNumber"
                  rules={{
                    required: "Technical support phone number is required",
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-phone" />
                      <InputMask
                        mask="999-999-9999"
                        placeholder="support phone number"
                        className="w-full pb-2 pt-2"
                        onClick={(e) => {
                          if (e.target.value === "___-___-____") {
                            e.target.setSelectionRange(0, 0);
                          }
                        }}
                        {...field}
                      />
                    </span>
                  )}
                />
                {errors.technicalSupportPhoneNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.technicalSupportPhoneNumber.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Customer Advocacy Phone Number{" "}
                  <span className="text-red-600">*</span>
                </label>
                <Controller
                  control={control}
                  name="customerAdvocacyPhoneNumber"
                  rules={{
                    required: "Customer advocacy phone number is required",
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-phone" />
                      <InputMask
                        mask="999-999-9999"
                        placeholder="advocacy phone number"
                        className="w-full pb-2 pt-2"
                        {...field}
                        onChange={(e) => {
                          field.onChange(
                            e.target.value == "" ? null : e.target.value,
                          );
                        }}
                        onClick={(e) => {
                          if (e.target.value === "___-___-____") {
                            e.target.setSelectionRange(0, 0);
                          }
                        }}
                      />
                    </span>
                  )}
                />
                {errors.customerAdvocacyPhoneNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.customerAdvocacyPhoneNumber.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Legal Phone Number <span className="text-red-600">*</span>
                </label>
                <Controller
                  control={control}
                  name="legalPhoneNumber"
                  rules={{
                    required: "Legal phone number is required",
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-phone" />
                      <InputMask
                        mask="999-999-9999"
                        placeholder="legal phone number"
                        className="w-full pb-2 pt-2"
                        name="legalPhoneNumber"
                        {...field}
                        onChange={(e) => {
                          field.onChange(
                            e.target.value == "" ? null : e.target.value,
                          );
                        }}
                        onClick={(e) => {
                          if (e.target.value === "___-___-____") {
                            e.target.setSelectionRange(0, 0);
                          }
                        }}
                      />
                    </span>
                  )}
                />
                {errors.legalPhoneNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.legalPhoneNumber.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Practice Notice Email <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="practiceNoticeEmail"
                  control={control}
                  rules={{
                    required: "Practice notice email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-envelope" />
                      <InputText
                        placeholder="practice notice email"
                        className="w-full pb-2 pt-2"
                        name="practiceNoticeEmail"
                        {...field}
                      />
                    </span>
                  )}
                />
                {errors.practiceNoticeEmail && (
                  <p className="mt-1 text-red-500">
                    {errors.practiceNoticeEmail.message}
                  </p>
                )}
              </div>

              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Patient Notice Email <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="patientNoticeEmail"
                  control={control}
                  rules={{
                    required: "Patient notice email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left w-6/12">
                      <i className="pi pi-envelope" />
                      <InputText
                        placeholder="patient notice email"
                        className="w-full pb-2 pt-2"
                        name="patientNoticeEmail"
                        {...field}
                      />
                    </span>
                  )}
                />
                {errors.patientNoticeEmail && (
                  <p className="mt-1 text-red-500">
                    {errors.patientNoticeEmail.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
                <div className="w-full md:w-auto"></div>
                <div className="w-full items-end justify-end md:w-auto">
                  {isLoading ? (
                    <div className="text-center">
                      <ProgressSpinner
                        strokeWidth={3}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
