import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../store";
import { useNavigate } from "react-router";
import useAxios from "../../hooks/useAxios";
import { ProgressSpinner } from "primereact/progressspinner";

function ViewInvitation() {
  const { http } = useAxios();
  const toast = useRef(null);
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const params = new URLSearchParams(window.location.search);
  const paramsUrl = useParams();
  const { entityId, setEntityId } = useStoreEntityIdFromUrl();
  const [invitationId, setInvitationId] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [invitationDate, setInvitationData] = useState();
  const [entityName, setEntityName] = useState();
  const [isAccept, setIsAccept] = useState();
  let status;
  const { admin } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // get in id from url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const systemAdminId = searchParams.get("I");
  // get data from url
  const EntityId = params.get("EId");
  const invId = params.get("InvID");
  const Email = params.get("E");
  const InvDate = params.get("IDate");
  const EName = params.get("EN");
  const Role = params.get("SR");
  const isProvider = params.get("IP");

  const isAdmin = window.location.pathname.includes("admin");
  useEffect(() => {
    setEntityId(EntityId);
    setInvitationId(invId);
    setEmail(Email);
    setInvitationData(InvDate);
    setEntityName(EName);
    setRole(Role);
    if (isAdmin) {
      fetchAdminData();
    }
  }, []);

  const fetchAdminData = async () => {
    try {
      const response = await http.get(
        `/Invitations/GetInvitationData/${systemAdminId}`,
      );
      setEmail(response.data.invitedUserEmail);
    } catch (error) {
      console.error(error);
    }
  };
  const handleInvitationRequest = async () => {
    setIsLoading(true);
    try {
      const endPoint = isAdmin
        ? `Authentication/UpdateSystemAdminInvitationStatus?status=${status}&email=${email}`
        : `/Entities/UpdateInvitationStatus/${entityId}?decodedEntityId=${entityId}&decodedInvitationId=${invitationId}&decodedInvitationDate=${InvDate}&invitationStatus=${status}&decodedEmail=${email}&staffRole=${role}&IsProvider=${isProvider ? isProvider : null}`;
      const response = await axios({
        method: isAdmin ? "put" : "post",
        url: endPoint,
        data: params,
      });

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });

      if (response.data.message === "User Declined Invitation") {
        navigate("/declineInvitation", {
          replace: true,
        });
      } else {
        setToken(response.data.token);
        setRefreshToken(response.data.refreshToken);
        if (response.data.token && response.data.isCompletedRegister === true) {
          if (response.data.isInvitedAsAProvider) {
            navigate("/welcome-provider", {
              replace: "true",
            });
          } else {
            navigate("/Dashboard/", {
              replace: "true",
            });
          }
        } else {
          const currentUrl = response.data;
          const registrationIndex = currentUrl.indexOf("/");
          const trimmedUrl =
            registrationIndex !== -1
              ? currentUrl.slice(registrationIndex)
              : currentUrl;
          navigate(trimmedUrl, {
            replace: "true",
          });
        }
      }
    } catch (error) {
      if (
        error.response.data.message === "You already accepted your invitation"
      ) {
        navigate("/acceptInvitation", {
          replace: true,
        });
      } else if (
        error.response.data.message === "You already declined your invitation"
      ) {
        navigate("/declinedInvitation", {
          replace: true,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex h-screen items-center justify-center">
        <div className="flex w-3/6  justify-center rounded-3xl bg-light-text py-16">
          <div className="2xl:w-5/5 Xl:w-4/5 md:w-5/5 rounded-3xl border  border-solid border-light-purple p-10 py-8 sm:w-4/5 lg:w-4/5">
            <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-300 p-2">
              <MedicalHistoryIcon />
            </span>
            <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
              {isAdmin
                ? "You’re invited to join MedX as a system admin"
                : "You’re invited to join"}
            </h4>
            <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
              {entityName}
            </h4>

            <p className="m-auto mt-4 w-4/5 text-center">
              {isAdmin
                ? "Please take a moment to consider the invitation and choose one of the following options"
                : "Please take a moment to consider the invitation and choose one of the following options"}
            </p>
            {!isLoading ? (
              <div className="flex justify-center gap-5">
                <div className="flex items-end justify-end">
                  <Button
                    onClick={() => {
                      status = 2;
                      handleInvitationRequest();
                    }}
                    type="submit"
                    loading={isLoading}
                    className="mt-9 justify-center rounded-full  bg-light-purple px-10 py-2.5 font-inter font-medium capitalize text-light-text "
                  >
                    Accept
                  </Button>
                </div>
                <div className="">
                  <Button
                    onClick={() => {
                      status = 4;
                      handleInvitationRequest();
                    }}
                    type="submit"
                    disabled={isLoading}
                    className="mt-9 justify-center rounded-full  bg-gray-200 px-10 py-2.5 font-inter font-medium capitalize text-light-purple"
                  >
                    Decline
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-center">
                <ProgressSpinner className="h-[81.6px] w-10" strokeWidth={2} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewInvitation;
