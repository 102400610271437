import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useRefreshTokenStore,
  useRegisterFormStore,
  useTokenStore,
} from "../../store";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import axios from "axios";
import { Toast } from "primereact/toast";
import { addYears } from "date-fns";
import { ProgressSpinner } from "primereact/progressspinner";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import useAxios from "../../hooks/useAxios";
import { MicrosoftLogin } from "react-microsoft-login";
import { validatePassword } from "../utility/Utils";

const genderOptions = [
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
];

function AdminRegistration() {
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailDisabled, setIsEmailDisabled] = useState(true);
  const params = new URLSearchParams(window.location.search);
  // get in id from url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const systemAdminId = searchParams.get("I");

  const fetchAdminData = async () => {
    try {
      const response = await http.get(
        `/Invitations/GetInvitationData/${systemAdminId}`,
      );
      setEmail(response.data.invitedUserEmail);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (systemAdminId) {
      fetchAdminData();
    }
  }, [systemAdminId]);

  useEffect(() => {
    if (email) {
      reset({ ...defaultValues, email });
      setIsEmailDisabled(true);
    } else {
      setEmail("");
      setIsEmailDisabled(false);
    }
  }, [email]);

  // declaration all const
  const toast = useRef(null);
  const navigate = useNavigate();
  const setFormData = useRegisterFormStore((state) => state.setFormData);
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  // create validation into names

  const validateName = (value) => {
    const regex = /^[A-Za-z]+$/;
    if (value.includes(" ")) {
      return "Name should not contain spaces";
    }
    if (!value.match(regex)) {
      return "Name should only contain letters";
    }
    return true;
  };

  const handleCreate = async (formData) => {
    setIsLoading(true);
    try {
      await http
        .post("/Authentication/RegisterSystemAdminAsync", formData)
        .then((response) => {
          setFormData(response.data);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "register Successfully",
            life: 4000,
          });
          if (isEmailDisabled) {
            reset(defaultValues);
            navigate("/admin/login");
          }
        });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const onSubmit = async (formData) => {
    handleCreate(formData);
  };
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const handleConfirmPassword = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };
  const password = watch("password");

  return (
    <>
      <Toast ref={toast} />
      <div className="flex items-center justify-center">
        <div className="xsm:w-9/12 mt-10 rounded-3xl bg-light-text py-8 pl-10 pr-10  shadow shadow-slate-300 sm:w-9/12 md:w-6/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12 ">
          <h4 className="mb-5 text-center font-philosopher text-title font-extrabold text-dark-purple">
            {" "}
            Sign Up to MedX{" "}
          </h4>

          <div className="form-container mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container mb-3">
                <label>
                  First Name <span className="text-red-600">*</span>
                </label>
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-user" />
                  <InputText
                    name="firstName"
                    placeholder="First Name"
                    className="w-full pb-2 pt-2"
                    {...register("firstName", {
                      required: "First Name is required",
                      validate: validateName,
                    })}
                  />
                </span>
                {errors.firstName && (
                  <p className="mt-1 text-red-500">
                    {errors.firstName.message}
                  </p>
                )}

                <div className="pt-4">
                  <label>
                    Last Name <span className="text-red-600">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-user" />
                    <InputText
                      name="lastName"
                      placeholder="Last Name"
                      className="w-full pb-2 pt-2"
                      {...register("lastName", {
                        required: "Last Name is required",
                        validate: validateName,
                      })}
                    />
                  </span>
                  {errors.lastName && (
                    <p className="mt-1 text-red-500">
                      {errors.lastName.message}
                    </p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Email <span className="text-red-600">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-envelope" />
                    <InputText
                      disabled={isEmailDisabled}
                      placeholder="Email Address"
                      name="email"
                      {...register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                      className="w-full pb-2 pt-2"
                    />
                  </span>
                  {errors.email && (
                    <p className="mt-1 text-red-500">{errors.email.message}</p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Password <span className="text-red-600">*</span>
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required",
                      validate: validatePassword,
                    }}
                    render={({ field }) => (
                      <>
                        <span className="p-input-icon-left p-input-icon-right w-full">
                          <i className="pi pi-lock" />
                          <InputText
                            placeholder="Password (8 or more characters)"
                            className="w-full pb-2 pt-2"
                            name="password"
                            type={!passwordEye ? "password" : "text"}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              trigger("confirmPassword");
                            }}
                          />
                          {!passwordEye ? (
                            <i
                              className="pi pi-eye-slash"
                              onClick={handlePasswordClick}
                            />
                          ) : (
                            <i
                              className="pi pi-eye"
                              onClick={handlePasswordClick}
                            />
                          )}
                        </span>
                      </>
                    )}
                  />
                  {errors.password && (
                    <p className="mt-1 text-red-500">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="pt-4">
                  <label>
                    Confirm Password <span className="text-red-600">*</span>
                  </label>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Confirm Password is required",
                      validate: (value, formValues) => {
                        return value === formValues.password
                          ? true
                          : "Passwords do not match";
                      },
                    }}
                    render={({ field }) => (
                      <span className="p-input-icon-left p-input-icon-right w-full">
                        <i className="pi pi-lock" />
                        <InputText
                          placeholder="Confirm password"
                          className="w-full pb-2 pt-2"
                          name="confirmPassword"
                          type={!confirmPasswordEye ? "password" : "text"}
                          {...field}
                        />
                        {!confirmPasswordEye ? (
                          <i
                            className="pi pi-eye-slash"
                            onClick={handleConfirmPassword}
                          />
                        ) : (
                          <i
                            className="pi pi-eye"
                            onClick={handleConfirmPassword}
                          />
                        )}
                      </span>
                    )}
                  />
                  {errors.confirmPassword && (
                    <p className="mt-1 text-red-500">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text "
                >
                  create my account{" "}
                </Button>
              )}
            </form>
          </div>

          <h5 className="text-center">
            Already have an account ?{" "}
            <Button className="p-0" link>
              {" "}
              <Link to={"/admin/login"}>Login </Link>{" "}
            </Button>
          </h5>
        </div>
      </div>
    </>
  );
}

export default AdminRegistration;
