import { InputText } from "primereact/inputtext";
import React, { useState, useRef, useEffect } from "react";

function OTPInput({ length, onComplete, onChange, reset, setReset }) {
  const [otp, setOTP] = useState(new Array(length).fill(""));
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (reset) {
      setOTP(new Array(length).fill(""));
      setReset(false);
      setIsDisabled(false);
      inputRefs.current[0].disabled = false;
      inputRefs.current[0].focus();
    }
  }, [reset]);

  const handleChange = (index, event) => {
    setOTP((prev) => {
      const newOTP = [...prev];
      newOTP[index] = event.target.value;
      if (newOTP.every((code) => code !== "")) {
        setIsDisabled(true);
        onComplete(newOTP.join(""));
      }
      return newOTP;
    });

    if (event.target.value !== "" && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (
      event.key === "Backspace" &&
      otp[index] === "" &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text");

    const pastedChars = pastedData.split("");

    pastedChars.forEach((char, index) => {
      if (inputRefs.current[index] && index < length) {
        inputRefs.current[index].value = char;
        handleChange(index, { target: { value: char } });
      }
    });
  };

  return (
    <div className="flex items-center gap-2">
      {otp.map((value, index) => (
        <InputText
          key={index}
          maxLength={1}
          value={value}
          disabled={isDisabled}
          keyfilter={/^\d+$/}
          className="h-8 w-8 text-center sm:h-12 sm:w-12"
          onChange={(e) => {
            handleChange(index, e);
            onChange && onChange(index, e);
          }}
          onPaste={handlePaste}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
}

export default OTPInput;
