import React, { useEffect, useRef, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Column } from "primereact/column";
import ExpandedRow from "./ExpandedRow";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  blockSpecialCharsPattern,
  formatDate,
  formatDateTime,
} from "../utility/Utils";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import ReusableCardList from "../ReusableCardList/ReusableCardList";
import ReusableFilter from "../ReusableFilter/ReusableFilter";
import useHandleResize from "../../hooks/useHandleResize";
export default function TransactionTracking() {
  const searchByOptions = [
    { name: "Order Number", value: 0 },
    { name: "Transaction Number", value: 1 },
  ];

  const { http } = useAxios();
  const [orderedByOptions, setOrderedByOptions] = useState([]);
  const [offeredByOptions, setOfferedByOptions] = useState([]);
  const [searchBy, setSearchBy] = useState(searchByOptions[0]);
  const [offeredBy, setOfferedBy] = useState({});
  const [orderedBy, setOrderedBy] = useState({});
  const [transactionStatus, setTransactionStatus] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  const [transactionNumberSearch, setTransactionNumberSearch] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [orders, setOrders] = useState();
  const [isLoading, setIsLoading] = useState();
  const [first, setFirst] = useState(0);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [numberOfRows, setNumberOfRows] = useState(5);
  const [currentPage, setCurrentPage] = useState();
  const [expandedContents, setExpandedContents] = useState({});
  const expandedContentsRef = useRef(expandedContents);
  const [totalRecords, setTotalRecords] = useState();
  const [value, setValue] = useState(null);
  const [openCard, setOpenCard] = useState({ id: null, show: false });
  const { isMobile } = useHandleResize();

  const handleOpenCalendar = () => {
    // Open or close the calendar when the button is clicked
    setCalendarVisible(!calendarVisible);
  };
  let clearClicked = false;
  const handleClear = async () => {
    clearClicked = true;
    setFirst(0);
    setOrderedBy({});
    setOfferedBy({});
    setSearchOrder("");
    setTransactionNumberSearch("");
    setTransactionStatus({});
    fetchAllOrders();
    fetchAllPractices();
    setStartDay("");
    setEndDay("");
    setValue(null);
  };

  const applyFilters = async () => {
    setFirst(0);
    setIsLoading(true);
    clearClicked = false;
    const searchOrderValue = searchOrder.trim() !== "" ? searchOrder : "";
    const transactionNumberSearchValue =
      transactionNumberSearch.trim() !== "" ? transactionNumberSearch : "";
    setSearchOrder(searchOrderValue);
    setTransactionNumberSearch(transactionNumberSearchValue);
    fetchAllOrders();
  };
  const handleToggle = (e) => {
    setExpandedRows(e.data);
  };
  const onRowExpand = async (rowId) => {
    try {
      const response = await http.get(
        `/Payments/GetOrderTransaction?orderId=${rowId}`,
      );
      // take this array of data to expanded table
      // this object of objects and the key is Id of rowId
      expandedContentsRef.current = {
        ...expandedContentsRef.current,
        [rowId]: response.data,
      };
      // this object contains that key up array of data "expanded table"
      setExpandedContents(expandedContentsRef.current);
    } catch (e) {
      console.error(e);
    }
  };

  const calendarChange = (e) => {
    if (e[0]) {
      const startDay = e[0];
      const endDay = e[1] || new Date();
      // British English Formate
      const formattedStartDate =
        startDay
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-") + // Reverse only month and day
        "T" +
        startDay.toTimeString().slice(0, 8);
      const formattedEndDate =
        endDay
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-") + "T23:59:59";

      setStartDay(formattedStartDate);
      setEndDay(formattedEndDate);
    }
  };

  const allStatus = [
    { name: "Unpaid", id: 1 },
    { name: "Expired", id: 2 },
    { name: "Hold", id: 3 },
    { name: "Captured", id: 4 },
    { name: "PendingCancellation", id: 5 },
    { name: "Canceled", id: 6 },
    { name: "PendingRefund", id: 7 },
    { name: "Refunded", id: 8 },
    { name: "Claim", id: 9 },
    { name: "Completed", id: 10 },
    { name: "Failed", id: 11 },
  ];

  const fetchAllOrders = async (page, signal) => {
    setIsLoading(true);
    try {
      // Constructing the endpoint URL with filters
      const endPointURL =
        clearClicked === false
          ? `/Payments/GetAllOrdersTransactions?Filters=${
              searchOrder !== "" ? `OrderTrackingNumber@=*${searchOrder}` : ""
            }${
              transactionNumberSearch &&
              `,PaymentProcessing.StripeTransactions.PaymentIntentId@=*${transactionNumberSearch}`
            }${
              Object.keys(orderedBy).length !== 0
                ? `,Entity.Name==*${orderedBy.name}`
                : ""
            } ${
              Object.keys(offeredBy).length !== 0
                ? `,Service.Entity.Name==*${offeredBy.name}`
                : ""
            }${
              Object.keys(transactionStatus).length !== 0
                ? `,PaymentProcessing.Status==${transactionStatus.id}`
                : ""
            }${
              startDay &&
              endDay &&
              `,orderedDate>=${startDay},orderedDate<=${endDay}`
            }&Page=${page || 1}&PageSize=5`
          : `/Payments/GetAllOrdersTransactions?Page=${page || 1}&PageSize=5`;

      const response = await http.get(endPointURL, { signal: signal });
      setTotalRecords(response?.data[0]?.count);
      setOrders(() => {
        const newData = response.data.map((item) => ({
          ...item,
          orderedDate: item.orderedDate,
        }));
        return [...newData];
      });
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const fetchAllPractices = async () => {
    const allPractices = await http.get("/Entities/GetAllPracticesName");
    setOrderedByOptions(allPractices.data);
    setOfferedByOptions(allPractices.data);
  };
  useEffect(() => {
    fetchAllPractices();
  }, []);

  const handleOrderedByChange = async (e) => {
    setOrderedBy(() => e);
  };

  const handleOfferedByChange = async (e) => {
    setOfferedBy(() => e);
  };

  const header = (
    <div className="flex-col">
      <div className="mb-4 flex justify-between">
        <h1 className="text-xl">Advanced Filters</h1>
        <div className="flex flex-col items-center gap-2">
          <Button
            label={
              calendarVisible ? "Close Calendar Filter" : "Filter by Date Range"
            }
            outlined
            onClick={handleOpenCalendar}
            className="relative"
          />
          {value && (
            <p className="flex items-center gap-2 font-inter">
              {value[0] && <span>{formatDate(value[0])}</span>}

              {value[1] && (
                <>
                  <span className="font-normal">to</span>
                  <span>{formatDate(value[1])}</span>
                </>
              )}
              <i
                className="pi pi-times cursor-pointer rounded-full border-[1px] border-red-600 p-1 text-red-600"
                onClick={() => {
                  setValue(null);
                  setEndDay("");
                  setStartDay("");
                }}
              ></i>
            </p>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="mb-4 flex justify-between">
          <span className="p-input-icon-left mr-5 w-full">
            <i className="pi pi-search " />
            <InputText
              onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              keyfilter="int"
              placeholder="Search by Order Number"
              value={searchOrder}
              onChange={(e) => {
                setSearchOrder(e.target.value);
              }}
              className="w-full"
            />
          </span>
        </div>
        <div className="mb-4 flex w-[20rem] justify-between">
          <span className="p-input-icon-left mr-8 w-full sm:mr-0">
            <i className="pi pi-search " />
            <InputText
              keyfilter={blockSpecialCharsPattern}
              onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              placeholder="Search by Transaction Number"
              value={transactionNumberSearch}
              onChange={(e) => {
                setTransactionNumberSearch(e.target.value);
              }}
              className="w-full"
            />
          </span>
        </div>
      </div>
      <div className="gap-5 md:flex-col lg:flex lg:flex-row lg:flex-wrap">
        <div className="min-[320px]:felx-col gap-5 md:mb-5 md:flex lg:flex">
          <div className="min-[320px]:mb-5 md:mb-0 md:w-[15rem] lg:w-52">
            <Dropdown
              value={orderedBy}
              onChange={(e) => handleOrderedByChange(e.value)}
              options={orderedByOptions}
              optionLabel="name"
              placeholder="Ordered By"
              className="h-12 w-full items-center"
            />
          </div>

          <div className="min-[320px]:mb-5 md:mb-0 md:w-[15rem] lg:w-52">
            <Dropdown
              value={offeredBy}
              onChange={(e) => handleOfferedByChange(e.value)}
              options={offeredByOptions}
              optionLabel="name"
              placeholder="Offered By"
              className="h-12 w-full items-center"
            />
          </div>

          <div className="min-[320px]:mb-5 md:mb-0 md:w-[15rem] lg:w-52">
            <Dropdown
              value={transactionStatus}
              onChange={(e) => setTransactionStatus(e.value)}
              options={allStatus}
              optionLabel="name"
              placeholder="Status"
              className="h-12 w-full items-center"
            />
          </div>
        </div>
        <div className="flex gap-5">
          <Button
            label="Apply Filters"
            raised
            onClick={applyFilters}
            className="h-12 w-full bg-light-purple lg:w-[12rem]"
          />
          <Button
            label="Clear All"
            className="h-12 w-full lg:w-[12rem]"
            outlined
            onClick={handleClear}
          />
        </div>
      </div>
    </div>
  );

  //if place you don't to put the expand icon
  const allowExpansion = () => {
    return true;
  };
  // content of expanded row
  const rowExpansionTemplate = (expandedContent) => {
    return <ExpandedRow data={expandedContent} />;
  };

  //Status Template
  const statusTemplate = (rowData) => {
    const statusStyles = {
      1: { text: "Unpaid", bgColor: "bg-red-500", textColor: "text-white" },
      2: { text: "Expired", bgColor: "bg-gray-500", textColor: "text-white" },
      3: { text: "Hold", bgColor: "bg-amber-300	", textColor: "text-gray-900" },
      4: { text: "Captured", bgColor: "bg-green-500", textColor: "text-white" },
      5: {
        text: "Pending Cancellation",
        bgColor: "bg-orange-500",
        textColor: "text-white",
      },
      6: { text: "Cancelled", bgColor: "bg-red-700", textColor: "text-white" },
      7: {
        text: "Pending Refund",
        bgColor: "bg-yellow-700",
        textColor: "text-gray-900",
      },
      8: { text: "Refunded", bgColor: "bg-green-700", textColor: "text-white" },
      9: { text: "Claim", bgColor: "bg-purple-500", textColor: "text-white" },
      10: {
        text: "Completed",
        bgColor: "bg-blue-500",
        textColor: "text-white",
      },
      11: {
        text: "Failed",
        bgColor: "bg-red-900",
        textColor: "text-white",
      },
    };

    const status = statusStyles[rowData.status];

    return (
      <span
        className={`inline rounded-md p-2 text-center text-sm font-semibold sm:block sm:p-3 sm:text-base ${status.bgColor} ${status.textColor}`}
      >
        {status.text}
      </span>
    );
  };

  useEffect(() => {
    fetchAllOrders(currentPage);
  }, [currentPage, numberOfRows]);

  const dateTemplate = (rowData) => {
    return (
      <>
        <div className="flex flex-col ">
          <p className="text-center">
            {rowData.orderedDate && formatDate(rowData.orderedDate)}
          </p>
          <p className="mt-1 rounded-md bg-sky-600 text-center font-inter text-sm text-white">
            {rowData.orderedDate && formatDateTime(rowData.orderedDate)}
          </p>
        </div>
      </>
    );
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <span>#{row.orderTrackingNumber}</span>
          <span className="text-sm">
            {formatDateTime(row.orderedDate)}
            &nbsp;{formatDate(row.orderedDate)}
          </span>
        </div>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="text-slate-500">&nbsp;Ordered By:&nbsp;</span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="text-slate-500">&nbsp;Offered By:&nbsp;</span>
          {row.offeredBy}
        </p>
        <hr />
        <div className="flex items-center justify-between gap-2 font-semibold">
          <span className="text-slate-500">Order Total Change:&nbsp;</span>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <div className="flex items-center justify-between gap-2 font-semibold">
          <span className="text-slate-500">Status:&nbsp;</span>
          {statusTemplate(row)}
        </div>
        <hr
          className={`${(openCard.id === row.id && openCard.show) || "mb-8"}`}
        />
        {openCard.id === row.id && expandedContents.hasOwnProperty(row.id) && (
          <div
            className={`flex flex-col items-center gap-2 p-2 ${openCard && "mb-8"}`}
          >
            <h4 className="text-center">Ordered Detailed Transactions</h4>
            {expandedContents[row.id].map((row, index) => (
              <div key={index} className="space-y-2">
                <div className="flex justify-between gap-2 rounded-md bg-purple-700 p-2 text-xs text-white">
                  <span>Transaction Date</span>
                  <span>
                    {formatDateTime(row.createdAt)}
                    &nbsp;{formatDate(row.createdAt)}
                  </span>
                </div>
                {row.transactionId && (
                  <p className="font-semibold">#{row.transactionId}</p>
                )}
                <hr />
                <div className="flex items-center justify-between gap-2 font-semibold">
                  <span className="text-slate-500">
                    Transaction Method:&nbsp;
                  </span>
                  <span>{row.type}</span>
                </div>
                <p className="font-semibold">
                  <span className="text-slate-500">Notes:&nbsp;</span>
                  {row.notes}
                </p>
              </div>
            ))}
          </div>
        )}

        <i
          onClick={() => {
            onRowExpand(row.id);
            setOpenCard({ id: row.id, show: true });
          }}
          style={{ color: "white" }}
          className={`pi pi-angle-down ${openCard.id === row.id && openCard.show ? "hidden" : "block"} absolute bottom-0 left-0 flex w-full cursor-pointer justify-center rounded-b-lg bg-purple-700 py-3`}
        />
        <i
          onClick={() => setOpenCard({ id: null, show: false })}
          style={{ color: "white" }}
          className={`pi pi-angle-up ${openCard.id === row.id && openCard.show ? "block" : "hidden"} absolute bottom-0 left-0 flex w-full cursor-pointer justify-center rounded-b-lg bg-purple-700 py-3`}
        />
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div className="mb-20 flex flex-col gap-4 overflow-y-auto">
        <div>
          <h3 className="text-md mb-3 text-center font-bold">Search By</h3>
          <div className="flex flex-col gap-2">
            {searchByOptions.map((option) => {
              return (
                <div key={option.name} className="flex items-center">
                  <RadioButton
                    inputId={option.name}
                    name="name"
                    value={option.value}
                    onChange={(e) => {
                      if (e.value === 0) {
                        setSearchOrder(transactionNumberSearch);
                        setTransactionNumberSearch("");
                      } else {
                        setTransactionNumberSearch(searchOrder);
                        setSearchOrder("");
                      }
                      setSearchBy(e.value);
                    }}
                    checked={searchBy === option.value}
                  />
                  <label htmlFor={option.name} className="ml-2 capitalize">
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="text-md mb-3 text-center font-bold">Ordered By</h3>
          <div className="flex flex-col gap-2">
            {orderedByOptions.map((option) => {
              return (
                <div key={option.name} className="flex items-center">
                  <RadioButton
                    inputId={option.name}
                    name="name"
                    value={option}
                    onChange={(e) => handleOrderedByChange(e.value)}
                    checked={orderedBy.id === option.id}
                  />
                  <label htmlFor={option.name} className="ml-2 capitalize">
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h3 className="text-md mb-3 text-center font-bold">Offered By</h3>
          <div className="flex flex-col gap-2">
            {offeredByOptions.map((option) => {
              return (
                <div key={option.name} className="align-items-center flex">
                  <RadioButton
                    inputId={option.name}
                    name="name"
                    value={option}
                    onChange={(e) => handleOfferedByChange(e.value)}
                    checked={offeredBy.id === option.id}
                  />
                  <label htmlFor={option.name} className="ml-2 capitalize">
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h3 className="text-md mb-3 text-center font-bold">Status</h3>
          <div className="flex flex-col gap-2">
            {allStatus.map((option) => {
              return (
                <div key={option.name} className="align-items-center flex">
                  <RadioButton
                    inputId={option.id}
                    name="name"
                    value={option}
                    onChange={(e) => setTransactionStatus(e.value)}
                    checked={transactionStatus.id === option.id}
                  />
                  <label htmlFor={option.name} className="ml-2 capitalize">
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <Button
          outlined
          onClick={handleClear}
          className="flex justify-center p-6"
        >
          Clear
        </Button>
      </div>
    );
  };

  const handleSearchByTransactionNumber = (event) =>
    setTransactionNumberSearch(event.target.value);
  const handleSearchByOrderNumber = (event) =>
    setSearchOrder(event.target.value);

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-[500px]"
        visible={calendarVisible}
        onHide={() => setCalendarVisible(false)}
      >
        <Calendar
          className="w-full"
          value={value}
          onChange={(e) => {
            setValue(e.value);

            calendarChange(e.value);
          }}
          selectionMode="range"
          inline
        />
        <div className="mt-2 text-end">
          <Button
            onClick={() => {
              setCalendarVisible(false);
              applyFilters();
            }}
            label="Filter"
            className="mt-3"
          ></Button>
        </div>
      </Dialog>
      {isMobile ? (
        <>
          <ReusableFilter
            filterTemplate={filterTemplate}
            applyFilter={applyFilters}
            searchValue={searchBy === 0 ? searchOrder : transactionNumberSearch}
            sendSearchData={
              searchBy === 0
                ? handleSearchByOrderNumber
                : handleSearchByTransactionNumber
            }
            onSearch={[applyFilters]}
          />
          <ReusableCardList
            tableData={orders}
            actionTemplates={[{ template: cardTemplate }]}
          />
          {totalRecords > 0 && (
            <Paginator
              first={first}
              rows={numberOfRows}
              totalRecords={totalRecords}
              onPageChange={(e) => {
                if (e.first === first) return;
                setFirst(e.first);
                setNumberOfRows(e.rows);
                setCurrentPage(e.page + 1);
              }}
              template={{
                layout: "PrevPageLink CurrentPageReport NextPageLink",
              }}
              className="mt-4"
            />
          )}
        </>
      ) : (
        <Card>
          <DataTable
            value={orders}
            header={header}
            expandedRows={expandedRows}
            onRowToggle={(e) => handleToggle(e)}
            onRowExpand={(e) => onRowExpand(e.data.id)}
            rowExpansionTemplate={
              (rowData) => rowExpansionTemplate(expandedContents[rowData.id]) //here you try to access object you want when clicked in expand icon
            }
            showGridlines
            pt={{
              loadingOverlay: {
                style: {
                  backgroundColor: "#C6C1FF40",
                },
              },
            }}
            loading={isLoading}
          >
            <Column expander={allowExpansion} style={{ width: "5rem" }} />

            <Column
              field="orderTrackingNumber"
              header="Order Number"
              style={{ textAlign: "center" }}
            />

            <Column field="orderedBy" header="Ordered By" />
            <Column field="offeredBy" header="Offered By" />
            <Column
              field="charge"
              header="Order Total Charged"
              style={{ textAlign: "center" }}
              body={(rowData) => {
                return <div>${rowData.charge.toFixed(2)}</div>;
              }}
            />
            <Column
              field="serviceCharg"
              header="Service Charge"
              style={{ textAlign: "center" }}
              body={(rowData) => {
                return <div>${rowData.serviceCharg.toFixed(2)}</div>;
              }}
            />
            <Column
              field="fees"
              header="Service Fees"
              style={{ textAlign: "center" }}
              body={(rowData) => {
                return (
                  <div>
                    ${(rowData.charge - rowData.serviceCharg).toFixed(2)}
                  </div>
                );
              }}
            />
            <Column
              field="transactionId"
              header="Transaction Number"
              style={{ textAlign: "center" }}
            />
            <Column field="status" header="Status" body={statusTemplate} />
            <Column
              field="orderedDate"
              header="Order Date"
              body={dateTemplate}
            />
          </DataTable>
          {totalRecords > 0 && (
            <Paginator
              first={first}
              rows={numberOfRows}
              totalRecords={totalRecords}
              onPageChange={(e) => {
                if (e.first === first) return;
                setFirst(e.first);
                setNumberOfRows(e.rows);
                setCurrentPage(e.page + 1);
              }}
            />
          )}
        </Card>
      )}
    </>
  );
}
