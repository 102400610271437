import React, { useState } from "react";

import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";

const ReusableFilter = ({
  globalFilterValue,
  onGlobalFilterChange,
  fromFront = false,
  searchTitle,
  searchValue,
  sendSearchData,
  filterTemplate,
  addNew,
  onSearch,
  keyFilter,
  applyFilter,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="flex w-full gap-1 py-5 sm:gap-3">
        <span className="p-input-icon-left flex-1 sm:col-span-5">
          <i
            className="pi pi-search"
            style={{ fontSize: "1.25rem", color: "rgb(126 34 206)" }}
          />
          <InputText
            keyfilter={keyFilter}
            onKeyDown={(e) =>
              e.key === "Enter" && onSearch.forEach((search) => search())
            }
            placeholder={searchTitle || "Search"}
            value={fromFront ? globalFilterValue : searchValue}
            onChange={fromFront ? onGlobalFilterChange : sendSearchData}
            className="w-full placeholder:text-sm"
          />
        </span>

        {filterTemplate && (
          <div className="flex justify-end sm:hidden">
            <Button
              icon="pi pi-filter"
              onClick={() => setVisible(true)}
              className="w-full"
            />
          </div>
        )}
        {addNew && (
          <Button
            className="flex h-12 w-12 gap-2 p-4 font-inter sm:h-auto sm:w-auto"
            onClick={addNew}
          >
            <i className="pi pi-plus" />
          </Button>
        )}
        <div className="hidden sm:flex">
          <Button
            onClick={() => onSearch.forEach((search) => search())}
            label="Search"
            className="w-full"
          />
        </div>
      </div>

      <Sidebar
        visible={visible}
        position="bottom"
        onHide={() => setVisible(false)}
        className="filter-sidebar flex sm:hidden"
        pt={{
          content: { className: "flex flex-col gap-2 overflow-hidden" },
        }}
      >
        <div>
          <h2 className="text-center text-lg font-bold">Filter</h2>
          <hr />
        </div>

        {filterTemplate && filterTemplate()}

        <div className="absolute bottom-0 left-0 z-10 flex w-full justify-center gap-2 bg-light-text p-4">
          <Button
            onClick={() => {
              applyFilter && applyFilter();
              setVisible(false);
            }}
            label="Apply"
            className="flex-1"
          />
        </div>
      </Sidebar>
    </>
  );
};

export default ReusableFilter;
