import React from "react";
import { Navigate } from "react-router-dom";
import AdminLogin from "./signUp/AdminLogin";
import useTokenData from "../hooks/useTokenData";
import { useExpireSystemAdmin } from "../store";

export const AdminLoginAuthGaurd = () => {
  const { userId } = useTokenData();
  if (userId) {
    return <Navigate to={"/admin/ourPractices"} replace />;
  }
  return <AdminLogin />;
};
