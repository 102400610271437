import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";

import ServicesCard from "./ServicesCard";
import useAxios from "../../hooks/useAxios";
import { formatDate } from "../utility/Utils";
import useTokenData from "../../hooks/useTokenData";
import ServicesIcon from "../../iconComponents/ServicesIcon";
import LocationCard from "../rendererProviderLocation/LocationCard";
import { LocationCardSkeleton } from "../skeletons/LocationCardSkeleton";
import { ServicesCardSkeleton } from "../skeletons/ServicesCardSkeleton";
import { FavoritePracticesCardSkeleton } from "../skeletons/FavoritePracticesCardSkeleton";

function EntityPage() {
  const toast = useRef(null);
  const { practiceId } = useParams();
  const { http } = useAxios();
  const [locations, setLocations] = useState();
  const [services, setServices] = useState();
  const [practiceData, setPracticeData] = useState();
  const { entityId } = useTokenData();
  const [practiseImageSrc, setPractiseImageSrc] = useState();
  const getEntityData = async () => {
    const res = await http.get(`/Entities/GetPracticeProfile/${practiceId}`);
    setLocations(res.data.practiceLocations);
    setPractiseImageSrc(res.data.practiceImage);
    setPracticeData(res.data);
    setServices(res.data.practiceServices);
  };
  const favouritePracticeEnable = async () => {
    try {
      await http.post("/Entities/AddFavouritePractice", {
        entityId: entityId,
        favouritePracticeId: practiceId,
      });
      getEntityData();

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Pracitce added to your favorite list successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 2000,
      });
    }
  };
  const favouritePracticeDisable = async () => {
    try {
      await http.delete(
        `/Entities/RemoveFavouritePractice/${entityId}/${practiceId}`,
      );
      getEntityData();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Pracitce Removed From Your Favorite List Successfully",
        life: 2000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 2000,
      });
    }
  };
  useEffect(() => {
    getEntityData();
  }, []);

  const formattedDate = formatDate(practiceData?.memberSince);

  return (
    <>
      <Toast ref={toast} />

      {practiceData ? (
        <div className="mb-4 bg-white p-3">
          <div className="flex justify-between">
            <div className="mb-4 flex items-center">
              <div>
                {" "}
                {practiseImageSrc ? (
                  <img
                    className="mr-2 h-[30px] w-[30px] rounded-full"
                    alt="practiseImage"
                    src={practiseImageSrc}
                  />
                ) : (
                  <Avatar
                    label={practiceData?.practiceName[0]}
                    shape="circle"
                    className="mr-2 bg-blue-500 text-white"
                  />
                )}
              </div>
              <div>
                <div>{practiceData?.practiceName}</div>
                <div className="text-sm font-light">
                  Member Since {formattedDate}
                </div>
              </div>
            </div>
            {entityId !== practiceId && (
              <div>
                {practiceData?.isFavouritePractice ? (
                  <i
                    onClick={() => {
                      favouritePracticeDisable();
                    }}
                    className="pi pi-star-fill cursor-pointer text-purple-700"
                  />
                ) : (
                  <i
                    onClick={() => {
                      favouritePracticeEnable();
                    }}
                    className="pi pi-star cursor-pointer text-purple-700"
                  />
                )}
              </div>
            )}
          </div>
          <div className="mb-2 flex items-center">
            <ServicesIcon />
            {practiceData?.numbersOfServices}
            {practiceData?.numbersOfServices > 1 ? " Services" : " Service"}
          </div>
        </div>
      ) : (
        <FavoritePracticesCardSkeleton />
      )}

      <div>
        <div className="bg-white p-5">
          <div className="mb-4 text-lg font-bold ">Locations</div>
          {locations ? (
            locations?.map((location, i) => (
              <div key={i} className="border-rounded mb-4 border">
                <LocationCard entityPage={true} location={location} />
              </div>
            ))
          ) : (
            <LocationCardSkeleton />
          )}
        </div>
        <div className="bg-white p-5">
          <div className="mb-4 text-lg font-bold ">Services</div>
          {services
            ? services?.map((service) => {
                return (
                  <div key={service.id} className="border-rounded mb-4 border">
                    <ServicesCard
                      serviceName={service.name}
                      serviceDescriprtion={service.description}
                      entityName={practiceData?.practiceName}
                      minCost={service.minFees}
                      maxCost={service.maxFees}
                      savedService={service.isSavedService}
                      serviceId={service.id}
                      entityId={service.entityId}
                      fetchDataFunction={getEntityData}
                      isEntityPage={true}
                      imageSrc={practiseImageSrc}
                    />
                  </div>
                );
              })
            : Array(8)
                .fill(0)
                .map((n, i) => (
                  <div key={i} className="border-rounded mb-2 border">
                    <ServicesCardSkeleton />
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}

export default EntityPage;
