import React from "react";

import { useLocation } from "react-router-dom";

import { TabPanel, TabView } from "primereact/tabview";

import PendingOrders from "./PendingOrders";
import ArchivedOrders from "./ArchivedOrders";
import FinalizedOrders from "./FinalizedOrders";
import { InboundOrders } from "./InboundOrders";

function OrderingProvider() {
  const location = useLocation();
  const activeIndex = location.state?.activeIndex;

  return (
    <>
      <div>
        <TabView activeIndex={activeIndex || 0}>
          <TabPanel
            header="Inbound"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <InboundOrders />
          </TabPanel>
          <TabPanel
            header="Pending"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <PendingOrders />
          </TabPanel>
          <TabPanel
            header="Finalized"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <FinalizedOrders />
          </TabPanel>
          <TabPanel
            header="Archived"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <ArchivedOrders />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default OrderingProvider;
