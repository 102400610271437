import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

function LoadingPage() {
  return (
    <div className="spinner-container">
      <ProgressSpinner
        strokeWidth={3}
        style={{ width: "30px", height: "30px" }}
      />
    </div>
  );
}

export default LoadingPage;
