"use client";

console.warn(
  "⚠️ Avoid importing components from 'index.js' for better performance. This practice is deprecated and may be removed in the future."
);

export * as _Builtin from "./_Builtin";
export * from "./devlinkContext";
export * from "./interactions";
export * from "./utils";
export * from "./devlink";
export * from "./Contactus";
export * from "./DevLinkProvider";
export * from "./Explor";
export * from "./Features";
export * from "./Hero";
export * from "./Nav";
