import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SuccessIcon from "../../iconComponents/SuccessIcon";
import PaymentSuccess from "../PaymentSuccess";
import OrderSuceessSkeleton from "../skeletons/OrderSuceessSkeleton";
import useAxios from "../../hooks/useAxios";

export default function EmbeddedStripeReturn() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const paymentLinkId = queryParams.get("id");
  const [loading, setLoading] = useState(true);
  const [receiptData, setReceiptData] = useState(true);
  const { http } = useAxios();

  useEffect(() => {
    if (status == "complete") {
      fetchReceipt(paymentLinkId);
    }
  }, [paymentLinkId]);

  const fetchReceipt = async (paymentLinkId) => {
    try {
      const receiptRes = await http.get(
        `/Payments/GetReceipt/${paymentLinkId}`,
      );
      setReceiptData(receiptRes.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex w-10/12 max-w-2xl flex-col items-center gap-8 rounded-2xl border border-light-purple bg-white p-6 md:w-8/12">
          <div className="w-11/12 max-w-md p-2">
            <SuccessIcon />
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple ">
              Order Paid Successfully
            </h4>
            <p className="text-center text-gray-700">
              We don&rsquo;t want to keep you waiting. Your receipt and
              confirmation details are on their way to your email.
            </p>
            {loading ? (
              <OrderSuceessSkeleton />
            ) : (
              <PaymentSuccess receipt={receiptData} />
            )}
            <p className="text-center font-bold capitalize text-gray-600">
              Thank you for choosing MedX
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
