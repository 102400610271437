import { useEffect, useState } from "react";

import { Tree } from "primereact/tree";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";

import useAxios from "../../hooks/useAxios";
import SquarePlusIcon from "../../iconComponents/SquarePlusIcon";
import SquareMinusIcon from "../../iconComponents/SquareMinusIcon";
import useHandleResize from "../../hooks/useHandleResize";

export default function TaxonomyTree({
  selectedChooseList,
  selectedOptionsList,
  setSelectedChooseList,
  setSelectedOptionsList,
}) {
  const { http } = useAxios();
  const { isMobile } = useHandleResize();

  const [treeData, setTreeData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [noResultFound, setNoResultFound] = useState(false);
  const [isLoadingTree, setIsLoadingTree] = useState(false);

  const handleDeleteOption = (index) => {
    const taxonomyId = selectedChooseList[index].taxonomyId;
    const isPrimary = selectedChooseList[index].isPrimary;

    setSelectedChooseList((prev, index) =>
      prev.filter((item) => item.taxonomyId !== taxonomyId),
    );
    setSelectedOptionsList((prev) =>
      prev.filter((item) => item.taxonomyId !== taxonomyId),
    );

    if (isPrimary) {
      setSelectedChooseList((prev) => {
        const updatedList = [...prev];
        if (updatedList.length > 0) {
          updatedList[0].isPrimary = true;
        }
        return updatedList;
      });
      setSelectedOptionsList((prev) => {
        const updatedList = [...prev];
        if (updatedList.length > 0) {
          updatedList[0].isPrimary = true;
        }
        return updatedList;
      });
    }
  };

  const handleLabelClick = (e, node) => {
    e.preventDefault();
    e.stopPropagation();

    const clone = structuredClone(treeData);
    const updatedClone = toggleNodeExpansion(clone, node.key);
    setTreeData(updatedClone);
  };

  const toggleNodeExpansion = (data, key) => {
    return data.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      } else if (item.children) {
        return {
          ...item,
          children: toggleNodeExpansion(item.children, key),
        };
      }
      return item;
    });
  };

  const filterTreeData = (data, searchText) => {
    return data.map((node) => {
      const labelMatches = node.label
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const codeMatches = node?.code
        ?.toLowerCase()
        .includes(searchText.toLowerCase());
      if (labelMatches || codeMatches) {
        return true;
      }
      if (node.children && node.children.length > 0) {
        const filteredChildren = filterTreeData(node.children, searchText);
        return filteredChildren.some((data) => data === true);
      }
      return false;
    });
  };

  const onCategoryChange = (e, node) => {
    e.stopPropagation();
    const selectedValue = node;
    setSelection([selectedValue]);
  };

  const handleSetSelectedChosenList = () => {
    let newSelection = [];
    const _selectedOptionsList = [...selectedChooseList];
    const hasNoPrimary = _selectedOptionsList.every(
      (option) => option?.isPrimary === false,
    );

    if (hasNoPrimary && _selectedOptionsList.length)
      _selectedOptionsList[0].isPrimary = true;

    const index = _selectedOptionsList.findIndex(
      (option) => option.taxonomyId === selection[0].key,
    );
    if (index === -1) {
      newSelection = {
        isPrimary: hasNoPrimary,
        code: selection[0].code,
        label: selection[0].label,
        taxonomyId: selection[0].key,
      };
      setSelectedChooseList((prev) => [...prev, newSelection]);
    } else {
      newSelection = _selectedOptionsList.filter(
        (option) => option.taxonomyId !== selection[0].key,
      );
    }

    setSelection([]);
  };

  const nodeTemplate = (node, options) => {
    const hasChildren = node.children && node.children.length > 0;
    const isSelected = selection.some((option) => option.key === node.key);
    const labelClassName = isSelected ? "text-light-purple bg-gray-200" : "";
    return (
      <div
        className={`taxonomy-node flex w-full items-center rounded px-2 py-2 ${labelClassName}`}
        onClick={(e) => onCategoryChange(e, node)}
      >
        {hasChildren && (
          <i
            className={node.expanded ? "icon-expanded" : "icon-collapsed"}
            onClick={(e) => {
              handleLabelClick(e, node);
            }}
          >
            {node.expanded ? <SquareMinusIcon /> : <SquarePlusIcon />}
          </i>
        )}
        <label
          htmlFor={node.taxonomyId}
          className={`ml-2 cursor-pointer ${labelClassName} ${!hasChildren && "ml-6"}`}
        >
          {node.label}
          {node.code && (
            <small className="italic text-gray-400"> - ({node.code})</small>
          )}
        </label>
      </div>
    );
  };

  const getTaxonomy = async () => {
    setIsLoadingTree(true);
    try {
      const response = await http.get("/Taxonomies/GetTaxonomies");
      const responseData = response.data;
      setTreeData(responseData);
    } catch (error) {
      console.error("Error fetching tree data:", error);
    }
    setIsLoadingTree(false);
  };

  useEffect(() => {
    getTaxonomy();
  }, []);

  return (
    <div className="parent-taxonomy mt-2 flex w-full flex-col gap-3 md:flex-row md:gap-0">
      <div className="child-taxonomy basis-1/2 rounded-lg border">
        <div className="text-left " style={{ height: "300px" }}>
          <Tree
            pt={{
              content: {
                style: { padding: "0", border: "none", fontSize: "1rem" },
              },
              root: { style: { border: "none" } },
              loadingOverlay: {
                style: { background: "#b9b5ff40" },
              },
            }}
            value={treeData}
            loading={isLoadingTree}
            selectionKeys={selection}
            selectionMode={"single"}
            nodeTemplate={nodeTemplate}
            filterValue={filterValue}
            filterTemplate={(options) => (
              <div className="flex">
                {options.element}
                <Button
                  text
                  rounded
                  severity="secondary"
                  onClick={options.filterOptions.reset}
                  icon="pi pi-times"
                  type="button"
                />
              </div>
            )}
            header={({ className, filterElement, ...opts }) => {
              return (
                <div className={className}>
                  <div className="mb-2 w-full">
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-search" />
                      <InputText
                        type="text"
                        className="p-inputtext p-component w-full"
                        value={filterValue}
                        onChange={(e) => {
                          setFilterValue(e.target.value);
                          setNoResultFound(
                            !filterTreeData(treeData, e.target.value).some(
                              (data) => data === true,
                            ),
                          );
                          opts.filterInput.onChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Search"
                      />
                      <i
                        className="pi pi-times text-red-500"
                        onClick={(e) => {
                          setFilterValue("");
                          setNoResultFound(false);
                          opts.filterInput.onChange(e);
                        }}
                      ></i>
                    </span>
                  </div>
                  {noResultFound && (
                    <p className="capitalize text-gray-500">No result found</p>
                  )}
                </div>
              );
            }}
            filter
            filterBy="label,code"
            metaKeySelection={false}
            className="custom-arrow-icon w-full "
            placeholder="Select taxonomy"
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-2 text-gray-400">
        <Button
          onClick={() => {
            handleSetSelectedChosenList();
          }}
          type="button"
          link
          icon={
            isMobile
              ? "pi pi-angle-double-down text-lg font-semibold"
              : "pi pi-angle-double-right text-lg font-semibold"
          }
          tooltip="Select Taxonomy"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
          className=" justify-center bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
        />
      </div>
      <div className="child-list basis-1/2 rounded-lg border p-3">
        <label className="mb-4 mt-4 block font-Poppins text-base capitalize leading-loose text-gray-500">
          selected Taxonomy
        </label>

        <div className="mt-3 flex h-52 flex-col gap-4 overflow-y-auto">
          {selectedChooseList.length ? (
            selectedChooseList.map((option, index) => (
              <div
                key={index}
                className="node-tooltip flex items-center justify-between gap-4"
              >
                <div className="text-base">
                  {option.label}{" "}
                  {option.code && (
                    <small className="italic text-gray-400">
                      - ({option.code})
                    </small>
                  )}
                </div>
                <div className="flex items-center gap-2 pl-4 pr-2">
                  <RadioButton
                    inputId={`isPrimary_${index}`}
                    name={`isPrimary_${index}`}
                    value={option.isPrimary}
                    checked={option.isPrimary}
                    onChange={() => {
                      setSelectedOptionsList((prevOptions) =>
                        prevOptions.map((prevOption, prevIndex) => ({
                          ...prevOption,
                          isPrimary: prevIndex === index,
                        })),
                      );
                      setSelectedChooseList((prevOptions) =>
                        prevOptions.map((prevOption, prevIndex) => ({
                          ...prevOption,
                          isPrimary: prevIndex === index,
                        })),
                      );
                    }}
                  />
                  <label htmlFor="isPrimary">Primary</label>

                  <i
                    className="pi pi-times ml-3 cursor-pointer pt-1 text-red-500"
                    onClick={(e) => {
                      handleDeleteOption(index);
                    }}
                  ></i>
                </div>
              </div>
            ))
          ) : (
            <p className="capitalize text-gray-500">no taxonomy selected</p>
          )}
        </div>
      </div>
    </div>
  );
}
