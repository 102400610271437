import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../../store";
import useTokenData from "../../../hooks/useTokenData";
import useAxios from "../../../hooks/useAxios";
import { Controller, useForm } from "react-hook-form";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

function SelectUserImpersonate({
  setShowDialogChooseUser,
  showDialogChooseUser,
  onUserIdSubmit,
  selectedEntityId,
  isImpersonateLoading,
}) {
  const [user, setUser] = useState([]);
  const { http } = useAxios();

  const {
    control,
    setValue,
    formState: { errors },
    reset,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onchange",
  });

  useEffect(() => {
    if (selectedEntityId) {
      getUsers();
    }
  }, [selectedEntityId]);

  const getUsers = async () => {
    try {
      const response = await http.get(
        `/Entities/GetPracticeUsersToImpersonate/${selectedEntityId}`,
      );
      const usersData = response.data;
      setUser(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedUser = (field, selectedOption) => {
    setValue("userId", selectedOption.value);
    clearErrors("userId");
  };

  const footerContent = (
    <div>
      <Button
        label="Impersonate"
        icon="pi pi-check"
        type="submit"
        form="createImpersonateForm"
        loading={isImpersonateLoading}
        autoFocus
        className="mt-9 w-full justify-center rounded-lg bg-light-purple  py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
      />
    </div>
  );
  const onSubmit = (formData) => {
    onUserIdSubmit(formData.userId);
    setShowDialogChooseUser(false);
    reset();
  };

  return (
    <Dialog
      visible={showDialogChooseUser}
      modal
      draggable={false}
      footer={footerContent}
      style={{ width: "35rem" }}
      onHide={() => {
        if (!showDialogChooseUser) return;
        setShowDialogChooseUser(false);
        reset();
      }}
    >
      <form
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
        id="createImpersonateForm"
      >
        <div className="flex w-full items-center justify-center sm:px-8">
          <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
            <h4 className="mb-14 mt-4 text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
              Proceed With Impersonation
            </h4>
            <div className="flex h-3/4 w-full flex-col">
              <p className="font-semibold">
                Please select the user you would like to impersonate:
              </p>

              <div className="flex flex-col items-center justify-center gap-4 sm:flex sm:flex-row">
                <div className="w-full">
                  <div className="mb-5 mt-4 w-full">
                    <Controller
                      name="userId"
                      control={control}
                      rules={{
                        required: "Please select user want to impersonate ",
                      }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          placeholder="Choose user"
                          focusInputRef={field.ref}
                          options={user.map((option) => ({
                            value: option.userId,
                            label: option.fullName,
                          }))}
                          onChange={(selectedOption) => {
                            handleSelectedUser(field, selectedOption);
                          }}
                          className="md:w-14rem w-full"
                        />
                      )}
                    />
                    {errors.userId && (
                      <p className="text-red-500 ">{errors.userId.message}</p>
                    )}
                    {!user.length && (
                      <div className="my-4 h-5">
                        <span className="text-red-500">
                          It seems there's no user available .
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default SelectUserImpersonate;
