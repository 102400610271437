import React from "react";

function MedicalHistoryIcon() {
  return (
    <svg
      width="123"
      height="115"
      viewBox="0 0 120 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_615_101997)">
        <path
          d="M88.7447 29.0059L74.9385 25.3066L74.3304 27.5761C73.9847 28.8663 72.646 29.6392 71.3558 29.2935L43.3013 21.7763C42.011 21.4306 41.2382 20.0919 41.5839 18.8017L42.192 16.5322L28.3859 12.8328C25.4753 12.0529 22.4729 13.7865 21.6931 16.6969L1.54644 91.8851C0.766543 94.7958 2.50011 97.7981 5.41054 98.578L65.7691 114.751C68.6797 115.531 71.6821 113.797 72.4619 110.887L92.6086 35.6987C93.3886 32.7883 91.6553 29.7858 88.7447 29.0059Z"
          fill="#707487"
        />
        <path
          d="M5.43368 92.9269L25.5804 17.7385C26.3603 14.8279 29.3627 13.0946 32.2732 13.8744L28.3859 12.8328C25.4753 12.0529 22.4729 13.7865 21.6931 16.6969L1.54644 91.8851C0.766543 94.7958 2.50011 97.7981 5.41054 98.578L9.29783 99.6195C6.38717 98.8398 4.65383 95.8374 5.43368 92.9269Z"
          fill="#5B5D6E"
        />
        <path
          d="M71.3153 91.704L85.1477 40.0808C85.3111 39.4708 84.92 38.8363 84.2742 38.6632L27.4271 23.4311C26.7813 23.2581 26.1253 23.6123 25.9619 24.222L7.86358 91.766C7.70015 92.3759 8.09123 93.0105 8.73701 93.1835L49.7282 104.167L71.3153 91.704Z"
          fill="#E4EAF8"
        />
        <path
          d="M11.7507 92.8077L29.849 25.2637C30.0124 24.6539 30.6684 24.2997 31.3142 24.4728L27.4271 23.4312C26.7813 23.2582 26.1253 23.6124 25.9619 24.2221L7.86358 91.7661C7.70015 92.3761 8.09123 93.0106 8.73701 93.1837L12.6243 94.2253C11.9783 94.0522 11.5873 93.4174 11.7507 92.8077Z"
          fill="#D8DCE5"
        />
        <path
          d="M64.2605 40.7709L43.7063 35.2634C41.9329 34.7883 40.8766 32.9588 41.3519 31.1852L44.043 21.1418C44.2587 20.3368 45.0862 19.8589 45.8914 20.0747C46.6964 20.2904 47.1743 21.1179 46.9585 21.9231L44.2674 31.9662C44.223 32.1321 44.3217 32.3033 44.4877 32.3478L65.042 37.8553C65.2078 37.8997 65.3791 37.8008 65.4235 37.635L68.1146 27.5918C68.3303 26.7868 69.1578 26.3089 69.9629 26.5247C70.7681 26.7404 71.2459 27.5678 71.0301 28.373L68.339 38.4162C67.8636 40.1899 66.0339 41.2461 64.2605 40.7709Z"
          fill="#0077AA"
        />
        <path
          d="M64.4612 17.4903L65.4869 13.6624C65.8326 12.3722 65.0596 11.0335 63.7693 10.6878L59.1721 9.45599C57.8819 9.11027 56.5433 9.88312 56.1976 11.1734L55.172 15.001L64.4612 17.4903Z"
          fill="#D8DCE5"
        />
        <path
          d="M63.1313 10.5167L59.1721 9.45587C57.8819 9.11015 56.5433 9.883 56.1976 11.1732L55.172 15.0009L59.1309 16.0617L60.1566 12.234C60.5025 10.9439 61.8411 10.171 63.1313 10.5167Z"
          fill="#C3C8D1"
        />
        <path
          d="M73.8843 19.9106L45.8026 12.3861C44.4629 12.0271 43.081 12.825 42.7221 14.1646L41.4829 18.7892C41.1239 20.1289 41.9218 21.5108 43.2614 21.8697L71.343 29.3942C72.6826 29.7531 74.0645 28.9553 74.4235 27.6156L75.6626 22.991C76.0218 21.6514 75.2239 20.2695 73.8843 19.9106Z"
          fill="#E4EAF8"
        />
        <path
          d="M72.3837 25.5071L44.302 17.9826C42.9623 17.6237 42.1645 16.2418 42.5234 14.9021L41.4819 18.7892C41.1229 20.1289 41.9208 21.5108 43.2604 21.8697L71.342 29.3942C72.6816 29.7531 74.0635 28.9553 74.4225 27.6156L75.464 23.7285C75.1053 25.0682 73.7233 25.8661 72.3837 25.5071Z"
          fill="#D8DCE5"
        />
        <path
          d="M71.3139 91.704L55.4584 87.4555C54.8126 87.2825 54.1566 87.6367 53.9932 88.2464L49.7273 104.167L71.3139 91.704Z"
          fill="#D8DCE5"
        />
        <path
          d="M57.8824 89.2879C58.0459 88.678 58.7018 88.324 59.3476 88.497L55.4603 87.4554C54.8145 87.2824 54.1585 87.6366 53.9951 88.2463L49.7292 104.167L54.6581 101.321L57.8824 89.2879Z"
          fill="#C3C8D1"
        />
        <path
          d="M105.253 38.3761C101.918 37.4858 98.5221 38.5385 96.2637 40.8378L96.2635 40.8372C96.2635 40.8372 95.6 41.321 94.9374 41.1439C94.2749 40.9674 93.9411 40.2181 93.9411 40.2181L93.9394 40.2177C93.1263 37.0994 90.7086 34.4945 87.3722 33.6041C82.4345 32.2863 77.3624 35.2212 76.0449 40.1591C72.6026 53.0603 84.79 64.3804 87.487 66.6942C87.4889 66.6955 87.9774 67.0493 88.6659 67.0101C92.1515 66.3489 108.366 62.6072 111.809 49.7033C113.127 44.7651 110.192 39.6943 105.253 38.3761Z"
          fill="#645CBA"
        />
        <path
          d="M79.9311 41.2005C81.071 36.9285 85.0214 34.1583 89.2635 34.3413C88.6728 34.0338 88.0416 33.7828 87.3711 33.6038C82.4334 32.286 77.3613 35.2209 76.0439 40.1587C72.6016 53.06 84.789 64.3801 87.4859 66.6941C87.4877 66.6954 87.9763 67.0491 88.6648 67.0098C89.0398 66.9386 89.5628 66.8316 90.2005 66.6816C86.2293 62.9618 76.8601 52.7108 79.9311 41.2005Z"
          fill="#594FC4"
        />
        <path
          d="M39.5613 76.1085C39.5377 76.1022 39.5143 76.0953 39.491 76.0879C38.8627 75.8866 38.438 75.3004 38.4423 74.6408L38.5748 54.6392L34.6054 61.0094C34.2542 61.5733 33.5756 61.8409 32.934 61.669L25.9542 59.7988C25.1492 59.5831 24.6713 58.7556 24.8871 57.9504C25.1028 57.1452 25.9302 56.6675 26.7354 56.8833L32.6351 58.4641L38.8384 48.5087C39.1958 47.9354 39.8928 47.6647 40.5392 47.8573C41.188 48.0452 41.6329 48.6414 41.6284 49.3167L41.4925 69.8405L46.2371 63.1021C46.6014 62.5847 47.2502 62.3495 47.8617 62.5133L52.7509 63.8234C53.3608 63.9868 53.8045 64.5125 53.863 65.1412L54.0156 66.7818L59.5852 56.8891C59.9142 56.3047 60.5894 56.0074 61.2424 56.1596C61.8992 56.3115 62.37 56.8764 62.4072 57.546L62.9059 66.5446L68.2306 67.9713C69.0356 68.187 69.5135 69.0145 69.2978 69.8197C69.082 70.6249 68.2546 71.1026 67.4494 70.8869L61.069 69.1772C60.4391 69.0084 59.9889 68.4541 59.9526 67.803L59.6794 62.8741L54.2759 72.4723C53.9519 73.0475 53.2919 73.3457 52.6463 73.2081C51.9988 73.0689 51.5194 72.5292 51.4583 71.8719L50.9551 66.467L48.0984 65.7016L41.1857 75.5196C40.8197 76.0389 40.1699 76.2716 39.5613 76.1085Z"
          fill="#645CBA"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_101997">
          <rect
            width="100"
            height="100"
            fill="white"
            transform="translate(23.8828) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MedicalHistoryIcon;
