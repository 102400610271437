import React, { useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";

import ReusableDataList from "../../ReusableDataList/ReusableDataList";
import useHandleResize from "../../../hooks/useHandleResize";

function RemitsPending() {
  const toast = useRef(null);
  const [refetch, setRefetch] = useState();
  const Transaction = async (rowData, navigate) => {
    navigate(`/admin/worklists/complete/${rowData.entityId}`, {
      state: { rowData },
    });
  };
  const { isMobile } = useHandleResize();

  const ActionTemplateComponent = ({ rowData }) => {
    const navigate = useNavigate();
    const handleTransactionClick = () => {
      Transaction(rowData, navigate);
    };

    return (
      <div
        onClick={handleTransactionClick}
        className="flex w-[70%] cursor-pointer items-center justify-center rounded-full bg-green-500 py-3 font-semibold hover:bg-green-600 text-md capitalize text-white"
      >
        Go To Transaction
        <i className="pi pi-arrow-right ml-2"></i>
      </div>
    );
  };

  const nameImagTemplate = (rowData) => (
    <>
      <div className="flex items-center">
        {rowData.imageUrl ? (
          <img
            src={rowData.imageUrl}
            alt={rowData.practiceName}
            className="mr-2 h-[30px] w-[30px] rounded-full"
          />
        ) : (
          <Avatar
            label={rowData.practiceName[0]}
            shape="circle"
            className="mr-2 bg-blue-500 text-white"
          />
        )}
        <h4 className="font-inter text-sm font-semibold">
          {rowData.practiceName}
        </h4>
      </div>
    </>
  );

  const columns = [
    { name: "Name", value: "practiceName", template: nameImagTemplate },
    { name: "Outstanding Transactions Count", value: "numberOfResultedOrders" },
  ];

  const cardTemplate = (row) => {
    return (
      <>
        {nameImagTemplate(row)}
        <p className="font-semibold">
          <span className="text-slate-500">
            Number of Outstanding Transactions:&nbsp;
          </span>
          {row.numberOfResultedOrders}
        </p>

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="text-slate-500">Action:&nbsp;</span>
          </div>
          <ActionTemplateComponent rowData={row} />
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ReusableDataList
        title={"List of rendering providers"}
        fromFront={true}
        dataLink={"/Entities/GetRemitsWorkList"}
        columns={columns}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [
                {
                  template: (rowData) => (
                    <ActionTemplateComponent rowData={rowData} />
                  ),
                  header: "Action",
                },
              ]
        }
        refetch={refetch}
      />
    </>
  );
}

export default RemitsPending;
