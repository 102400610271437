import { RadioButton } from "primereact/radiobutton";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import useAxios from "../hooks/useAxios";
import { useNavigate } from "react-router-dom";

export default function WelcomeProvider() {
  const { logOut } = useAxios();

  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      view: "verify",
    },
  });

  const onSubmit = async (formData) => {
    const view = formData.view;
    if (view === "verify") {
      navigate("/ordering/settings/verifyProvider");
    } else {
      navigate("/Dashboard")
    }
  };
  return (
    <div className="flex h-full  items-center justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex max-w-xl flex-col gap-5 rounded-lg border border-dark-purple bg-white p-10 text-center">
          <h1 className="font-philosopher text-2xl font-extrabold text-dark-purple">
            Welcome!.
          </h1>
          <p className="max-w-md self-center text-gray-600">
            Choose your preferred option for your situation. <br /> Please
            select one of the following options
          </p>
          <div className="grid grid-cols-2 gap-5">
            <Controller
              name="view"
              control={control}
              render={({ field }) => (
                <>
                  <div
                    onClick={() => {
                      setValue("view", "verify");
                    }}
                    className={`col-span-1 cursor-pointer rounded-lg border   p-4 hover:bg-slate-100 hover:shadow-md ${watch("view") === "render" ? " border-dark-purple bg-slate-50 shadow-md" : "border-slate-300  "}`}
                  >
                    <div className="flex gap-3">
                      <RadioButton
                        inputId="verify"
                        {...field}
                        inputRef={field.ref}
                        value={"verify"}
                        checked={field.value === "verify"}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        htmlFor="verify"
                        className="font-semibold text-gray-800 capitalize"
                      >
                        verify yourself as a provider
                      </label>
                    </div>
                    <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                      Select this option if you're ready to verify yourself as a provider now and complete the steps of the provider verification
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setValue("view", "login");
                    }}
                    className={`col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${watch("view") === "order" ? " border-dark-purple bg-slate-50 shadow-md" : "border-slate-300  "}`}
                  >
                    <div className="flex gap-3">
                      <RadioButton
                        inputId="login"
                        {...field}
                        inputRef={field.ref}
                        value={"login"}
                        checked={field.value === "login"}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        htmlFor="login"
                        className="font-semibold text-gray-800 capitalize"
                      >
                        go to the dashboard
                      </label>
                    </div>
                    <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                      you'll have the option to verify yourself as a provider at a
                      later time.
                    </p>
                  </div>
                </>
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button label="Save & Continue" loading={isSubmitting} />
          </div>
        </div>
      </form>
    </div>
  );
}
