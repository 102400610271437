import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { Accordion, AccordionTab } from "primereact/accordion";

import useTokenData from "../hooks/useTokenData";
import useHandleResize from "../hooks/useHandleResize";
import { editorHeaderTemplate, formatDate } from "./utility/Utils";
import useDelayedInputChange from "../hooks/useDelayedInputChange";
import ReusableDataList from "./ReusableDataList/ReusableDataList";
import { CreateBillableItem } from "./services/billableItems/CreateBillableItem";
import SelectSingleBillableTable from "./heplers/ruleHelpers/SelectSingleBillableTable";
import SwappedBillableTable from "./heplers/ruleHelpers/SwappedBillableTable";
import SelectDocuments from "./services/SelectDocuments";

function GroupForm({
  groupRule,
  groupIndex,
  allRules,
  setAllRules,
  setRules,
  mainRules,
  allQuestions,
  hasQuestionLabel,
  mainLocation,
  selectedCptCodes,
  formErrors,
  setSelectedCptCodes,
  toast,
  getUsedBillableItems,
  getAllBillableItems,
  serviceDocumentsIds,
  getAllServiceDocs,
}) {
  const initialRule = {
    type: "single",
    name: "",
    action: "addCPT",
    ruleCondition: "and",
    locations: [],
    documents: [],
    billableItems: [],
    conditions: [
      {
        question: {
          label: "",
        },
        answer: {
          label: "",
        },
        questionCondition: "equal",
        answerOptions: [],
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      },
    ],
    defaultUnitsChange: [],
  };
  const [activeIndex, setActiveIndex] = useState([]);
  const [contradictionErr, setContradictionErr] = useState(false);
  const [availableAnswers, setAvailableAnswers] = useState();
  const [tableVisible, setTableVisible] = useState(false);
  const [swap, setSwap] = useState(false);
  const [billableToSwapDialogVisible, setBillableToSwapDialogVisible] =
    useState(false);
  const [swappedBillableDialogVisible, setSwappedBillableDialogVisible] =
    useState(false);
  const [refetch, setRefetch] = useState(false);
  const [notSavedRules, setNotSavedRules] = useState([]);
  const [searchValue, handleInputChange, setSearchValue] =
    useDelayedInputChange(refetch, setRefetch);
  const [hcpcsSearchValue, setHcpcsSearchValue] = useState(undefined);
  const [billableItemVisible, setBillableItemVisible] = useState(false);
  const { entityId } = useTokenData();

  useEffect(() => {
    setNotSavedRules(mainRules);
  }, [mainRules]);

  const deleteTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = mainRules[groupIndex].rules.length
      ? [...mainRules]
      : [...allRules];
    const index = [...rulesClone][ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div className="mt-4" key={ruleIndex}>
        {index !== -1 ? (
          <i
            className="pi pi-times cursor-pointer text-red-400"
            min={1}
            type="number"
            onClick={(e) => {
              const newRules = mainRules[groupIndex].rules.length
                ? [...mainRules]
                : [...allRules];
              newRules[ruleIndex].billableItems.splice(index, 1);
              mainRules[groupIndex].rules.length
                ? setRules(newRules)
                : setAllRules(newRules);
            }}
          />
        ) : null}
      </div>
    );
  };
  const deleteBillableTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = mainRules[groupIndex].rules.length
      ? [...mainRules]
      : [...allRules];
    const index = [...rulesClone][ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div key={ruleIndex}>
        {
          <Checkbox
            className="cursor-pointer"
            checked={index !== -1}
            type="number"
            id="remove-from-billable-items"
            onClick={() => {
              index !== -1
                ? rulesClone[ruleIndex].billableItems.splice(index, 1)
                : rulesClone[ruleIndex].billableItems.push(rowData);
              mainRules[groupIndex].rules.length
                ? setRules(rulesClone)
                : setAllRules(rulesClone);
            }}
          ></Checkbox>
        }
      </div>
    );
  };

  const differenceByKey = (array1, array2, key) => {
    const set2 = new Set(array2.map((item) => item[key]));
    return array1.filter((item) => !set2.has(item[key]));
  };

  const removeAllExceptBillableItemsTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = mainRules[ruleIndex].rules.length
      ? [...mainRules]
      : [...allRules];

    const index = rulesClone[ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div key={ruleIndex}>
        {
          <Checkbox
            className="cursor-pointer"
            id="delete-billable-item"
            checked={index !== -1}
            type="number"
            onClick={() => {
              const rulesClone = mainRules[ruleIndex].rules.length
                ? [...mainRules]
                : [...allRules];

              index !== -1
                ? rulesClone[ruleIndex].billableItems.splice(index, 1)
                : rulesClone[ruleIndex].billableItems.push(rowData);
              rulesClone.length
                ? setRules(rulesClone)
                : setAllRules(rulesClone);
            }}
          ></Checkbox>
        }
      </div>
    );
  };

  function translatePattern(pattern) {
    // Escape regex special characters except for our wildcard and range characters
    pattern = pattern.replace(/([.+^$(){}|\\])/g, "\\$1");

    // Translate the user-friendly pattern into regex
    pattern = pattern
      .replace(/\*/g, ".*") // * => .*
      .replace(/\?/g, ".") // ? => .
      .replace(/#/g, "[0-9]") // # => [0-9]
      .replace(/\[!([^\]]+)\]/g, "[^$1]") // [!a-z] => [^a-z] for negation
      .replace(/\[([^\]]+)\]/g, "[$1]"); // [a-z] => [a-z]

    try {
      return new RegExp("^" + pattern + "$");
    } catch (error) {
      return null;
    }
  }

  function matchPattern(expression, pattern) {
    const regex = translatePattern(pattern);
    try {
      return regex.test(expression);
    } catch (error) {
      return null;
    }
  }

  const changeExistingUnit = (rowData, ruleIndex, rule) => {
    const rulesClone = mainRules[groupIndex].rules.length
      ? [...mainRules]
      : [...allRules];
    const index = rule.defaultUnitsChange.findIndex(
      (obj) => obj.id == rowData.id,
    );

    return (
      <div className="mt-4" key={ruleIndex}>
        <InputText
          className="ml-2 w-16"
          value={rowData.changeInUnit}
          id="billable-existing-units-change"
          onChange={(e) => {
            const newValue = Number(e.target.value);
            const ruleClone = [...mainRules];
            if (/^\d*$/.test(newValue)) {
              if (index === -1 && newValue > 0) {
                ruleClone[ruleIndex].defaultUnitsChange.push({
                  ...rowData,
                  changeInUnit: newValue,
                });
              } else {
                if (newValue > 0) {
                  ruleClone[ruleIndex].defaultUnitsChange[index].changeInUnit =
                    newValue;
                } else {
                  ruleClone[ruleIndex].defaultUnitsChange.splice(index, 1);
                }
              }
              mainRules.length > 0
                ? setRules(ruleClone)
                : setAllRules(ruleClone);
            }
          }}
        />
      </div>
    );
  };
  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? (
      <p>{rowData[mod]}</p>
    ) : (
      <p className="border-[1px] border-gray-400"></p>
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <p>
        {rowData.chargeAmount} <span className="text-green-500">$</span>
      </p>
    );
  };

  const unitsTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = notSavedRules[groupIndex].rules.length
      ? structuredClone([...notSavedRules])
      : structuredClone([...allRules]);
    const index = [...rulesClone][groupIndex]?.billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div className="mt-4" key={ruleIndex}>
        {index !== -1 ? (
          <>
            <InputText
              className={`${rulesClone[groupIndex].billableItems[index].error ? "p-invalid" : ""} w-16`}
              value={rulesClone[groupIndex].billableItems[index].units}
              id="billable-units"
              min={1}
              tooltipOptions={{ position: "top" }}
              tooltip={rulesClone[groupIndex].billableItems[index].error}
              keyfilter={/^\d+$/}
              onChange={(e) => {
                const newRules = rulesClone;
                newRules[groupIndex].billableItems[index] = {
                  ...newRules[groupIndex].billableItems[index],
                  units: Number(e.target.value),
                  error: Number(e.target.value) ? "" : "Value cannot be 0",
                };
                notSavedRules.length
                  ? setNotSavedRules(newRules)
                  : setNotSavedRules(newRules);
              }}
            />
          </>
        ) : null}
      </div>
    );
  };

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.shortDescription}
        >
          {rowData.description}
        </p>
      </div>
    );
  };
  const questionOperators = [
    { label: "Equal", value: "equal" },
    { label: "Not Equal", value: "notEqual" },
    { label: "Not Answered", value: "notAnswered" },
  ];

  const ruleOperators = [
    { label: "All", value: "and" },
    { label: "Any", value: "or" },
  ];

  const handleDeleteRule = (index, ruleIndex) => {
    const newRules = [...mainRules];

    newRules[groupIndex].rules[ruleIndex].conditions.splice(index, 1);
    setRules(newRules);
  };

  const handleAddCondition = (ruleIndex) => {
    if (mainRules[groupIndex].rules.length) {
      const newRules = [...mainRules];
      newRules[groupIndex].rules[ruleIndex].conditions.push({
        question: { label: "" },
        answer: { label: "" },
        questionCondition: "equal",
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      });
      setRules(newRules);
    } else {
      const newRules = [...allRules];
      newRules[groupIndex].rules[ruleIndex].conditions.push({
        question: { label: "" },
        answer: { label: "" },
        questionCondition: "equal",
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      });
      setAllRules(newRules);
    }
  };
  const emptyActions = () => {
    if (mainRules[groupIndex].rules.length > 0) {
    } else {
    }
  };
  const deleteGroupOfRules = () => {
    const allRulesClone = mainRules[groupIndex].rules.length
      ? [...mainRules]
      : [...allRules];
    allRulesClone.splice(groupIndex, 1);
    mainRules[groupIndex].rules.length
      ? setRules(allRulesClone)
      : setAllRules(allRulesClone);
  };
  const { isMobile } = useHandleResize();

  const removeDuplicates = (arr) => {
    const seen = new Set();
    return arr.filter((obj) => {
      const duplicate = seen.has(obj.id);
      seen.add(obj.id);
      return !duplicate;
    });
  };

  return (
    <div className="">
      <Dialog
        blockScroll
        draggable={false}
        visible={billableToSwapDialogVisible}
        onHide={() => {
          setBillableToSwapDialogVisible(false);
        }}
        header="Choose a billable item to remove"
        className="w-11/12 lg:w-[80vw]"
      >
        <SelectSingleBillableTable
          value={getAllBillableItems()}
          selection={groupRule.billableItems[0]}
          onSelectionChange={(e) => {
            const newRules = notSavedRules[groupIndex].rules.length
              ? structuredClone([...notSavedRules])
              : structuredClone([...allRules]);

            newRules[groupIndex].billableItems[0] = e.value;
            notSavedRules[groupIndex].rules.length
              ? setRules(newRules)
              : setAllRules(newRules);
            setBillableToSwapDialogVisible(false);
          }}
        />
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        visible={swappedBillableDialogVisible}
        onHide={() => {
          setSearchValue("");
          setNotSavedRules(mainRules);
          setSwappedBillableDialogVisible(false);
        }}
        header="Choose a billable item to add"
        className="w-11/12 lg:w-[80vw]"
      >
        <div className="flex flex-col">
          <SelectSingleBillableTable
            link={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
            refetch={refetch}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
            payload={getUsedBillableItems()}
            selection={
              notSavedRules?.[groupIndex]?.billableItems.length
                ? notSavedRules?.[groupIndex]?.billableItems?.[1]
                : groupRule?.billableItems?.[1]
            }
            onUnitsChange={(e) => {
              const newRules = notSavedRules[groupIndex]?.rules?.length
                ? structuredClone(notSavedRules)
                : structuredClone(allRules);
              newRules[groupIndex].billableItems[1].units = e.target.value;
              setNotSavedRules(newRules);
            }}
            onSelectionChange={(e) => {
              const newRules = notSavedRules[groupIndex]?.rules?.length
                ? structuredClone([...notSavedRules])
                : structuredClone([...allRules]);
              newRules[groupIndex].billableItems[1] = {
                ...e.value,
                units: e.value.defaultUnits,
              };
              setNotSavedRules(newRules);
            }}
          />
          <Divider
            layout="horizontal"
            align="center"
            className="w-10/12 self-center"
          >
            <b>OR</b>
          </Divider>
          <div className="flex justify-center ">
            <Button
              id="serviceBillables"
              label="Create new billable item"
              type="button"
              outlined
              onClick={() => {
                setSwap(true);
                setSearchValue("");
                setNotSavedRules(mainRules);
                setHcpcsSearchValue("");
                setBillableItemVisible(true);
                setSwappedBillableDialogVisible(false);
              }}
            />
          </div>
          <Button
            className="self-end"
            label="Save"
            onClick={() => {
              if (notSavedRules[groupIndex].billableItems[1].units <= 0) {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "The number of units must be a minimum of 1",
                  life: 4000,
                });
                return;
              }
              if (notSavedRules.length) {
                notSavedRules[groupIndex].rules.length
                  ? setRules(notSavedRules)
                  : setAllRules(notSavedRules);
              }
              setNotSavedRules(mainRules);
              setSwappedBillableDialogVisible(false);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        visible={billableItemVisible}
        onHide={() => {
          setBillableItemVisible(false);
          setHcpcsSearchValue("");
          setSwap(false);
        }}
        header="New Billable Item"
        className="w-[80vw]"
      >
        <CreateBillableItem
          data={false}
          hcpcsSearchValue={hcpcsSearchValue}
          toast={toast}
          setVisible={setBillableItemVisible}
          addToService={true}
          selectedCptCodes={selectedCptCodes}
          setSelectedCptCodes={(rowData) => {
            const newRules = notSavedRules[groupIndex].rules.length
              ? structuredClone([...notSavedRules])
              : structuredClone([...allRules]);

            if (swap) {
              try {
                newRules[groupIndex].billableItems[1] = {
                  ...rowData[rowData.length - 1],
                  units: rowData[rowData.length - 1].defaultUnits,
                };
                setRules(newRules);
              } catch (error) {
                console.error(error);
              }
              setSwap(false);
              setBillableItemVisible(false);
              setTableVisible(false);
              setSearchValue("");
              setNotSavedRules(mainRules);
              return;
            }

            newRules[groupIndex].billableItems.push({
              ...rowData[rowData.length - 1],
              units: rowData[rowData.length - 1].defaultUnits,
              error: "",
            });

            setBillableItemVisible(false);
            setRules(newRules);
            setTableVisible(false);
            setSearchValue("");
            setNotSavedRules(mainRules);
          }}
        />
      </Dialog>
      <div className="flex  flex-col rounded-lg border-gray-200  md:border md:p-5 ">
        <Accordion
          activeIndex={activeIndex}
          onTabChange={(e) => {
            const className =
              typeof e.originalEvent.target.className === "string"
                ? e.originalEvent.target.className
                : e.originalEvent.target.className?.baseVal;
            if (!className.includes("non-expandable")) {
              setActiveIndex(e.index);
            }
          }}
        >
          {groupRule.rules.map((rule, ruleIndex) => {
            return (
              <AccordionTab
                key={ruleIndex}
                pt={{
                  content: {
                    style: {
                      padding: "4px",
                    },
                  },
                }}
                headerTemplate={() => (
                  <div
                    className="accordion w-full grid-cols-1  items-center md:grid md:grid-cols-10"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <label
                      htmlFor={`ruleName${ruleIndex}`}
                      className="col-span-1 flex cursor-pointer items-center"
                    >
                      Rule Name:
                    </label>
                    <div className="col-span-2 flex flex-col">
                      <InputText
                        key={`ruleName${ruleIndex}`}
                        id={`ruleName${ruleIndex}`}
                        value={rule.name}
                        onChange={(e) => {
                          if (mainRules[groupIndex].rules.length) {
                            const newRules = [...mainRules];
                            newRules[groupIndex].rules[ruleIndex].name =
                              e.target.value;
                            setRules(newRules);
                          } else {
                            const newRules = [...allRules];
                            newRules[groupIndex].rules[ruleIndex].name =
                              e.target.value;
                            setAllRules(newRules);
                          }
                        }}
                        className="non-expandable"
                      />
                      {formErrors[groupIndex]?.rules &&
                        formErrors[groupIndex].rules[ruleIndex] && (
                          <p className="text-red-500 ">
                            {formErrors[groupIndex].rules[ruleIndex]}
                          </p>
                        )}
                    </div>
                    {groupRule.rules.length > 2 ? (
                      <div className="non-expandable col-span-1 col-start-10 mt-2 flex w-full justify-center md:m-auto md:w-auto">
                        <Button
                          className="non-expandable w-full md:w-auto"
                          onClick={() => {
                            const allRulesClone = mainRules[groupIndex].rules
                              .length
                              ? [...mainRules]
                              : [...allRules];

                            allRulesClone[groupIndex].rules.splice(
                              ruleIndex,
                              1,
                            );
                            mainRules[groupIndex].rules.length
                              ? setRules(allRulesClone)
                              : setAllRules(allRulesClone);
                          }}
                          outlined
                          label={isMobile ? "Delete Role" : ""}
                          id="delete-role"
                          icon="pi pi-trash"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              >
                <div
                  id={ruleIndex + "conditions-key"}
                  key={ruleIndex + "conditions-key"}
                  className="mb-4"
                >
                  <h3 className="mb-2 text-lg font-semibold">Rule Operator</h3>
                  <div className="mb-2 mt-4" key={rule.ruleCondition.id}>
                    <Tooltip target=".custom-target-icon" />

                    <div className="flex w-full justify-center">
                      <div className="flex items-center justify-center rounded-xl border border-light-purple p-1 text-[.7rem] font-semibold text-light-purple sm:text-base md:w-[80%]">
                        If
                        {
                          <Dropdown
                            className="mx-2 flex h-8 w-20 items-center md:w-28"
                            options={ruleOperators}
                            id="rule-operators"
                            value={groupRule.rules[ruleIndex].ruleCondition}
                            onChange={(e) => {
                              if (mainRules[groupIndex].rules.length) {
                                const newRules = [...mainRules];
                                newRules[groupIndex].rules[
                                  ruleIndex
                                ].ruleCondition = e.target.value;
                                setRules(newRules);
                              } else {
                                const newRules = [...allRules];
                                newRules[groupIndex].rules[
                                  ruleIndex
                                ].ruleCondition = e.target.value;
                                setAllRules(newRules);
                              }
                            }}
                          />
                        }
                        Of The Following “IF” Conditions Are Matched
                      </div>
                      {formErrors[ruleIndex]?.ruleCondition && (
                        <p className="text-red-500">
                          {formErrors[ruleIndex].ruleCondition}
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    key={rule.name}
                    className="flex flex-col gap-3 rounded-xl border border-slate-200 bg-slate-50 p-2 md:m-5 md:p-8"
                  >
                    {rule?.conditions?.map((condition, index) => {
                      return (
                        <div
                          key={index + "rulingrp"}
                          className="flex flex-col lg:grid lg:grid-cols-11"
                        >
                          <div className="col-span-8 rounded-xl border border-l-8 border-slate-200 border-l-light-purple bg-white p-2 shadow-md md:p-4">
                            <div className="font-bold">
                              Condition {index + 1}
                            </div>
                            <div className="grid gap-2 p-3">
                              <div className="row-span-1 grid grid-cols-1 items-center gap-2 md:grid md:grid-cols-6 ">
                                <label
                                  className="col-span-1 font-semibold"
                                  htmlFor={`question${index}-${index}`}
                                >
                                  IF
                                </label>
                                <Dropdown
                                  value={condition.question}
                                  dataKey="label"
                                  optionLabel="label"
                                  id="condition.question.label"
                                  options={allQuestions}
                                  onChange={(e) => {
                                    setContradictionErr(false);
                                    if (mainRules.length) {
                                      const newr = [...mainRules];
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].question.label =
                                        e.value.label;
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].question.type =
                                        e.value.type;
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].answerOptions =
                                        e.value.options;
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].answer.label = "";
                                      setRules(newr);
                                    } else {
                                      const newr = [...allRules];
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].question.label =
                                        e.value.label;
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].answer.label = "";
                                      newr[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].answerOptions =
                                        e.value.options;
                                      setAllRules(newr);
                                    }
                                  }}
                                  className="col-span-5 rounded-none"
                                  placeholder={
                                    condition.question.label ||
                                    "Select A Question"
                                  }
                                />
                              </div>
                              <div className="row-span-1 grid grid-cols-1 items-center gap-2 md:grid-cols-6 ">
                                <label
                                  className="col-span-1 font-semibold"
                                  htmlFor={`question${ruleIndex}-${ruleIndex}`}
                                >
                                  State
                                </label>
                                <Dropdown
                                  className="rounded-none md:col-span-5"
                                  value={condition.questionCondition}
                                  options={questionOperators}
                                  id="condition-state"
                                  onChange={(e) => {
                                    if (mainRules[groupIndex].rules.length) {
                                      const newRules = [...mainRules];
                                      newRules[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].questionCondition =
                                        e.target.value;
                                      setRules(newRules);
                                    } else {
                                      const newRules = [...allRules];
                                      newRules[groupIndex].rules[
                                        ruleIndex
                                      ].conditions[index].questionCondition =
                                        e.target.value;
                                      setAllRules(newRules);
                                    }
                                  }}
                                />
                              </div>

                              {condition.questionCondition !==
                                "notAnswered" && (
                                <div className="row-span-1 grid grid-cols-1 items-center gap-2 md:grid-cols-6 ">
                                  <label
                                    className="col-span-1 font-semibold"
                                    htmlFor={`question${ruleIndex}-${ruleIndex}`}
                                  >
                                    Value
                                  </label>
                                  <div className="col-span-5 flex flex-col gap-2">
                                    {["number", "decimal"].includes(
                                      condition.question.type,
                                    ) ? (
                                      <>
                                        <Dropdown
                                          className="w-full rounded-none"
                                          id="condition-value"
                                          placeholder="Comparison operators"
                                          options={[
                                            {
                                              label: "Equal",
                                              value: "equal",
                                            },
                                            {
                                              label: "Bigger than",
                                              value: "biggerNumber",
                                            },
                                            {
                                              label: "Smaller than",
                                              value: "smallerNumber",
                                            },
                                            {
                                              label: "Bigger than or equal",
                                              value: "biggerThanOrEqual",
                                            },
                                            {
                                              label: "Smaller than or equal",
                                              value: "smallerThanOrEqual",
                                            },
                                            {
                                              label: "Between",
                                              value: "between",
                                            },
                                          ]}
                                          value={
                                            condition.numberComparisionCondition
                                          }
                                          onChange={(e) => {
                                            if (
                                              mainRules[groupIndex].rules.length
                                            ) {
                                              const newRules = [...mainRules];
                                              newRules[groupIndex].rules[
                                                ruleIndex
                                              ].conditions[
                                                index
                                              ].numberComparisionCondition =
                                                e.target.value;
                                              setRules(newRules);
                                            } else {
                                              const newRules = [...allRules];
                                              newRules[groupIndex].rules[
                                                ruleIndex
                                              ].conditions[
                                                index
                                              ].numberComparisionCondition =
                                                e.target.value;
                                              setAllRules(newRules);
                                            }
                                          }}
                                        />
                                        {condition.numberComparisionCondition ==
                                        "between" ? (
                                          <div className="grid grid-cols-2 gap-2">
                                            <InputNumber
                                              useGrouping={false}
                                              minFractionDigits={
                                                condition.question.type ===
                                                "number"
                                                  ? undefined
                                                  : 2
                                              }
                                              maxFractionDigits={
                                                condition.question.type ===
                                                "number"
                                                  ? undefined
                                                  : 3
                                              }
                                              value={
                                                condition.numberComparisionRange
                                                  .min
                                              }
                                              className="col-span-1"
                                              id="condition-number-comparison-condition-between"
                                              onChange={(e) => {
                                                if (
                                                  mainRules[groupIndex].rules
                                                    .length
                                                ) {
                                                  const newRules = [
                                                    ...mainRules,
                                                  ];
                                                  newRules[groupIndex].rules[
                                                    ruleIndex
                                                  ].conditions[
                                                    index
                                                  ].numberComparisionRange.min =
                                                    e.value;
                                                  setRules(newRules);
                                                } else {
                                                  const newRules = [
                                                    ...allRules,
                                                  ];
                                                  newRules[groupIndex].rules[
                                                    ruleIndex
                                                  ].conditions[
                                                    index
                                                  ].numberComparisionRange.min =
                                                    e.value;
                                                  setAllRules(newRules);
                                                }
                                              }}
                                            />{" "}
                                            <InputNumber
                                              useGrouping={false}
                                              minFractionDigits={
                                                condition.question.type ===
                                                "number"
                                                  ? undefined
                                                  : 2
                                              }
                                              maxFractionDigits={
                                                condition.question.type ===
                                                "number"
                                                  ? undefined
                                                  : 3
                                              }
                                              value={
                                                condition.numberComparisionRange
                                                  .max
                                              }
                                              className="col-span-1"
                                              id="condition-number-comparison-range"
                                              onChange={(e) => {
                                                if (
                                                  mainRules[groupIndex].rules
                                                    .length
                                                ) {
                                                  const newRules = [
                                                    ...mainRules,
                                                  ];
                                                  newRules[groupIndex].rules[
                                                    ruleIndex
                                                  ].conditions[
                                                    index
                                                  ].numberComparisionRange.max =
                                                    e.value;
                                                  setRules(newRules);
                                                } else {
                                                  const newRules = [
                                                    ...allRules,
                                                  ];
                                                  newRules[groupIndex].rules[
                                                    ruleIndex
                                                  ].conditions[
                                                    index
                                                  ].numberComparisionRange.max =
                                                    e.value;
                                                  setAllRules(newRules);
                                                }
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <InputNumber
                                            useGrouping={false}
                                            minFractionDigits={
                                              condition.question.type ===
                                              "number"
                                                ? undefined
                                                : 2
                                            }
                                            maxFractionDigits={
                                              condition.question.type ===
                                              "number"
                                                ? undefined
                                                : 3
                                            }
                                            value={condition.numberComparision}
                                            className="w-1/2"
                                            id="condition-number-comparison"
                                            onChange={(e) => {
                                              if (
                                                mainRules[groupIndex].rules
                                                  .length
                                              ) {
                                                const newRules = [...mainRules];
                                                newRules[groupIndex].rules[
                                                  ruleIndex
                                                ].conditions[
                                                  index
                                                ].numberComparision = e.value;
                                                setRules(newRules);
                                              } else {
                                                const newRules = [...allRules];
                                                newRules[groupIndex].rules[
                                                  ruleIndex
                                                ].conditions[
                                                  index
                                                ].numberComparision = e.value;
                                                setAllRules(newRules);
                                              }
                                            }}
                                          />
                                        )}
                                        {
                                          <div className="flex items-center gap-4">
                                            {allQuestions.find(
                                              (q) =>
                                                q.label ===
                                                condition?.question?.label,
                                            )?.constraints?.min && (
                                              <p className="flex items-center gap-1">
                                                <i className="pi pi-info-circle"></i>
                                                Min:{" "}
                                                {
                                                  allQuestions.find(
                                                    (q) =>
                                                      q.label ===
                                                      condition?.question
                                                        ?.label,
                                                  )?.constraints?.min
                                                }
                                              </p>
                                            )}
                                            {allQuestions.find(
                                              (q) =>
                                                q.label ===
                                                condition?.question?.label,
                                            )?.constraints?.max && (
                                              <p className="flex items-center gap-1">
                                                <i className="pi pi-info-circle"></i>
                                                Max:{" "}
                                                {
                                                  allQuestions.find(
                                                    (q) =>
                                                      q.label ===
                                                      condition?.question
                                                        ?.label,
                                                  )?.constraints?.max
                                                }
                                              </p>
                                            )}
                                          </div>
                                        }
                                      </>
                                    ) : (
                                      <Dropdown
                                        id={`answer${ruleIndex}`}
                                        disabled={!condition.question.label}
                                        value={condition.answer.label}
                                        options={condition.answerOptions}
                                        onChange={(e) => {
                                          if (
                                            mainRules[groupIndex].rules.length
                                          ) {
                                            const newr = [...mainRules];
                                            newr[groupIndex].rules[
                                              ruleIndex
                                            ].conditions[index].answer.label =
                                              e.value.label;
                                            setRules(newr);
                                          } else {
                                            const newr = [...allRules];
                                            newr[groupIndex].rules[
                                              ruleIndex
                                            ].conditions[index].answer.label =
                                              e.value.label;
                                            setAllRules(newr);
                                          }
                                        }}
                                        className="w-full rounded-none"
                                        placeholder={condition.answer.label}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-span-3 flex items-center gap-4 p-6 ">
                            {rule?.conditions?.length > 1 ? (
                              <Button
                                severity="danger"
                                icon="pi pi-trash color-red"
                                onClick={() =>
                                  handleDeleteRule(index, ruleIndex)
                                }
                              />
                            ) : null}
                            {index === rule?.conditions?.length - 1 ? (
                              <Button
                                icon={!isMobile ? "pi pi-plus" : false}
                                label={
                                  rule?.conditions?.length - 1
                                    ? ""
                                    : "Add new condition"
                                }
                                className="m-auto overflow-auto p-2 md:p-4"
                                onClick={() => handleAddCondition(ruleIndex)}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                    {contradictionErr ? (
                      <div className="mb-1 text-red-500">
                        Condition will be contradicting itself since another
                        answer has been assigned to the same question
                      </div>
                    ) : null}
                  </div>
                  {formErrors[groupIndex]?.conditions &&
                    formErrors[groupIndex].conditions[ruleIndex] && (
                      <p className="text-red-500 ">
                        {formErrors[groupIndex].conditions[ruleIndex]}
                      </p>
                    )}
                </div>
              </AccordionTab>
            );
          })}
        </Accordion>

        <Button
          className="my-2 self-end"
          label={"Add rule"}
          id="add-rule"
          onClick={() => {
            if (mainRules[groupIndex].rules.length) {
              const newRules = [...mainRules];
              newRules[groupIndex].rules.push({
                ...initialRule,
                name: `Rule-${newRules[groupIndex].rules.length + 1}`,
              });

              setRules(newRules);
            } else {
              const newRules = [...allRules];
              newRules[groupIndex].rules.push({
                ...initialRule,
                name: `Rule-${allRules.length + 1}`,
              });
              setAllRules(newRules);
            }
          }}
          icon="pi pi-plus"
        />
      </div>
      <div className="">
        <div className=" my-5 flex w-full justify-center">
          <div className="rounded-xl bg-light-purple p-2 text-center font-semibold text-white md:flex md:w-[80%] md:items-center md:justify-center">
            Apply This Action
            <Dropdown
              id={`action${groupIndex}`}
              value={groupRule.action}
              options={[
                { label: "Abort", value: "abort" },
                { label: "Add Billable items", value: "addCPT" },
                { label: "Add Documents", value: "addDocs" },
                { label: "Add locations", value: "addLocations" },
                {
                  label: "Add units to existing billable items",
                  value: "AddUnitsToExisting",
                },
                {
                  label: "Remove All Except",
                  value: "removeAllExcept",
                },
                {
                  label: "Remove Any Matching Pattern",
                  value: "removeMatchingPattern",
                },
                { label: "Remove Billable items", value: "removeCPT" },
                { label: "Remove Documents", value: "removeDocs" },
                { label: "Remove Locations", value: "removeLocations" },
                {
                  label: "Remove units from existing billable items",
                  value: "removeUnitsFromExisting",
                },
                {
                  label: "Replace billable item",
                  value: "swapCPT",
                },
              ]}
              onChange={(e) => {
                const cloneR = mainRules;
                cloneR[groupIndex].defaultUnitsChange = [];
                cloneR[groupIndex].billableItems = [];
                cloneR[groupIndex].documents = [];
                cloneR[groupIndex].billableItemstoRemoveFrom = [];
                cloneR[groupIndex].locations = [];

                mainRules[groupIndex].rules.length > 0
                  ? setRules(cloneR)
                  : setAllRules(cloneR);

                const newRules = mainRules[groupIndex].rules.length
                  ? [...mainRules]
                  : [...allRules];
                newRules[groupIndex] = {
                  ...newRules[groupIndex],
                  action: e.value,
                };

                mainRules[groupIndex].rules.length
                  ? setRules(newRules)
                  : setAllRules(newRules);
              }}
              className="mx-2 w-52"
            />
          </div>
        </div>

        <div className=" flex flex-col gap-3 rounded-xl border-slate-200 bg-white md:border  md:p-8">
          {formErrors[groupIndex]?.billableItems && (
            <p className="mt-1 text-red-500">
              {formErrors[groupIndex].billableItems}
            </p>
          )}
          {formErrors[groupIndex]?.documents && (
            <p className="mt-1 text-red-500">
              {formErrors[groupIndex].documents}
            </p>
          )}
          {formErrors[groupIndex]?.locations && (
            <p className="mt-1 text-red-500">
              {formErrors[groupIndex].locations}
            </p>
          )}
          {formErrors[groupIndex]?.abortMessage && (
            <p className="mt-1 text-red-500">
              {formErrors[groupIndex].abortMessage}
            </p>
          )}
          {formErrors[groupIndex]?.defaultUnitsChange && (
            <p className="mt-1 text-red-500">
              {formErrors[groupIndex].defaultUnitsChange}
            </p>
          )}
          <div className="mb-4 items-center">
            {groupRule.action == "abort" ? (
              <div className="flex flex-col gap-2">
                <div className="font-semibold">
                  Add Message to show on aborting
                </div>

                <Editor
                  value={
                    mainRules[groupIndex].rules.length
                      ? mainRules[groupIndex].abortMessage
                      : allRules[groupIndex].abortMessage
                  }
                  onTextChange={(e) => {
                    const newValue = e.htmlValue;
                    if (mainRules[groupIndex].rules.length) {
                      const newRules = [...mainRules];
                      newRules[groupIndex].abortMessage = newValue;
                      setRules(newRules);
                    } else {
                      const newRules = [...allRules];
                      newRules[groupIndex].abortMessage = newValue;
                      setAllRules(newRules);
                    }
                  }}
                  headerTemplate={editorHeaderTemplate}
                  id="abort-message"
                  className="w-full"
                  style={{ height: "164px" }}
                />
              </div>
            ) : null}
            {groupRule.action == "addCPT" ? (
              <div className="flex flex-col  gap-2">
                <label className="font-semibold">Current Billable Items</label>
                {groupRule.billableItems && (
                  <DataTable
                    className="rounded-lg border-2 border-gray-200"
                    emptyMessage={
                      <p className="text-center">
                        Currently, there are no billable items selected.
                      </p>
                    }
                    value={groupRule.billableItems}
                  >
                    {[
                      { name: "HCPCS", value: "hcpcs" },
                      {
                        name: "Description",
                        template: descriptionWithTooltipTemplate,
                      },
                      {
                        name: "Mod 1",
                        template: (e) => modTemplate(e, "mod1"),
                        header: "Mod 1",
                      },
                      {
                        name: "Mod 2",
                        template: (e) => modTemplate(e, "mod2"),
                        header: "Mod 2",
                      },
                      {
                        name: "Mod 3",
                        template: (e) => modTemplate(e, "mod3"),
                        header: "Mod 3",
                      },
                      {
                        name: "Mod 4",
                        template: (e) => modTemplate(e, "mod4"),
                        header: "Mod 4",
                      },
                      {
                        name: "Price per unit",
                        template: priceTemplate,
                        header: "Price per unit",
                      },
                      {
                        name: "Units",
                        value: "units",
                        header: "Units",
                      },
                      {
                        name: "Remove Billable items",
                        template: (e) =>
                          deleteTemplate(e, groupIndex, groupRule),
                        header: "Remove Billable items",
                      },
                    ].map((column) => (
                      <Column
                        key={column.name}
                        header={column.name}
                        align="center"
                        alignHeader="center"
                        style={column.style ? column.style : null}
                        body={(rowData) => {
                          if (column.name.toLowerCase().includes("date")) {
                            return (
                              rowData[column.value] &&
                              formatDate(rowData[column.value])
                            );
                          } else if (
                            column.template &&
                            typeof column.template === "function"
                          ) {
                            return column.template(rowData);
                          } else {
                            return rowData[column.value];
                          }
                        }}
                      />
                    ))}
                  </DataTable>
                )}
                <div>
                  <Button
                    className="mt-4"
                    label={
                      groupRule.billableItems.length
                        ? "Add and edit current billable items"
                        : "Add billable items"
                    }
                    onClick={() => {
                      setTableVisible(true);
                    }}
                    icon="pi pi-plus"
                  />
                </div>

                <Dialog
                  blockScroll
                  draggable={false}
                  className="md:w-[70vw]"
                  header="Attach Billable Items"
                  visible={tableVisible}
                  onHide={() => {
                    setNotSavedRules(mainRules);
                    setTableVisible(false);
                    setSearchValue("");
                  }}
                >
                  <div className="flex flex-col p-4">
                    <div className="flex flex-col gap-2">
                      <div className="overflow-hidden rounded-xl border border-gray-300">
                        <ReusableDataList
                          scrollable={true}
                          scrollHeight="264px"
                          emptyMessage={
                            searchValue ? (
                              <p className="text-center">
                                No results found. Alternatively, you can{" "}
                                <span
                                  className="cursor-pointer font-semibold text-light-purple underline"
                                  onClick={() => {
                                    setHcpcsSearchValue(searchValue);
                                    setBillableItemVisible(true);
                                    setTableVisible(false);
                                    setSearchValue("");
                                  }}
                                >
                                  Search in National HCPCS Codes
                                </span>
                              </p>
                            ) : (
                              <p className="text-center">
                                You haven't created any billable items yet. You
                                can create a new item by clicking on 'Create New
                                Billable Item'.
                              </p>
                            )
                          }
                          searchTitle="Search HCPCS, Description"
                          fetchMethod="put"
                          payload={getUsedBillableItems()}
                          dataLink={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
                          sendSearchData={handleInputChange}
                          searchValue={searchValue}
                          columns={[
                            { name: "HCPCS", value: "hcpcs" },
                            { name: "Description", value: "description" },
                          ]}
                          actionTemplates={[
                            {
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                            {
                              template: (e) =>
                                unitsTemplate(e, groupIndex, groupRule),
                              header: "Units",
                            },
                          ]}
                          selectionRowFunction={true}
                          selectedRows={mainRules[groupIndex].billableItems}
                          onSelectionChange={(rowData) => {
                            const newRules = notSavedRules[groupIndex].rules
                              .length
                              ? structuredClone([...notSavedRules])
                              : structuredClone([...allRules]);

                            const index = newRules[
                              groupIndex
                            ].billableItems.findIndex(
                              (obj) => obj.id === rowData.data.id,
                            );
                            if (index !== -1) {
                              newRules[groupIndex].billableItems.splice(
                                index,
                                1,
                              );
                            } else {
                              newRules[groupIndex].billableItems.push({
                                ...rowData.data,
                                units: rowData.data.defaultUnits,
                                error: "",
                              });
                            }
                            setNotSavedRules(newRules);
                          }}
                          refetch={refetch}
                        />
                      </div>
                    </div>
                    <Divider
                      layout="horizontal"
                      align="center"
                      className="w-10/12 self-center"
                    >
                      <b>OR</b>
                    </Divider>
                    <div className="flex justify-center ">
                      <Button
                        id="serviceBillables"
                        label="Create new billable item"
                        type="button"
                        outlined
                        onClick={() => {
                          setBillableItemVisible(true);
                          setHcpcsSearchValue("");
                          setTableVisible(false);
                          setSearchValue("");
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end p-4">
                    <Button
                      label="Save"
                      onClick={() => {
                        const hasErrors = notSavedRules[
                          groupIndex
                        ].billableItems.some(
                          (item) => item?.error && item.error !== "",
                        );
                        if (!hasErrors) {
                          mainRules.length
                            ? setRules(notSavedRules)
                            : setAllRules(notSavedRules);
                          setTableVisible(false);
                          setSearchValue("");
                        } else {
                          return;
                        }
                      }}
                    />
                  </div>
                </Dialog>
              </div>
            ) : null}
            {groupRule.action == "removeCPT" ? (
              <div className="flex flex-col gap-4">
                <DataTable
                  value={getAllBillableItems()}
                  className="rounded-lg border-2 border-gray-200"
                  emptyMessage={
                    <p className="text-center">
                      Currently, there are no billable items selected.
                    </p>
                  }
                >
                  {[
                    {
                      name: "Remove from Billable items",
                      template: (e) =>
                        deleteBillableTemplate(e, groupIndex, groupRule),
                      header: "Remove from Billable items",
                    },
                    { name: "HCPCS", value: "hcpcs" },
                    {
                      name: "Description",
                      template: descriptionWithTooltipTemplate,
                    },
                    {
                      name: "Mod 1",
                      template: (e) => modTemplate(e, "mod1"),
                      header: "Mod 1",
                    },
                    {
                      name: "Mod 2",
                      template: (e) => modTemplate(e, "mod2"),
                      header: "Mod 2",
                    },
                    {
                      name: "Mod 3",
                      template: (e) => modTemplate(e, "mod3"),
                      header: "Mod 3",
                    },
                    {
                      name: "Mod 4",
                      template: (e) => modTemplate(e, "mod4"),
                      header: "Mod 4",
                    },
                    {
                      name: "Price per unit",
                      template: priceTemplate,
                      header: "Price per unit",
                    },
                  ].map((column) => (
                    <Column
                      key={column.name}
                      header={column.name}
                      align="center"
                      alignHeader="center"
                      style={column.style ? column.style : null}
                      body={(rowData) => {
                        if (column.name.toLowerCase().includes("date")) {
                          return (
                            rowData[column.value] &&
                            formatDate(rowData[column.value])
                          );
                        } else if (
                          column.template &&
                          typeof column.template === "function"
                        ) {
                          return column.template(rowData);
                        } else {
                          return rowData[column.value];
                        }
                      }}
                    />
                  ))}
                </DataTable>
              </div>
            ) : null}
            {groupRule.action == "removeAllExcept" ? (
              <div className="flex flex-col gap-4">
                <div>
                  <DataTable
                    value={getAllBillableItems()}
                    className="rounded-lg border-2 border-gray-200"
                    emptyMessage={
                      <p className="text-center">
                        Currently, there are no billable items selected.
                      </p>
                    }
                  >
                    {[
                      {
                        name: "Billable Items To Keep",
                        template: (e) =>
                          removeAllExceptBillableItemsTemplate(
                            e,
                            groupIndex,
                            groupRule,
                          ),
                        header: "Billable Items To Keep",
                      },
                      { name: "HCPCS", value: "hcpcs" },
                      {
                        name: "Description",
                        template: descriptionWithTooltipTemplate,
                      },
                      {
                        name: "Mod 1",
                        template: (e) => modTemplate(e, "mod1"),
                        header: "Mod 1",
                      },
                      {
                        name: "Mod 2",
                        template: (e) => modTemplate(e, "mod2"),
                        header: "Mod 2",
                      },
                      {
                        name: "Mod 3",
                        template: (e) => modTemplate(e, "mod3"),
                        header: "Mod 3",
                      },
                      {
                        name: "Mod 4",
                        template: (e) => modTemplate(e, "mod4"),
                        header: "Mod 4",
                      },
                      {
                        name: "Price per unit",
                        template: priceTemplate,
                        header: "Price per unit",
                      },
                    ].map((column) => (
                      <Column
                        key={column.name}
                        header={column.name}
                        align="center"
                        alignHeader="center"
                        style={column.style ? column.style : null}
                        body={(rowData) => {
                          if (column.name.toLowerCase().includes("date")) {
                            return (
                              rowData[column.value] &&
                              formatDate(rowData[column.value])
                            );
                          } else if (
                            column.template &&
                            typeof column.template === "function"
                          ) {
                            return column.template(rowData);
                          } else {
                            return rowData[column.value];
                          }
                        }}
                      />
                    ))}
                  </DataTable>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="font-semibold">
                    Billable Items that will be removed
                  </div>
                  <DataTable
                    value={
                      groupRule.billableItems.length > 0
                        ? differenceByKey(
                            getAllBillableItems(),
                            groupRule.billableItems,
                            "id",
                          )
                        : []
                    }
                    className="rounded-lg border-2 border-gray-200"
                    emptyMessage={
                      <p className="text-center">
                        Currently, there are no billable items selected.
                      </p>
                    }
                  >
                    {[
                      { name: "HCPCS", value: "hcpcs" },
                      {
                        name: "Description",
                        template: descriptionWithTooltipTemplate,
                      },
                      {
                        name: "Mod 1",
                        template: (e) => modTemplate(e, "mod1"),
                        header: "Mod 1",
                      },
                      {
                        name: "Mod 2",
                        template: (e) => modTemplate(e, "mod2"),
                        header: "Mod 2",
                      },
                      {
                        name: "Mod 3",
                        template: (e) => modTemplate(e, "mod3"),
                        header: "Mod 3",
                      },
                      {
                        name: "Mod 4",
                        template: (e) => modTemplate(e, "mod4"),
                        header: "Mod 4",
                      },
                      {
                        name: "Price per unit",
                        template: priceTemplate,
                        header: "Price per unit",
                      },
                    ].map((column) => (
                      <Column
                        key={column.name}
                        header={column.name}
                        align="center"
                        alignHeader="center"
                        style={column.style ? column.style : null}
                        body={(rowData) => {
                          if (column.name.toLowerCase().includes("date")) {
                            return (
                              rowData[column.value] &&
                              formatDate(rowData[column.value])
                            );
                          } else if (
                            column.template &&
                            typeof column.template === "function"
                          ) {
                            return column.template(rowData);
                          } else {
                            return rowData[column.value];
                          }
                        }}
                      />
                    ))}
                  </DataTable>
                </div>
              </div>
            ) : null}
            {groupRule.action == "removeMatchingPattern" ? (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="removeMatchingPattern"
                    className="font-semibold"
                  >
                    Pattern to Match
                  </label>
                  <InputText
                    name="removeMatchingPattern"
                    id="removeMatchingPattern"
                    className="w-full md:w-1/2"
                    placeholder="Enter a valid pattern*"
                    onChange={(e) => {
                      const allItems = getAllBillableItems();
                      const matchingHcpcs = allItems.filter((item) =>
                        matchPattern(item.hcpcs, e.target.value),
                      );

                      const rulesClone = mainRules[groupIndex].rules.length
                        ? [...mainRules]
                        : [...allRules];

                      rulesClone[groupIndex].billableItems = matchingHcpcs;

                      rulesClone.length
                        ? setRules(rulesClone)
                        : setAllRules(rulesClone);
                    }}
                  />
                  <div className="rounded-lg border border-slate-100 p-4">
                    <p className="text-sm text-gray-500">
                      Valid patterns:
                      <div className="p-2">
                        <span className="font-medium">*:</span> any characters
                        <br /> <span className="font-medium">?:</span> a single
                        character
                        <br /> <span className="font-medium">#:</span> a single
                        digit
                        <br /> <span className="font-medium">[A-Z]:</span> any
                        character in range
                        <br /> <span className="font-medium">[!A-Z]:</span> any
                        character not in range
                      </div>
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="font-semibold">
                    Billable Items that will be removed
                  </div>
                  <DataTable
                    value={groupRule.billableItems}
                    className="rounded-lg border-2 border-gray-200"
                    emptyMessage={
                      <p className="text-center">No Matching HCPCS Code(s)</p>
                    }
                  >
                    {[
                      { name: "HCPCS", value: "hcpcs" },
                      {
                        name: "Description",
                        template: descriptionWithTooltipTemplate,
                      },
                      {
                        name: "Mod 1",
                        template: (e) => modTemplate(e, "mod1"),
                        header: "Mod 1",
                      },
                      {
                        name: "Mod 2",
                        template: (e) => modTemplate(e, "mod2"),
                        header: "Mod 2",
                      },
                      {
                        name: "Mod 3",
                        template: (e) => modTemplate(e, "mod3"),
                        header: "Mod 3",
                      },
                      {
                        name: "Mod 4",
                        template: (e) => modTemplate(e, "mod4"),
                        header: "Mod 4",
                      },
                      {
                        name: "Price per unit",
                        template: priceTemplate,
                        header: "Price per unit",
                      },
                    ].map((column) => (
                      <Column
                        key={column.name}
                        header={column.name}
                        align="center"
                        alignHeader="center"
                        style={column.style ? column.style : null}
                        body={(rowData) => {
                          if (column.name.toLowerCase().includes("date")) {
                            return (
                              rowData[column.value] &&
                              formatDate(rowData[column.value])
                            );
                          } else if (
                            column.template &&
                            typeof column.template === "function"
                          ) {
                            return column.template(rowData);
                          } else {
                            return rowData[column.value];
                          }
                        }}
                      />
                    ))}
                  </DataTable>
                </div>
              </div>
            ) : null}
            {groupRule.action == "addLocations" ||
            groupRule.action == "removeLocations" ? (
              <div className="flex flex-col gap-2">
                <label className="font-semibold">Filter Locations</label>
                <MultiSelect
                  value={mainLocation.filter((obj) =>
                    groupRule.locations.includes(obj.id),
                  )}
                  onChange={(e) => {
                    if (mainRules[groupIndex].rules.length > 0) {
                      const newRules = [...mainRules];
                      mainRules[groupIndex].locations = e.value.map(
                        (loc) => loc.id,
                      );
                      setRules(newRules);
                    } else {
                      const newRules = [...allRules];
                      mainRules[groupIndex].locations = e.value.map(
                        (loc) => loc.id,
                      );
                      setRules(newRules);
                    }
                  }}
                  itemTemplate={(option) => (
                    <div>
                      <h4 className="font-medium capitalize text-gray-500">
                        {option.name}
                      </h4>
                      <p className="font-normal capitalize text-gray-500">{`${
                        option.address.lineOne
                      }, ${
                        option.address.lineTwo
                          ? option.address.lineTwo + ","
                          : ""
                      } ${option.address.city}, ${option.address.state} ${
                        option.address.zipCode
                      }`}</p>
                    </div>
                  )}
                  options={mainLocation}
                  optionLabel="name"
                  id="select-locations"
                  filter
                  placeholder="Select Billable Items Locations"
                  className="md:w-20rem w-full"
                />
              </div>
            ) : null}
            {groupRule.action == "removeUnitsFromExisting" ||
            groupRule.action == "AddUnitsToExisting" ? (
              <>
                <DataTable value={getAllBillableItems(groupRule)}>
                  {[
                    { name: "HCPCS", value: "hcpcs" },
                    {
                      name: "Description",
                      template: descriptionWithTooltipTemplate,
                    },
                    {
                      name: "Mod 1",
                      template: (e) => modTemplate(e, "mod1"),
                      header: "Mod 1",
                    },
                    {
                      name: "Mod 2",
                      template: (e) => modTemplate(e, "mod2"),
                      header: "Mod 2",
                    },
                    {
                      name: "Mod 3",
                      template: (e) => modTemplate(e, "mod3"),
                      header: "Mod 3",
                    },
                    {
                      name: "Mod 4",
                      template: (e) => modTemplate(e, "mod4"),
                      header: "Mod 4",
                    },
                    {
                      name: "Price per unit",
                      template: priceTemplate,
                      header: "Price per unit",
                    },
                    { name: "Units", value: "units" },
                    {
                      name:
                        groupRule.action == "removeUnitsFromExisting"
                          ? "Decrement by"
                          : "Increment by",
                      template: (e) =>
                        changeExistingUnit(e, groupIndex, groupRule),
                      header:
                        groupRule.action == "removeUnitsFromExisting"
                          ? "Decrement by"
                          : "Increment by",
                    },
                  ].map((column) => (
                    <Column
                      key={column.name}
                      header={column.name}
                      align="center"
                      alignHeader="center"
                      style={column.style ? column.style : null}
                      body={(rowData) => {
                        if (column.name.toLowerCase().includes("date")) {
                          return (
                            rowData[column.value] &&
                            formatDate(rowData[column.value])
                          );
                        } else if (
                          column.template &&
                          typeof column.template === "function"
                        ) {
                          return column.template(rowData);
                        } else {
                          return rowData[column.value];
                        }
                      }}
                    />
                  ))}
                </DataTable>
              </>
            ) : null}
            {groupRule.action == "swapCPT" ? (
              <div className="flex flex-col gap-3">
                <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
                  <i className="pi pi-minus text-red-500"></i>
                  Billable Item To Remove
                </p>
                <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
                  <SwappedBillableTable value={groupRule.billableItems[0]} />
                  <Button
                    className="self-end"
                    label="Choose a billable item to remove"
                    onClick={() => setBillableToSwapDialogVisible(true)}
                  />{" "}
                </div>
                <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
                  <i className="pi pi-plus text-green-500"></i>
                  Billable Item To Add
                </p>
                <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
                  <SwappedBillableTable value={groupRule.billableItems[1]} />
                  <Button
                    className="self-end"
                    label="Choose a billable item to add"
                    onClick={() => setSwappedBillableDialogVisible(true)}
                  />
                </div>
              </div>
            ) : null}
            {groupRule.action == "addDocs" ||
            groupRule.action == "removeDocs" ? (
              <div>
                <SelectDocuments
                  action={groupRule.action}
                  getAllServiceDocs={getAllServiceDocs}
                  serviceDocumentsIds={serviceDocumentsIds}
                  selectedDocs={groupRule.documents}
                  handleSaveSelection={(unsavedSelectedDocs) => {
                    const newRules = mainRules.length
                      ? [...mainRules]
                      : [...allRules];

                    newRules[groupIndex].documents = unsavedSelectedDocs.map(
                      (doc) => doc.id,
                    );

                    mainRules.length
                      ? setRules(newRules)
                      : setAllRules(newRules);
                  }}
                  handleAddNew={(id) => {
                    const newRules = mainRules.length
                      ? [...mainRules]
                      : [...allRules];

                    newRules[groupIndex].documents.push(id);

                    mainRules.length
                      ? setRules(newRules)
                      : setAllRules(newRules);
                  }}
                  handleRemove={(rowData) => {
                    const newRules = mainRules.length
                      ? [...mainRules]
                      : [...allRules];

                    newRules[groupIndex].documents = newRules[
                      groupIndex
                    ].documents.filter((doc) => doc !== rowData.id);

                    mainRules.length
                      ? setRules(newRules)
                      : setAllRules(newRules);
                  }}
                />
                {/* <pre>{JSON.stringify(mainRules, null, 2)}</pre> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupForm;
