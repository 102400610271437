import React, { useRef } from "react";

import { useNavigate } from "react-router-dom";

import { Avatar } from "primereact/avatar";

import useAxios from "../../hooks/useAxios";
import { formatDate } from "../utility/Utils";
import useTokenData from "../../hooks/useTokenData";
import ServicesIcon from "../../iconComponents/ServicesIcon";

function FavoritePracticeCard({
  practiceName,
  practiceImage,
  practiceSince,
  practiceDesc,
  practiceLocation,
  practiceState,
  practiceServicesNum,
  refetchData,
  practiceId,
  isFavoritePractice,
  toastEnableSuccess,
  toastDisableSuccess,
  toastError,
}) {
  const { http } = useAxios();
  const navigate = useNavigate();
  const { entityId } = useTokenData();

  const favouritePracticeEnable = async () => {
    try {
      const res = await http.post("/Entities/AddFavouritePractice", {
        entityId: entityId,
        favouritePracticeId: practiceId,
      });
      refetchData();
      toastEnableSuccess();
    } catch (error) {
      toastError(error);
    }
  };

  const favouritePracticeDisable = async () => {
    try {
      const res = await http.delete(
        `/Entities/RemoveFavouritePractice/${entityId}/${practiceId}`,
      );

      refetchData();
      toastDisableSuccess();
    } catch {
      toastError();
    }
  };

  return (
    <div
      className="mb-2 cursor-pointer bg-white p-4"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/ordering/practices/${practiceId}`);
      }}
    >
      <div className="flex justify-between">
        <div className="mb-4 flex items-center">
          <div>
            {practiceImage ? (
              <img
                className="mr-2 h-[30px] w-[30px] rounded-full"
                src={practiceImage}
                alt=""
              />
            ) : (
              <Avatar
                label={practiceName[0]}
                shape="circle"
                className="mr-2 bg-blue-500 text-white"
              />
            )}
          </div>
          <div>
            <div>{practiceName}</div>
            <div className="text-sm font-light">
              Member Since {formatDate(practiceSince)}
            </div>
          </div>
        </div>
        <div>
          <i
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (isFavoritePractice) {
                favouritePracticeDisable();
              } else {
                favouritePracticeEnable();
              }
            }}
            className={`pi cursor-pointer text-purple-700 ${
              isFavoritePractice ? "pi-star-fill" : "pi-star"
            } `}
          />
        </div>
      </div>
      <div>{practiceDesc}</div>
      <div className="flex flex-col gap-2 sm:flex-row">
        <span>
          <i className="pi pi-map-marker mr-2" />
          {`${practiceLocation.lineOne} ${practiceLocation.city}`}
        </span>
        <span>
          <i className="pi pi-map mr-2" />
          {practiceLocation.state}
        </span>
        <span>
          <ServicesIcon />
          {practiceServicesNum}
          {practiceServicesNum > 1 ? " Services" : " Service"}
        </span>
      </div>
    </div>
  );
}

export default FavoritePracticeCard;
