import { Fieldset } from "primereact/fieldset";
import React from "react";

const BankAccInfo = ({ bankAccData }) => {
  return bankAccData?.map((data) => (
    <Fieldset
      key={data.id}
      className="mt-4 font-inter"
      legend={data?.isDefault ? "Default Card" : undefined}
      pt={{
        legend: {
          style: { background: "#2196F3", color: "white", marginLeft: "-.2%" },
        },
      }}
    >
      <div className="flex flex-col gap-3 pr-20">
        <p>
          <span className="font-semibold">Bank Name: </span>
          {data?.bankName}
        </p>
        <p>
          <span className="font-semibold">Account Owner Name: </span>
          {data?.accountOwnerName}
        </p>
        <p>
          <span className="font-semibold">Bank Account Number: </span>
          {data?.bankAccountNumber}
        </p>
        <p>
          <span className="font-semibold">Routing Number: </span>
          {data?.routingNumber}
        </p>
      </div>
    </Fieldset>
  ));
};

export default BankAccInfo;
