import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { validatePassword } from "../utility/Utils";
import useTokenData from "../../hooks/useTokenData";
import useAxios from "../../hooks/useAxios";
import { Toast } from "primereact/toast";
import {
  useRefreshTokenStore,
  useSwitchOrdering,
  useSystemAdmin,
  useTokenStore,
} from "../../store";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, Link, useLocation } from "react-router-dom";
function DialogCreatePin({
  fromTap,
  setIsLoading,
  isLoading,
  showDialogCreatePin,
  setShowDialogCreatePin = () => {},
}) {
  const { http } = useAxios();
  const location = useLocation();
  const toast = useRef();
  const navigate = useNavigate();
  const { token, setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  // const [isLoading, setIsLoading] = useState(false);
  const { isOrderingMode } = useSwitchOrdering();
  const [pinEye, setPinEye] = useState(false);
  const [confirmPinEye, setConfirmPinEye] = useState(false);
  const handlePinClick = () => setPinEye(!pinEye);
  const handleConfirmPinClick = () => setConfirmPinEye(!confirmPinEye);
  const { userId } = useTokenData();
  const { IsAdmin } = useSystemAdmin();
  const [oldPasswordEye, setOldPasswordEye] = useState(false);
  const handleOldPasswordClick = () => {
    setOldPasswordEye(!oldPasswordEye);
  };
  const currentRefreshToken = JSON.parse(localStorage.getItem("refreshToken"))
    .state.refreshToken;
  const defaultValues = {
    // userId: userId,
    pinCode: "",
    confirmPinCode: "",
    refreshToken: currentRefreshToken,
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      const res = await http.put(`/Users/SetUserPinCode`, formData);
      setToken(res.data.token);
      setRefreshToken(res.data.refreshToken);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 4000,
      });
      reset();
    } catch (error) {
      console.error("Error creating order:", error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 4000,
      });
    } finally {
      setIsLoading(false);
      if (fromTap) {
        const path = isOrderingMode
          ? "/ordering/settings/"
          : "/rendering/settings/";
        navigate(path);
      } else {
        setShowDialogCreatePin(false);
      }
    }
  };
  const handleCancel = () => {
    const path = isOrderingMode
      ? "/ordering/settings/"
      : "/rendering/settings/";
    navigate(path);
  };

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Set PIN",
      template: () => <Link to="#">Set PIN</Link>,
    },
  ];

  const shouldHideBreadcrumb =
    location.pathname.includes("ordering/RequestService") ||
    location.pathname.includes("ordering/Orders");

  return (
    <>
      <Toast ref={toast} />
      {!shouldHideBreadcrumb && (
        <BreadCrumb
          model={breadCrumbsItems}
          className="border-0 bg-transparent pb-7 font-semibold"
          pt={{
            separator: { className: "first:hidden" },
            menuitem: { className: "last:text-purple-700" },
          }}
        />
      )}
      <form
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
        id="createPinForm"
      >
        <div className="flex w-full items-center justify-center py-4 sm:px-8">
          <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
            <div>
              <h4 className="mb-4 text-center text-3xl font-bold sm:text-left">
                Set Provider Authentication PIN
              </h4>
              <p className="mb-3 text-center text-gray-700 sm:text-left">
                Set your PIN to ensure only you can place and accept orders,
                even if a staff member initiates them
              </p>
            </div>

            {/* <div className="pt-4">
              <label className="mb-2 block capitalize">
                current password <span className="text-red-600">*</span>
              </label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Current password is required",
                  validate: validatePassword,
                }}
                render={({ field }) => (
                  <>
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="Current Password"
                        className="w-full pb-2 pt-2"
                        name="password"
                        type={!oldPasswordEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      {!oldPasswordEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handleOldPasswordClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handleOldPasswordClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.password && (
                <p className="mt-1 text-red-500">{errors.password.message}</p>
              )}
            </div> */}

            <div className="pt-7">
              <label className="block pb-2 text-base capitalize leading-loose">
                New PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="pinCode"
                control={control}
                rules={{
                  required: "PIN field is required",
                  pattern: {
                    value: /^\d{4,12}$/,
                    message: "PIN must be between 4 and 12 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <span
                      className={`p-input-icon-left p-input-icon-right ${fromTap ? "w-6/12" : "w-full"}`}
                    >
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="New PIN"
                        maxLength={12}
                        keyfilter="num"
                        className="w-full pb-2 pt-2"
                        name="pinCode"
                        disabled={IsAdmin}
                        type={!pinEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("confirmPinCode");
                        }}
                      />
                      {!pinEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handlePinClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handlePinClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.pinCode && (
                <p className="text-red-500">{errors.pinCode.message}</p>
              )}
            </div>
            <div className="pt-4">
              <label className="block pb-2 text-base capitalize leading-loose">
                Confirm PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="confirmPinCode"
                control={control}
                rules={{
                  validate: (value) => {
                    return value === getValues("pinCode")
                      ? true
                      : "PIN does not match";
                  },
                }}
                render={({ field }) => (
                  <span
                    className={`p-input-icon-left p-input-icon-right ${fromTap ? "w-6/12" : "w-full"}`}
                  >
                    <i className="pi pi-lock" />
                    <InputText
                      placeholder="Confirm PIN"
                      maxLength={12}
                      className="w-full pb-2 pt-2"
                      name="confirmPinCode"
                      disabled={IsAdmin}
                      keyfilter="num"
                      type={!confirmPinEye ? "password" : "text"}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger("confirmPinCode");
                      }}
                    />
                    {!confirmPinEye ? (
                      <i
                        className="pi pi-eye-slash cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    ) : (
                      <i
                        className="pi pi-eye cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    )}
                  </span>
                )}
              />

              {errors.confirmPinCode && (
                <p className="text-red-500">{errors.confirmPinCode.message}</p>
              )}
            </div>
            {fromTap && (
              <div className="mt-7 flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
                <div className="w-full md:w-auto">
                  <Button
                    onClick={handleCancel}
                    disabled={IsAdmin}
                    type="button"
                    className="mt-2 w-full justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple md:mt-9 md:w-auto"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-full items-end justify-end md:w-auto">
                  <Button
                    loading={isLoading}
                    disabled={IsAdmin}
                    type="submit"
                    className="mt-9 w-full justify-center rounded-full bg-light-purple px-7 py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                  >
                    set PIN
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default DialogCreatePin;
