import { TabPanel, TabView } from "primereact/tabview";
import ManageServicesTemplate from "./ManageServicesTemplate";
import ManageQuestionsTemplate from "./ManageQuestionsTable";
import { useLocation } from "react-router-dom";
function ManageServices() {
  const location = useLocation();
  const activeIndex = location.state?.activeIndex;
  return (
    <div className="card">
      <div className="rounded-lg bg-white p-8">
        <ManageServicesTemplate />
      </div>
      {/* <TabView activeIndex={activeIndex || 0}>
        <TabPanel header="Questions">
        <ManageQuestionsTemplate />
        </TabPanel>
        <TabPanel header="Services">
        <ManageServicesTemplate />
        </TabPanel>
      </TabView> */}
    </div>
  );
}

export default ManageServices;
