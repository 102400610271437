import React, { useRef, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import {
  downloadFileFromResponse,
  formatDate,
  formatDateTime,
  RENDERING_DROPDOWN_OPTIONS,
} from "../utility/Utils";
import useDelayedInputChange from "../../hooks/useDelayedInputChange";
import ReusableDataList from "../ReusableDataList/ReusableDataList";
import useHandleResize from "../../hooks/useHandleResize";
import { RadioButton } from "primereact/radiobutton";
import PatientInfoTemplate from "./PatientInfoTemplate";

function ArchivedOrders() {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const { userId, entityId } = useTokenData();
  const toast = useRef();
  const navigate = useNavigate();
  const { http } = useAxios();
  const [refetch, setRefetch] = useState();
  const menuRight = useRef({});

  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );
  const { isMobile } = useHandleResize();

  const unArchiveOrder = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to unarchive the order?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        await http.put(`/Orders/UnArchieveOrder/${rowData.id}`);
        setRefetch(() => !refetch);
      },
    });
  };

  const handleScroll = () => {
    setVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status == 10 ? (
          <div className="inline rounded-md bg-[#FDBA74] p-2 text-center text-sm text-orange-900 sm:block sm:p-3 sm:text-base">
            Recalled
          </div>
        ) : rowData.status == 11 ? (
          <div
            id={`${rowData.id}C`}
            className="inline rounded-md bg-red-100 p-2 text-center text-sm text-red-500 sm:block sm:p-3 sm:text-base"
          >
            Canceled
          </div>
        ) : rowData.status == 12 ? (
          <div
            id={`${rowData.id}R`}
            className="inline rounded-md bg-green-100 p-2 text-center text-sm text-green-600 sm:block sm:p-3 sm:text-base"
          >
            Resulted
          </div>
        ) : rowData.status == 13 ? (
          <div
            id={`${rowData.id}R`}
            className="inline rounded-md bg-blue-100 p-2 text-center text-sm text-blue-600 sm:block sm:p-3 sm:text-base"
          >
            Completed
          </div>
        ) : null}
      </>
    );
  };

  const downloadPatientInfo = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    }
  };

  const downloadPatientAttachments = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadPatientAttachmentsByOrderId/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
    }
  };

  const orderInfo = async (rowData) => {
    navigate(`/rendering/Orders/${rowData.id}`, {
      state: {
        orderNumber: rowData.orderTrackingNumber,
        serviceName: rowData.serviceName,
      },
    });
  };
  const downloadReceipt = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadRendringRecieptSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message || "File download successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };
  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> $ {rowData.charge} </h4>
    </>
  );

  const getMenuItems = (rowData) => {
    const items = [];
    items.push({
      label: "Unarchive",
      icon: "pi pi-inbox",
      command: () => unArchiveOrder(rowData),
    });
    if (!rowData.status === 10) {
      items.push({
        label: "Download Order Summary",
        icon: "pi pi-download",
        command: () => downloadPatientInfo(rowData),
      });
    }
    if (rowData.hasAttachmentFiles && !rowData.status === 10) {
      items.push({
        label: "Download Patient Attachments",
        icon: "pi pi-download",
        command: () => downloadPatientAttachments(rowData),
      });
    }

    items.push({
      label: "Order Info",
      icon: "pi pi-info-circle",
      command: () => orderInfo(rowData),
    });
    items.push({
      label: "Download receipt Summary",
      icon: "pi pi-download",
      command: () => downloadReceipt(rowData),
    });
    return items;
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const dropdownValues = {
    options: RENDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="font-semibold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            Patient Info:&nbsp;
          </span>
          <PatientInfoTemplate rowData={row} />
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            Scheduled Date:&nbsp;
          </span>
          {row.scheduledDate && (
            <>
              <span>{formatDate(row.scheduledDate)}&nbsp;</span>
              <span>{formatDateTime(row.scheduledDate)}</span>
            </>
          )}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user-minus" />
          <span className="font-semibold text-slate-500">
            Archived By:&nbsp;
          </span>
          {row.archeivedBy}
        </p>
        <div className="flex justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="font-semibold text-slate-500">Status:&nbsp;</span>

            {statusTemplate(row)}
          </div>
          {actionTemplate(row)}
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option}
                  onChange={(e) => dropdownValues?.setSelectedValue(e.value)}
                  checked={dropdownValues.selectedValue === option.name}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <ReusableDataList
        title={"List of orders"}
        sendSearchData={handleInputChange}
        dropdownValues={dropdownValues}
        searchValue={searchValue}
        dataLink={`/Orders/GetOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==10|11|12,archievedAt%21%3Dnull`}
        columns={[
          {
            name: "Order Number",
            value: "orderTrackingNumber",
            style: { width: "8%" },
          },
          { name: "Service", value: "serviceName", style: { width: "13%" } },
          {
            name: "Charge",
            value: "charge",
            template: chargeTemplate,
            style: { width: "10%" },
          },
          { name: "Ordered By", value: "orderedBy", style: { width: "9%" } },
          { name: "Date", value: "orderedDate", style: { width: "10%" } },
          {
            name: "Patient Info",
            value: "patientName",
            template: (rowData) => <PatientInfoTemplate rowData={rowData} />,
            style: { width: "10%" },
          },
          {
            name: "Scheduled Date",
            value: "scheduledDate",
            style: { width: "10%" },
          },
          {
            name: "Archived By",
            value: "archievedBy",
            style: { width: "10%" },
          },
        ]}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [
                {
                  template: statusTemplate,
                  header: "Status",
                  style: { width: "13%" },
                },
                { template: actionTemplate, header: "Actions" },
              ]
        }
        filterTemplate={filterTemplate}
        refetch={refetch}
      />
    </>
  );
}

export default ArchivedOrders;
