import { useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import useAxios from "../../hooks/useAxios";
import { InputMask } from "primereact/inputmask";

export const ContactUsForm = () => {
  const toast = useRef();
  const { http } = useAxios();
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false);
  const defaultValues = { name: "", email: "", phoneNumber: "", message: "" };
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues: defaultValues });

  const getErrorMessage = (fieldName) => {
    return (
      errors[fieldName]?.message && (
        <span className="text-sm text-pink-200">
          {errors[fieldName]?.message}
        </span>
      )
    );
  };

  const onSubmit = async (formData) => {
    try {
      await http.post("/Users/SendContactForm", formData);
      setIsSentSuccessfully(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
      <Toast ref={toast} />
      {isSentSuccessfully ? (
        <Message
          className="w-full p-5"
          severity="success"
          text="Thank you! Your submission has been received!"
        />
      ) : (
        <div className="flex flex-col gap-4 font-inter">
          <div>
            <label className="font-Poppins text-base font-normal text-white">
              Name <span className="text-pink-200">*</span>
            </label>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
              }}
              render={({ field }) => (
                <InputText
                  placeholder="Enter your name"
                  className="w-full"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              )}
            />
            {getErrorMessage("name")}
          </div>
          <div>
            <label className="font-Poppins text-base font-normal text-white">
              Email <span className="text-pink-200">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  type="email"
                  placeholder="Enter your email"
                  className="w-full"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              )}
              rules={{
                required: "Email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
            {getErrorMessage("email")}
          </div>

          <div>
            <label className="font-Poppins text-base font-normal text-white">
              Phone Number
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                pattern: {
                  value: /^\(\d{3}\) \d{3}-\d{4}$/,
                  message: "Invalid phone format",
                },
              }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  autoClear={false}
                  className="w-full"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                />
              )}
            />
            {getErrorMessage("phoneNumber")}
          </div>
          <div>
            <label className="font-Poppins text-base font-normal text-white">
              Your Message
            </label>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  placeholder="Enter your message"
                  className="w-full"
                />
              )}
            />
          </div>
          <div className="mt-7 flex flex-col items-center justify-center gap-5">
            <Button
              severity="info"
              className="w-full"
              loading={isSubmitting}
              label={isSubmitting ? "Sending..." : "Send"}
            />
          </div>
        </div>
      )}
    </form>
  );
};
