import { Skeleton } from "primereact/skeleton";
import React from "react";

export const LocationCardSkeleton = () => {
  return (
    <div className='p-5 bg-white mb-2 border border-rounded'>
      <Skeleton className='p-4 mb-4' width='30%' />
      <div className='flex w-[100%] items-center'>
        <Skeleton size='25px' shape='circle' className='p-2 mr-4' />
        <Skeleton className='p-3' width='20%' />
      </div>
    </div>
  );
};
