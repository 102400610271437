import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import { VerificationQueuePractice } from "./VerificationQueuePractice";
import { blockSpecialCharsPattern } from "../../utility/Utils";

export const ProvidersUpdatesRequests = () => {
  const [searchValue, setSearchValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="rounded-t-lg">
      {/* Header & Search */}
      <div className="flex-col border-b-2 bg-[#f8f9fa] p-5 sm:flex sm:flex-row sm:items-center sm:justify-between">
        <p className="py-1 font-inter font-semibold sm:py-5">
          Provider Re-Validation Requests
        </p>
        <div className="p-input-icon-left w-full sm:w-auto">
          <i className="pi pi-search " />
          <InputText
            keyfilter={blockSpecialCharsPattern}
            placeholder="Search Provider"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="w-full"
          />
        </div>
      </div>

      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setSearchValue("");
          setActiveIndex(e.index);
        }}
      >
        <TabPanel header="Practice" className="flex-1 sm:flex-initial">
          <VerificationQueuePractice
            url={
              searchValue
                ? "/DueDiligence/GetAllDueDiligenceQueue?Filters=EntityType==1,IsReDueDiligence==true,(Identification.Entity.Name|Identification.ApplicationUser.FirstName|Identification.ApplicationUser.LastName)@=*"
                : "/DueDiligence/GetAllDueDiligenceQueue?Filters=EntityType==1,IsReDueDiligence==true"
            }
            tag="practice"
            searchValue={searchValue}
          />
        </TabPanel>
        <TabPanel header="Individual" className="flex-1 sm:flex-initial">
          <VerificationQueuePractice
            url={
              searchValue
                ? "/DueDiligence/GetAllDueDiligenceQueue?Filters=EntityType==2,IsReDueDiligence==true,(Identification.Entity.Name|Identification.ApplicationUser.FirstName|Identification.ApplicationUser.LastName)@=*"
                : "/DueDiligence/GetAllDueDiligenceQueue?Filters=EntityType==2,IsReDueDiligence==true"
            }
            searchValue={searchValue}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};
