import SuccessIcon from "../../../iconComponents/SuccessIcon"

function OrderDenied() {
    return (
        <div className="flex h-full w-full items-center justify-center">
            <div className="flex w-10/12 max-w-2xl flex-col items-center gap-8 rounded-2xl border border-light-purple bg-white p-6 md:w-8/12">
                <>
                    <div className="w-11/12 max-w-md p-2">
                        <SuccessIcon />
                    </div>
                    <div className="flex flex-col gap-3">
                        <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple ">
                            Order denied Successfully
                        </h4>
                        <p className="text-center font-bold capitalize text-gray-600">
                            Thank you for choosing MedX
                        </p>
                    </div>
                </>
            </div>
        </div>
    )
}

export default OrderDenied