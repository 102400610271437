export default function ServiceChecklist({ formData }) {
  const serviceChecklistItems = [
    {
      label: "Add service name",
      completed: formData?.generalInfo?.name,
      required: true,
    },
    {
      label: "Add service description",
      completed: formData?.generalInfo?.description,
      required: true,
    },
    {
      label: "Choose service location(s)",
      completed: formData?.generalInfo?.locations?.length,
      required: true,
    },
    {
      label: "Add billable item(s)",
      completed: formData?.generalInfo?.billables?.length,
      required: true,
    },
    {
      label: "Add service document(s)",
      completed: formData?.generalInfo?.serviceDocumentsIds?.length,
      required: false,
    },
    {
      label: "Add questions",
      completed: formData?.questions?.length > 2,
      required: false,
    },
    {
      label: "Add service rules",
      completed: formData?.services?.length,
      required: false,
    },
  ];
  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 rounded-xl  bg-white  p-3">
        {serviceChecklistItems.map((item) => (
          <div
            key={item.label}
            className={`flex justify-between gap-1 rounded-lg  p-3  hover:bg-slate-50 ${item.completed ? " bg-slate-50 shadow-md" : "shadow-sm "}`}
          >
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-500">
                {item.label}
              </p>
              <p className="text-xs italic text-gray-400">
                {item.required ? "Required" : "(Optional)"}
              </p>
            </div>
            <div>
              {item.completed ? (
                <i className="pi pi-check-circle mr-2 text-lg font-bold text-green-500"></i>
              ) : (
                <i className="pi pi-circle mr-2 text-lg font-bold text-gray-400"></i>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
