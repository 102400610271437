import { Skeleton } from "primereact/skeleton";
function OrderSuceessSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
    </div>
  );
}

export default OrderSuceessSkeleton;
