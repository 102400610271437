import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { formatDate, formatDateTime } from "../utility/Utils";

export default function ExpandedRow({ data }) {
  const dateTemplate = (rowData) => {
    return (
      <>
        <div className="flex flex-col ">
          <p className="text-center">
            {rowData.createdAt && formatDate(rowData.createdAt)}
          </p>
          <p className="mt-1 rounded-md bg-sky-600 text-center font-inter text-sm text-white">
            {rowData.createdAt && formatDateTime(rowData.createdAt)}
          </p>
        </div>
      </>
    );
  };

  return (
    <div className="p-3">
      <DataTable
        value={data}
        pt={{
          loadingOverlay: {
            style: {
              background: "#C6C1FF40",
            },
          },
        }}
        loading={!!!data}
      >
        <Column field="transactionId" header="Transaction" />
        <Column field="type" header="Transaction Type" />
        <Column field="notes" header="Notes" />
        <Column
          field="createdAt"
          header="Transaction Date"
          body={dateTemplate}
        />
      </DataTable>
    </div>
  );
}
