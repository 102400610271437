import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";
import { Dialog } from "primereact/dialog";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import ReusableFilter from "../ReusableFilter/ReusableFilter";
import useHandleResize from "../../hooks/useHandleResize";
import ReusableDataList from "../ReusableDataList/ReusableDataList";
import ReusableCardList from "../ReusableCardList/ReusableCardList";
import { formatDate } from "../utility/Utils";
import { Checkbox } from "primereact/checkbox";
import VerifyProvider from "../settings/VerifyProvider";
import {
  useJoinRequest,
  useStoreEntityIdFromUrl,
  useSwitchOrdering,
  useTokenStore,
} from "../../store";

import { FilterMatchMode } from "primereact/api";

// CONSTANTS
const Roles = [
  { label: "Staff", value: 4 },
  { label: "Admin", value: 3 },
];

function StaffDashboard() {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOrderingMode } = useSwitchOrdering();
  // get Id from url
  const location = useLocation();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { userId, entityId, entityType } = useTokenData();

  const { http } = useAxios();

  //token method
  const toast = useRef(null);
  const menuRight = useRef({});
  const navigate = useNavigate();
  const { token } = useTokenStore();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //************all const*************
  const [users, setUsers] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  const { setJoinRequestId, joinRequestId } = useJoinRequest();
  const [refetchData, setRefetchData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [tags, setTags] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { isMobile } = useHandleResize();
  const [loadingSendInvitation, setLoadingSendInvitation] = useState(false);
  const [loadingResendInvitation, setLoadingResendInvitation] = useState({});
  const [providersData, setProvidersData] = useState();
  const [providerFormDialog, setProviderFormDialog] = useState();

  const handleScroll = () => {
    setVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  useEffect(() => {
    if (entityId) {
      fetchStaff();
      setRefetchData(!refetchData);
      setEntityId(entityId);
      fetchProviders();
    }
  }, []);

  useEffect(() => {
    if (entityId && activeIndex === 0 && entityType == 1) {
      fetchUsers();
    }
    if (entityId && activeIndex === 2) {
      fetchStaff();
      setRefetchData(!refetchData);
    }
    if (entityId && activeIndex === 3) {
      fetchProviders();
    }
  }, [activeIndex]);

  useEffect(() => {
    entityType != 1 && setActiveIndex(1);
  }, []);

  // ***************search ************
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //*********** create invite staff form *******************
  const [inputTag, setInputTag] = useState({
    staffEmail: "",
    staffRole: Roles[0].value,
    isProvider: false,
  });

  const handleButtonClick = async () => {
    let allTags = tags;

    if (inputTag.staffEmail.length) {
      validateEmail(inputTag.staffEmail) && allTags.push(inputTag);
    }
    setLoadingSendInvitation(true);
    if (allTags.length === 0) {
      setErrorMessage("Email is required");
      setLoadingSendInvitation(false);
      return;
    } else if (allTags.length > 0) {
      const invalidTags = allTags.filter(
        (tag) => !validateFormat(tag.staffEmail),
      );
      if (invalidTags.length > 0) {
        setErrorMessage("Invalid email format");
        setLoadingSendInvitation(false);
        return;
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
    try {
      setLoadingSendInvitation(true);
      const response = await http.post(`/Entities/InviteStaff/${entityId}`, {
        invitations: allTags,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message || "Invitation Sent Successfully",
        life: 4000,
      });
      setRefetchData(!refetchData);
    } catch (error) {
      if (Array.isArray(error?.response?.data)) {
        error.response.data.forEach((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: 4000,
          });
        });
      } else if (typeof error?.response?.data === "object") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      } else {
        console.error(error.response.data);
      }
    }
    setTags([]);
    setInputTag({ ...inputTag, staffEmail: "", isProvider: false });
    setLoadingSendInvitation(false);
  };

  const validateFormat = (tag) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(tag);
  };
  const validateEmail = (email) => {
    if (!email.length) {
      setErrorMessage("Email field is empty.");
      return false;
    } else if (!validateFormat(email)) {
      setErrorMessage("This email is invalid.");
      return false;
    } else if (tags.length && tags.some((tag) => tag.staffEmail === email)) {
      setErrorMessage("This email is already added.");
      return false;
    }
    return true;
  };
  const addNewTag = () => {
    const email = inputTag.staffEmail.trim();

    if (validateEmail(email)) {
      setErrorMessage("");
      setInputTag({ ...inputTag, staffEmail: "", isProvider: false });
      setTags([...tags, inputTag]);
    }
  };

  //*************** join request ************
  const joinColumns = [
    { value: "userName", name: "Name", style: { width: "15%" } },
    { value: "userEmail", name: "Email", style: { width: "25%" } },
    { value: "requestDate", name: "Date", style: { width: "15%" } },
  ];

  const statusTemplate = (rowData) => {
    if (rowData.status === 1) {
      return (
        <div className="inline rounded-md bg-orange-100 p-2 text-center text-sm font-bold text-orange-600 sm:block sm:p-3 sm:text-base">
          Pending
        </div>
      );
    } else if (rowData.status === 2) {
      return (
        <div className="inline rounded-md bg-green-100 p-2 text-center text-sm font-bold text-green-600 sm:block sm:p-3 sm:text-base">
          Approved
        </div>
      );
    } else {
      return (
        <div className="inline rounded-md bg-red-100 p-2 text-center text-sm font-bold text-red-600 sm:block sm:p-3 sm:text-base">
          Declined
        </div>
      );
    }
  };

  //get data to the first table join request
  const fetchUsers = async () => {
    try {
      const response = await http.get(
        `JoiningRequests/GetJoiningRequestsByEntityId/${entityId}`,
      );
      const data = response.data;
      setUsers(data);
      const ids = data.map((item) => item.id);
      setJoinRequestId(ids);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  //format data response to view it
  const names = users
    ? users.map((user, index) => {
        const formattedDate = formatDate(user.requestDate);
        return {
          id: user.id,
          name: user.userName,
          date: formattedDate,
        };
      })
    : null;

  const handleRoleChange = (event, userId) => {
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: event.target.value,
    }));
  };

  const handleApprove = async (userId) => {
    setIsLoading(true);
    try {
      confirmDialog({
        message: "Are you sure you want to approve?",
        icon: "pi pi-exclamation-triangle",
        position: "center",
        accept: async () => {
          try {
            setIsLoading(true);
            const joinReqBody = {
              status: 2,
              role: +selectedRoles[userId],
            };
            const response = await http.put(
              `JoiningRequests/UpdateJoiningRequestStatus/${userId}`,
              joinReqBody,
            );
            setRefetchData((prevstate) => !prevstate);
            fetchUsers();
            fetchStaff();
            setRefetchData(!refetchData);
            setIsLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 4000,
            });
          } catch (error) {
            console.error("Error while approving:", error);
            fetchUsers();
            fetchStaff();
            setRefetchData(!refetchData);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.response.data.message,
              life: 4000,
            });
          }
          setIsLoading(false);
        },
        reject: async () => {
          setSelectedRoles({});
        },
      });
    } catch (error) {
      console.error(error);
      fetchUsers();
      fetchStaff();
      setRefetchData(!refetchData);
    }
    setIsLoading(false);
  };

  const handleDecline = async (userId) => {
    setIsLoading(true);
    try {
      confirmDialog({
        message: "Are you sure you want to decline?",
        icon: "pi pi-exclamation-triangle",
        position: "center",

        accept: async () => {
          try {
            setIsLoading(true);
            const joinReqBody = {
              status: 3,
              role: 1,
            };
            const response = await http.put(
              `JoiningRequests/UpdateJoiningRequestStatus/${userId}`,
              joinReqBody,
            );
            setRefetchData((prevstate) => !prevstate);
            fetchUsers();
            setIsLoading(false);

            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 4000,
            });
          } catch (error) {
            console.error("Error while declining:", error);
            fetchUsers();
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.response.data.message,
              life: 4000,
            });
          }
          setIsLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const actionsTemplate = (rowData) => (
    <>
      <div className="flex gap-2">
        <Button
          className="p-button-success rounded-full py-2.5"
          icon="pi pi-check pr-1"
          onClick={() => handleApprove(rowData.id)}
          disabled={!selectedRoles[rowData.id]}
        >
          {selectedRoles[rowData.id] ? "Approve" : "Select Role"}
        </Button>
        <Button
          className="rounded-full border-none  bg-gray-300 py-2.5 text-gray-600"
          icon="pi pi-ban pr-1"
          onClick={() => handleDecline(rowData.id)}
        >
          Decline
        </Button>
      </div>
    </>
  );

  const dropdownTemplate = (rowData) => (
    <Dropdown
      value={(selectedRoles && rowData && selectedRoles[rowData.id]) || ""}
      options={Roles}
      onChange={(event) => handleRoleChange(event, rowData.id)}
      placeholder="Select a role"
      className="w-9/12"
    />
  );
  const cardRequestsTemplate = (row) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">{row.name}</p>
          <div>{dropdownTemplate(row)}</div>
        </div>
        <p className="text-slate-500">{row.email}</p>

        <div>{actionsTemplate(row)}</div>
      </>
    );
  };

  // *********** list of staff *************

  const fetchStaff = async () => {
    setIsLoading(true);
    try {
      const response = await http.get(`Entities/GetEntityStaff/${entityId}`);
      const data = response.data;
      const formattedData = data.map((item) => ({
        ...item,
        lastCertifiedDate: formatDate(item.lastCertifiedDate),
        disabled: item.applicationUserId === userId || item.isOwner,
      }));

      setStaffList(formattedData);

      const ids = data.map((item) => item.id);
      setJoinRequestId(ids);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  // create owner is disabled
  const isRowSelectable = (event) => {
    if (event.data) {
      return !event.data.disabled;
    }
    return true;
  };

  const rowClassName = (data) => {
    if (data.isOwner === true || data.applicationUserId === userId) {
      return "p-disabled";
    }
    return "";
  };

  const dropdownEditor = (props) => {
    const onDropdownChange = (e) => {
      const updatedData = e.value;
      const selectedOption = Roles.find((option) => option.value === e.value);
      const updatedRole = {
        applicationUserId: props.applicationUserId,
        entityId: entityId,
        role: e.value,
      };
      const UpdatedRole = async () => {
        try {
          const response = await http.put(
            "/EntityUser/UpdateEntityUserRole",
            updatedRole,
          );
          const data = response.data;
          toast.current.show({
            severity: "success",
            summary: "success",
            detail: response.data.message,
            life: 4000,
          });
          fetchStaff();
          setRefetchData(!refetchData);
        } catch (error) {
          console.error("Error fetching users:", error);
          toast.current.show({
            severity: "error",
            summary: "error",
            detail: error.response.data.message,
            life: 4000,
          });
        }
      };
      UpdatedRole();
    };

    return (
      <Dropdown
        value={props.role}
        options={Roles}
        onChange={onDropdownChange}
        optionLabel="label"
        placeholder={props.role}
        className="w-full"
        disabled={!props.isEnabled}
      />
    );
  };

  const confirm = (position, message, rowData) => {
    confirmDialog({
      message: message,
      icon: "pi pi-info-circle",
      position,
      accept: () => accept(rowData, message),
    });
  };
  const accept = async (rowData, message) => {
    const { isCertified, isEnabled } = rowData;
    const selectedOption = Roles.find(
      (option) => option.label === rowData.role,
    );
    const updatedCertified = {
      role: selectedOption.value,
      isEnabled: rowData.isEnabled,
      isCertified: rowData.isCertified,
    };
    if (
      message == "Would you like to enable this staff as certified member ?" &&
      !isCertified &&
      !isEnabled
    ) {
      updatedCertified.isCertified = true;
      updatedCertified.isEnabled = true;
    } else if (
      message ==
        "Would you like to disable this staff as uncertified member ?" &&
      isCertified &&
      isEnabled
    ) {
      updatedCertified.isCertified = false;
      updatedCertified.isEnabled = false;
    }

    try {
      const response = await http.put(
        `/EntityUser/${rowData.entityUserId}`,
        updatedCertified,
      );
      const data = response.data;
      toast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: data,
        life: 3000,
      });
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 3000,
      });
    }
    fetchStaff();
    setRefetchData(!refetchData);
  };

  const getMenuItems = (rowData) => {
    const items = [];

    if (rowData.isCertified === true) {
      items.push({
        label: "Deactivate",
        icon: "pi pi-ban",
        command: () =>
          confirm(
            "top-right",
            "Would you like to disable this staff as uncertified member ?",
            rowData,
          ),
      });
    } else {
      items.push({
        label: "Activate",
        icon: "pi pi-user",
        command: () =>
          confirm(
            "top-right",
            "Would you like to enable this staff as certified member ?",
            rowData,
          ),
      });
    }

    items.push({
      label: "Delete",
      icon: "pi pi-trash",
      command: () => handleDelete(rowData),
    });

    return items;
  };

  const actionTemplate = (rowData) => {
    const { entityUserId } = rowData;

    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[entityUserId].toggle(event);
          }}
          aria-controls={`popup_menu_right_${entityUserId}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[entityUserId] = el)}
          id={`popup_menu_right_${entityUserId}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const handleDelete = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        setIsLoading(true);
        try {
          await new Promise((resolve) => {
            deleteRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      },
    });
  };

  const lastCertifiedTemplate = (staffList) => {
    const severity = getSeverity(staffList);
    return (
      <>
        {severity == "success" ? (
          <span className="inline rounded-md bg-[#9EC8B9] p-2 text-center text-sm text-[#1B4242] sm:block sm:p-3 sm:text-base">
            {formatDate(staffList.lastCertifiedDate)}
          </span>
        ) : (
          <span className="inline rounded-md bg-red-100 p-2 text-center text-sm text-red-500 sm:block sm:p-3 sm:text-base">
            {formatDate(staffList.lastCertifiedDate)}
          </span>
        )}
      </>
    );
  };

  const getSeverity = (staffList) => {
    const { isCertified } = staffList;
    if (isCertified) {
      return "success";
    } else {
      return "danger";
    }
  };

  const nameEmailTemplate = (rowData) => (
    <>
      <h4 className="text-mobileTitle">{rowData.name}</h4>
      <p className="text-p">{rowData.email}</p>
    </>
  );
  const EmailOwnerTemplate = (rowData) => (
    <>
      <p className="text-p">{rowData.email}</p>
      <h4 className="text-mobileTitle">
        {rowData.isOwner === true ? (
          <div>
            <span className="text-sm font-bold capitalize text-light-purple sm:block sm:text-base">
              owner
            </span>
          </div>
        ) : rowData.applicationUserId === userId ? (
          <div>
            <span className="text-sm font-bold capitalize text-light-purple sm:block sm:text-base">
              You
            </span>
          </div>
        ) : null}
      </h4>
    </>
  );
  const columns = [{ value: "name", name: "Name", style: { width: "20%" } }];

  //*************** list of providers ************ */
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedIdentifierId, setSelectedIdentifierId] = useState(null);
  const [status, setStatus] = useState();
  const ProviderTemplate = (rowData) => {
    return (
      <>
        <div className="flex items-center gap-3">
          <div className="w-1/2">{rowData.role}</div>
          {rowData.isProvider === true ? (
            <div className="inline rounded-md bg-slate-100 px-4 py-1 text-center text-sm font-bold text-light-purple sm:block sm:p-3 sm:text-base">
              Provider
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const providerColumns = [
    { value: "fullName", name: "Name", style: { width: "20%" } },
    { value: "email", name: "Email", style: { width: "25%" } },
  ];
  const fetchProviders = async () => {
    setIsLoading(true);
    try {
      const response = await http.get(
        `/Entities/GetInvitedUsersAsAProvider/${entityId}`,
      );
      const data = response.data;
      setProvidersData(data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const statusProviderTemplate = (rowData) => {
    if (rowData.status === "Null") {
      return (
        <div className="inline rounded-md bg-sky-100 p-2 text-center text-sm font-bold capitalize text-sky-600 sm:block sm:p-3 sm:text-base">
          Verify Provider
        </div>
      );
    } else if (rowData.status === "InComplete") {
      return (
        <div className="inline rounded-md bg-orange-200 text-center text-sm font-bold capitalize text-orange-600 sm:block sm:p-3 sm:text-base">
          Incomplete
        </div>
      );
    } else if (rowData.status === "Pending") {
      return (
        <div className="inline rounded-md bg-slate-200 text-center text-sm font-bold capitalize text-slate-500 sm:block sm:p-3 sm:text-base">
          Pending
        </div>
      );
    } else if (rowData.status === "Approved") {
      return (
        <div className="inline rounded-md bg-green-200 text-center text-sm font-semibold capitalize text-green-700 sm:block sm:p-3 sm:text-base">
          Approved
        </div>
      );
    } else if (rowData.status === "Denied") {
      return (
        <div className="inline rounded-md bg-red-200 text-center text-sm font-semibold capitalize text-red-700 sm:block sm:p-3 sm:text-base">
          Denied
        </div>
      );
    }
  };

  const handleEditProvider = (userId, identifierId, status) => {
    setSelectedUserId(userId);
    setSelectedIdentifierId(identifierId);
    setStatus(status);
    setProviderFormDialog(true);
  };

  const editProviderTemplate = (rowData) => {
    if (rowData.status === "Null") {
      return (
        <div className="flex gap-2">
          <Button
            className=" rounded-full py-2.5"
            icon="pi pi-verified pr-1"
            severity="info"
            onClick={() =>
              handleEditProvider(
                rowData.userId,
                rowData.identifierId,
                rowData.status,
              )
            }
          >
            Verify provider
          </Button>
        </div>
      );
    } else if (rowData.status === "InComplete") {
      return (
        <div className="flex gap-2">
          <Button
            className="rounded-full py-2.5"
            icon="pi pi-verified pr-2"
            severity="help"
            loading={isLoading === rowData.userId}
            onClick={() => handleSendEmailToVerify(rowData)}
          >
            send link to complete verify
          </Button>
        </div>
      );
    } else if (rowData.status === "Pending") {
      return rowData.isReDueDiligence === true ? null : (
        <div className="flex gap-2">
          <Button
            className="rounded-full py-2.5"
            icon="pi pi-verified pr-1"
            severity="warning"
            onClick={() =>
              handleEditProvider(
                rowData.userId,
                rowData.identifierId,
                rowData.status,
              )
            }
          >
            Edit verify provider
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSendEmailToVerify = async (rowData) => {
    setIsLoading(rowData.userId);
    try {
      const response = await http.post(
        `/Identifications/SendCompleteProviderIdentityLink`,
        {
          userId: rowData.userId,
          userEmail: rowData.email,
          identifierId: rowData.identificationId,
        },
      );

      toast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: response.data.message,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // ********** list of invitations *********************
  const invitationsColumns = [
    { value: "invitedUserEmail", name: "Email", style: { width: "25%" } },
    { value: "invitationDate", name: "Date", style: { width: "15%" } },
  ];
  const handleResendInvitation = async (rowData) => {
    setLoadingResendInvitation((prevState) => ({
      ...prevState,
      [rowData.id]: true,
    }));
    try {
      const response = await http.post(
        `/Entities/ResendStaffInvitation/${rowData.id}`,
      );
      const data = response.data;
      toast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: data.message,
        life: 3000,
      });
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
    } finally {
      setLoadingResendInvitation((prevState) => ({
        ...prevState,
        [rowData.id]: false,
      }));
    }
  };
  const actionInvitationTemplate = (rowData) => {
    return (
      <>
        <Button
          onClick={() => handleDelete(rowData)}
          link
          tooltip="delete"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className="pi pi-trash text-red-500"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => handleResendInvitation(rowData)}
          disabled={loadingResendInvitation[rowData.id]}
          loading={loadingResendInvitation[rowData.id]}
          link
          tooltip="Resend Invitation"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i className="pi pi-undo" style={{ fontSize: "1rem" }}></i>
        </Button>
      </>
    );
  };

  const deleteRow = async (rowData) => {
    try {
      const endPointUrl = rowData.invitationDate
        ? `/Invitations/${rowData.id} `
        : `/EntityUser/${rowData.entityUserId}`;

      const res = await http.delete(endPointUrl);

      if (!rowData.invitationDate) {
        fetchStaff();
        setRefetchData(!refetchData);
      } else {
        setRefetchData(invitationsColumns);
      }
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data,
        life: 2000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 2000,
      });
    }
  };
  const cardInvitationsTemplate = (row) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">{row.invitedUserEmail}</p>
          <div className="flex">{actionInvitationTemplate(row)}</div>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-slate-500">{formatDate(row.invitationDate)}</p>
        </div>

        <div className="flex items-center gap-2 font-semibold">
          <span className="font-semibold text-slate-500">Status:&nbsp;</span>
          {statusTemplate(row)}
        </div>
        <div className="flex items-center gap-2 font-semibold">
          <span className="font-semibold text-slate-500">Status:&nbsp;</span>
          {ProviderTemplate(row)}
        </div>
      </>
    );
  };
  //********************************************* */
  const cardListOfStaffTemplate = (row) => {
    const isDisabled = row.isOwner === true || row.applicationUserId === userId;
    return (
      <>
        <div className={`${isDisabled ? "p-disabled" : ""}`}>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <div>{nameEmailTemplate(row)}</div>
            </div>
            <div className="flex">{actionTemplate(row)}</div>
          </div>
          <p className="font-semibold">
            <span className="font-semibold text-slate-500">Role:&nbsp;</span>
            {row.role}
          </p>

          <p className="font-semibold">
            <span className="font-semibold text-slate-500">
              Last Verified Date:&nbsp;
            </span>
            {lastCertifiedTemplate(row)}
          </p>
        </div>
      </>
    );
  };
  const cardListOfProvidersTemplate = (row) => {
    return (
      <>
        <div className="border-b border-solid border-gray-400 pb-5">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <div>
                <p className="font-semibold capitalize">{row.fullName}</p>
              </div>
            </div>
            <div className="flex">{editProviderTemplate(row)}</div>
          </div>
          <p className="font-semibold">{row.email}</p>
          <p className="mt-2 font-semibold">
            <span className="font-semibold capitalize text-slate-500">
              status:&nbsp;
            </span>
            {statusProviderTemplate(row)}
          </p>
        </div>
      </>
    );
  };
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        blockScroll
        draggable={false}
        visible={providerFormDialog}
        onHide={() => {
          setProviderFormDialog(false);
        }}
        header="Verify Provider"
        className="w-[80vw]"
      >
        <VerifyProvider
          userIdTable={selectedUserId}
          identifierIdTable={selectedIdentifierId}
          statusTable={status}
          setProviderFormDialog={setProviderFormDialog}
          setRefetchData={setRefetchData}
          refetchData={refetchData}
        />
      </Dialog>
      <div className="staff-container w-full">
        <div className="btn-invite flex flex-col items-start gap-4 border-b-2 py-11 md:w-full ">
          <div>
            <h5 className="font-inter text-lg font-extrabold">
              Invite Staff Member
            </h5>
            <p className="text-p text-normal-text ">
              We will email them instructions and send them a link to sign up.
            </p>
          </div>
          <div className="w-full gap-y-2 rounded-xl border-[.14rem] border-solid border-light-purple bg-white p-4">
            <div className="flex w-full flex-wrap items-center justify-between md:w-10/12">
              <div className="basis-3/6 md:basis-2/6">
                <InputText
                  keyfilter="email"
                  placeholder="Email"
                  name="staffEmail"
                  value={inputTag.staffEmail}
                  onKeyDown={(e) => e.key === "Enter" && addNewTag()}
                  onChange={(e) => {
                    setInputTag({
                      ...inputTag,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  className={`w-11/12 ${errorMessage ? "border-2 border-red-600" : null}`}
                />
              </div>
              <div className="basis-3/6 md:basis-2/6">
                <Dropdown
                  value={inputTag.staffRole}
                  options={Roles}
                  onChange={(e) => {
                    setInputTag({
                      ...inputTag,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  optionLabel="label"
                  optionValue="value"
                  name="staffRole"
                  placeholder="Select Role"
                  className="w-full md:w-11/12"
                />
              </div>
              <div className="mt-4 md:my-auto md:mr-auto ">
                {isOrderingMode && (
                  <>
                    <Checkbox
                      inputId="isProvider"
                      name="isProvider"
                      value={inputTag.isProvider}
                      checked={inputTag.isProvider}
                      onChange={(e) => {
                        setInputTag({
                          ...inputTag,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      className="pb-5"
                    />
                    <label
                      htmlFor="Provider"
                      className="ml-2 font-semibold text-normal-text"
                    >
                      Invite as a provider{" "}
                    </label>
                  </>
                )}
              </div>
            </div>
            <div className="px-3 py-1">
              <span className="text-sm text-red-500">{errorMessage}</span>
            </div>
            <div className="mb-4 w-9/12">
              {tags.length > 0 && (
                <div className="col-span-12 flex flex-wrap items-center gap-3 bg-white p-4 md:col-span-9  md:rounded-lg md:border md:border-slate-300 md:bg-slate-50">
                  {" "}
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 rounded-full  bg-white px-4 py-1 shadow-md"
                    >
                      <span>{tag.staffEmail} </span>
                      <span className="rounded-full bg-slate-200 px-2 py-1 text-sm">
                        {
                          Roles.find((role) => role.value === tag.staffRole)
                            .label
                        }
                      </span>
                      <Button
                        icon="pi pi-times"
                        rounded
                        text
                        size="small"
                        severity="danger"
                        aria-label="Cancel"
                        onClick={() => {
                          setTags(tags.filter((tag, idx) => idx !== index));
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-end">
              <div className="">
                <Button
                  icon="pi pi-plus"
                  label="Add New Email"
                  onClick={addNewTag}
                  outlined
                  className="w-full bg-white"
                />
              </div>
              <div className="">
                <Button
                  type="submit"
                  className="w-full bg-light-purple "
                  onClick={handleButtonClick}
                  disabled={loadingSendInvitation}
                  loading={loadingSendInvitation}
                  icon="pi pi-send"
                  label={`Send Invitation${tags.length > 1 ? "s" : ""}`}
                ></Button>
              </div>
            </div>
          </div>
        </div>

        <div className="list-request border-b-2 border-b-gray-300 pb-11">
          {isMobile || (
            <h4 className="mb-5 mt-4 font-inter text-title font-semibold capitalize ">
              Requests
            </h4>
          )}

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {entityType == 1 && (
              <TabPanel
                header="Join Requests"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                {isMobile ? (
                  <>
                    <ReusableFilter
                      fromFront={true}
                      globalFilterValue={globalFilterValue}
                      onGlobalFilterChange={onGlobalFilterChange}
                    />
                    <ReusableCardList
                      tableData={names}
                      actionTemplates={[{ template: cardRequestsTemplate }]}
                      globalFilterValue={globalFilterValue}
                      cardFilters={["name"]}
                    />
                  </>
                ) : (
                  <ReusableDataList
                    title={"All Join Requests"}
                    fromFront={true}
                    dataLink={`/JoiningRequests/GetJoiningRequestsByEntityId/${entityId}`}
                    columns={joinColumns}
                    searchTitle="search by name"
                    actionTemplates={[
                      {
                        template: dropdownTemplate,
                        header: "Roles",
                        style: { width: "20%" },
                      },
                      {
                        template: actionsTemplate,
                        header: "Actions",
                        style: { width: "25%" },
                      },
                    ]}
                    refetch={refetchData}
                    setRefetch={setRefetchData}
                  />
                )}
              </TabPanel>
            )}
            <TabPanel
              header="Invitations"
              className="flex-1 text-sm sm:flex-initial sm:text-base"
            >
              <ReusableDataList
                title={"All Invitations"}
                fromFront={true}
                dataLink={`/Invitations/GetInvitationRequestsByEntityId/${entityId}`}
                columns={invitationsColumns}
                searchTitle="search by email"
                actionTemplates={
                  isMobile
                    ? [{ template: cardInvitationsTemplate }]
                    : [
                        { template: statusTemplate, header: "Status" },
                        {
                          template: ProviderTemplate,
                          header: "Role",
                          style: { width: "15%" },
                        },
                        {
                          template: actionInvitationTemplate,
                          header: "Actions",
                        },
                      ]
                }
                cardFilters={["invitedUserEmail"]}
                refetch={refetchData}
                setRefetch={setRefetchData}
              />
            </TabPanel>
            {isMobile && (
              <TabPanel
                header="Staff"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <ReusableFilter
                  fromFront={true}
                  globalFilterValue={globalFilterValue}
                  onGlobalFilterChange={onGlobalFilterChange}
                />
                <ReusableCardList
                  tableData={staffList}
                  actionTemplates={[{ template: cardListOfStaffTemplate }]}
                  globalFilterValue={globalFilterValue}
                  cardFilters={["name", "email"]}
                />
              </TabPanel>
            )}
            {isMobile && (
              <TabPanel
                header="List Of Providers"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <ReusableFilter
                  fromFront={true}
                  globalFilterValue={globalFilterValue}
                  onGlobalFilterChange={onGlobalFilterChange}
                />
                <ReusableCardList
                  tableData={providersData}
                  actionTemplates={[{ template: cardListOfProvidersTemplate }]}
                  globalFilterValue={globalFilterValue}
                  cardFilters={["email", "fullName"]}
                />
              </TabPanel>
            )}
          </TabView>
        </div>

        {isMobile || (
          <div>
            <div className="mb-5 mt-4 flex flex-wrap justify-between">
              <h4 className="font-inter text-title font-semibold capitalize ">
                &nbsp;staff&nbsp;
              </h4>
            </div>
            <TabView>
              <TabPanel
                header="Staff"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <ReusableDataList
                  title={"All Members"}
                  fromFront={true}
                  dataLink={`/Entities/GetEntityStaff/${entityId}`}
                  columns={columns}
                  searchTitle="search by name"
                  actionTemplates={[
                    {
                      template: EmailOwnerTemplate,
                      header: "Emails",
                      style: { width: "20%" },
                    },
                    {
                      template: dropdownEditor,
                      header: "Roles",
                      style: { width: "20%" },
                    },
                    {
                      template: lastCertifiedTemplate,
                      header: "Last Certified Date",
                      style: { width: "20%" },
                    },
                    {
                      template: actionTemplate,
                      header: "Actions",
                      style: { width: "20%" },
                    },
                  ]}
                  isRowSelectable={isRowSelectable}
                  rowClassName={rowClassName}
                  refetch={refetchData}
                  setRefetch={setRefetchData}
                />
              </TabPanel>
              {isOrderingMode && (
                <TabPanel
                  header="Providers"
                  className="flex-1 text-sm sm:flex-initial sm:text-base"
                >
                  <ReusableDataList
                    title={"All Providers"}
                    fromFront={true}
                    dataLink={`/Entities/GetInvitedUsersAsAProvider/${entityId}`}
                    columns={providerColumns}
                    searchTitle="search by name"
                    actionTemplates={[
                      {
                        template: statusProviderTemplate,
                        header: "Status",
                        style: { width: "20%" },
                      },
                      { template: editProviderTemplate, header: "Actions" },
                    ]}
                    refetch={refetchData}
                    setRefetch={setRefetchData}
                  />
                </TabPanel>
              )}
            </TabView>
          </div>
        )}
      </div>
    </>
  );
}

export default StaffDashboard;
