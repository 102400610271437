import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";

import useAxios from "../../hooks/useAxios";
import { trimValidation } from "../utility/Utils";

export const PatientInfo = ({
  isTesting,
  submitRef,
  setActiveIndex,
  onSubmitCallback,
  patientInfoData,
}) => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [states, setStates] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      const res = await http.get(`/States`);
      setStates(res.data);
    };
    getStates();
  }, []);

  const genderOptions = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
    { name: "Unspecified", value: "Unspecified" },
  ];
  const handleGenderChange = (e) => {
    setSelectedGender(e.value);
  };

  const getDefaultValues = () => {
    return isTesting
      ? {
          Fname: "Patient First Name",
          Lname: "Patient Last Name",
          phone: "(123) 123-1234",
          email: "patient-email@example.com",
          AddressLineOne: "123 Main Street",
          City: "Any town",
          ZipCode: "12345",
        }
      : {};
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues: getDefaultValues() });

  const { http } = useAxios();
  const onSubmit = async (formData) => {
    onSubmitCallback(formData);
    setActiveIndex((old) => old + 1);
  };
  useEffect(() => {
    if (patientInfoData) {
      setSelectedGender(patientInfoData.gender);
      reset(patientInfoData);
    } else {
      setValue("State", "Arkansas");
    }
  }, []);

  return (
    <div>
      <div className="flex items-center justify-center">
        <form
          ref={submitRef}
          className="w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            {!isTesting && (
              <div className="mb-5">
                <h4 className="font-inter text-title font-extrabold capitalize text-dark-purple">
                  Patient Information
                </h4>
                <p>Fill the form below to complete patient information</p>
              </div>
            )}
            <div className="flex flex-col gap-5 sm:flex-row">
              <div>
                <label
                  htmlFor="ssn"
                  className="text-normal font-Poppins capitalize leading-loose text-gray-500"
                >
                  patient first name <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-left w-full ">
                  <i className="pi pi-user" />
                  <InputText
                    placeholder="Patient first name "
                    className="w-full pb-2 pt-2"
                    {...register("Fname", {
                      required: "first name is required",
                      validate: (value) =>
                        trimValidation(value, "Patient first name "),
                    })}
                  />
                </span>
                {errors.Fname && (
                  <p className="text-red-500 ">{errors.Fname.message}</p>
                )}
              </div>
              <div>
                <div>
                  <label
                    htmlFor="ssn"
                    className="text-normal font-Poppins capitalize leading-loose text-gray-500"
                  >
                    patient last name<span className="text-red-500">*</span>
                  </label>
                  <span className="p-input-icon-left w-full ">
                    <i className="pi pi-user" />
                    <InputText
                      placeholder="Patient last name "
                      className="w-full pb-2 pt-2"
                      {...register("Lname", {
                        required: "Last name is required",
                        validate: (value) =>
                          trimValidation(value, "Patient last name"),
                      })}
                    />
                  </span>
                  {errors.Lname && (
                    <p className="text-red-500 ">{errors.Lname.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="ssn"
                className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
              >
                Mobile Phone Number<span className="text-red-500">*</span>
              </label>
              <span className="p-input-icon-left w-full ">
                <i className="pi pi-phone" />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                    pattern: {
                      value: /^\(\d{3}\) \d{3}-\d{4}$/,
                      message: "Invalid phone format",
                    },
                  }}
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      autoClear={false}
                      className="w-full pb-2 pt-2"
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                    />
                  )}
                />
              </span>
              {errors.phone && (
                <p className="text-red-500 ">{errors.phone.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="ssn"
                className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
              >
                Email Address<span className="text-red-500">*</span>
              </label>
              <span className="p-input-icon-left w-full ">
                <i className="pi pi-envelope" />
                <InputText
                  placeholder="Email Address"
                  className="w-full pb-2 pt-2"
                  {...register("email", {
                    required: "please enter a valid email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                />
              </span>
              {errors.email && (
                <p className="text-red-500 ">{errors.email.message}</p>
              )}
            </div>
            <div>
              <label className="mt-2 block font-inter text-p font-normal leading-loose text-gray-500">
                Date of Birth
                <span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <span className="p-float-label mt-1 w-full ">
                <Controller
                  name="birth"
                  control={control}
                  rules={{
                    required: "Date of Birth is required",
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        type="date"
                        min={"1900-01-01"}
                        max={new Date().toISOString().split("T")[0]}
                        id="birthday"
                        name="birthday"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        style={{
                          border: "1px solid rgb(203 213 225)",
                          outline: "none",
                        }}
                        className="w-full rounded p-2"
                        placeholder="mm/dd/yy"
                      />
                    </>
                  )}
                />
              </span>
              {errors.birth && (
                <p className="text-red-500 ">{errors.birth.message}</p>
              )}
            </div>
            <label
              htmlFor="ssn"
              className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
            >
              gender <span className="text-red-500">*</span>
            </label>
            <div>
              <Dropdown
                name="gender"
                value={selectedGender}
                options={genderOptions}
                optionLabel="name"
                placeholder="Gender"
                {...register("gender", {
                  required: "Please select your gender",
                })}
                onChange={handleGenderChange}
                className="md:w-14rem w-full "
              />
              {errors.gender && (
                <p className="text-red-500 ">{errors.gender.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="ssn"
                className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
              >
                address line one <span className="text-red-500">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-map-marker" />
                <InputText
                  placeholder="Address Line one "
                  className="w-full pb-2 pt-2"
                  {...register("AddressLineOne", {
                    required: "Address Line one is required",
                    validate: (value) =>
                      trimValidation(value, "address line one"),
                  })}
                />
              </span>
              {errors.AddressLineOne && (
                <p className="text-red-500 ">{errors.AddressLineOne.message}</p>
              )}
            </div>
            <div className="flex flex-col md:gap-2 lg:flex-row">
              <div className="grow">
                <label
                  htmlFor="ssn"
                  className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
                >
                  address line two
                </label>
                <span className="p-input-icon-left w-full ">
                  <i className="pi pi-map-marker" />
                  <InputText
                    placeholder="Address Line two "
                    className="w-full"
                    {...register("AddressLineTwo")}
                  />
                </span>
              </div>
              <div className="flex flex-col sm:flex-row sm:gap-2">
                <div className="flex-1">
                  <label
                    htmlFor="ssn"
                    className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
                  >
                    City <span className="text-red-500">*</span>
                  </label>
                  <span className="p-input-icon-left w-full ">
                    <i className="pi pi-map" />
                    <InputText
                      placeholder="City"
                      className="w-full"
                      {...register("City", {
                        required: "City is required",
                        validate: (value) => trimValidation(value, "City"),
                      })}
                    />
                  </span>
                  {errors.City && (
                    <p className="text-red-500 ">{errors.City.message}</p>
                  )}
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="ssn"
                    className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
                  >
                    state <span className="text-red-500">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-map" />
                    <Controller
                      name="State"
                      control={control}
                      rules={{ required: "State is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select State"
                          options={states}
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className="w-full"
                        />
                      )}
                    />
                  </span>
                  {errors.State && (
                    <p className="text-red-500 ">{errors.State.message}</p>
                  )}
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="ssn"
                    className="text-normal mt-3 block font-Poppins capitalize leading-loose text-gray-500"
                  >
                    ZipCode <span className="text-red-500">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="ZipCode"
                      control={control}
                      rules={{
                        required: "ZIP Code is required",
                        pattern: {
                          value: /^\d{5}$/,
                          message: "ZIP Code must be exactly 5 digits",
                        },
                        validate: (value) => trimValidation(value, "ZIP Code"),
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <InputText
                            placeholder="ZIP Code"
                            className="w-full"
                            maxLength={5}
                            keyfilter="num"
                            {...field}
                          />
                        </>
                      )}
                    />
                  </span>
                  {errors.ZipCode && (
                    <p className="text-red-500 ">{errors.ZipCode.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
