import React, { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import { trimValidation } from "../utility/Utils";
import useTokenData from "../../hooks/useTokenData";
import DocumentUpload from "../formElements/DocumentUpload";
import IdentityVerification from "../settings/IdentityVerification";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import {
  useCapturedPhoto,
  useCurrentEntity,
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../store";
import * as signalR from "@microsoft/signalr";

function IndividualNextStep() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedState, setSelectedState] = useState([]);
  const { setCapturedPhoto } = useCapturedPhoto();

  const params = useParams();
  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();
  const { token, setToken } = useTokenStore();
  const { userId, userName } = useTokenData();
  const { setCurrentEntity } = useCurrentEntity();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { setRefreshToken } = useRefreshTokenStore();

  let hubConnection = null;

  const defaultValues = {
    Entity: {
      Name: userName,
      DoingBusinessAsName: "",
      RegisteredStateName: "",
      EntityType: Number(params.entityType),
      EntityImageFile: {
        FileDetails: null,
        FilePropertyName: 3,
        FileType: 1,
      },
      Description: "",
      TechnicalSupportName: "",
      TechnicalSupportPhoneNumber: "",
      TechnicalSupportEmail: "",
    },
    identification: {
      TaxID: "",
      IdentityImageFile: {
        FileDetails: null,
        FilePropertyName: null,
        FileType: 1,
      },
      SelfieImageFile: {
        FileDetails: null,
        FilePropertyName: 4,
        FileType: 1,
      },
    },
    EntityPrincipalAddress: {
      LineOne: "",
      LineTwo: "",
      State: "",
      City: null,
      ZipCode: "",
    },
    RegisteredAgentAddress: {
      LineOne: "",
      LineTwo: "",
      State: "",
      City: null,
      ZipCode: "",
    },

    ApplicationUserId: userId,
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const startConnection = () => {
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notify", {
        accessTokenFactory: () => {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    hubConnection
      .start()
      .then(() => {})
      .catch((err) => {});
  };

  const addTransferChartDataListener = () => {
    hubConnection.on("NotifyProviderTracking", (newData) => {
      if (userId === newData.notifyTo) {
        if (newData.action === "DisplayImage") {
          const imageDataUrl = newData.source;
          const binaryData = atob(imageDataUrl.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/png" });
          const file = new File([blob], "mobileCapturedPhoto.png", {
            type: "image/png",
          });
          const url = URL.createObjectURL(blob);
          setCapturedPhoto({
            file: file,
            url: url,
            filePropertyName: newData.propertyName,
            driverLicenseType: newData.side,
          });
        }
      }
    });
  };
  useEffect(() => {
    startConnection();
    addTransferChartDataListener();
    return () => {
      if (hubConnection) {
        hubConnection.off("NotifyProviderTracking");
        hubConnection.stop();
      }
    };
  }, [token]);
  // Check if the event key is a keyboard key you want to ignore
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        return;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    getStates();
  }, []);

  //   fetch state data from api
  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setSelectedState(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // on submit method with refactor
  const onSubmit = async (formData) => {
    setIsLoading(true);

    const data = new FormData();
    data.append("Entity.Name", formData.Entity.Name);
    data.append(
      "Entity.DoingBusinessAsName",
      formData.Entity.DoingBusinessAsName,
    );
    data.append(
      "Entity.RegisteredStateName",
      formData.Entity.RegisteredStateName,
    );
    data.append("Entity.EntityType", formData.Entity.EntityType);

    data.append(
      "Entity.EntityImageFile.FileDetails",
      formData.Entity.EntityImageFile.FileDetails,
    );
    data.append(
      "Entity.EntityImageFile.FilePropertyName",
      formData.Entity.EntityImageFile.FilePropertyName,
    );
    data.append(
      "Entity.EntityImageFile.FileType",
      formData.Entity.EntityImageFile.FileType,
    );
    data.append("Entity.Description", formData.Entity.Description);
    data.append("Identification.TaxID", formData.Identification.TaxID);
    data.append(
      "Identification.IdentityImageFile.FileDetails",
      formData.identification.IdentityImageFile.FileDetails,
    );
    data.append(
      "Identification.IdentityImageFile.FilePropertyName",
      formData.identification.IdentityImageFile.FilePropertyName || 0,
    );
    data.append(
      "Identification.IdentityImageFile.FileType",
      formData.identification.IdentityImageFile.FileType,
    );
    data.append(
      "Identification.SelfieImageFile.FileDetails",
      formData.identification.SelfieImageFile.FileDetails,
    );
    data.append(
      "Identification.SelfieImageFile.FilePropertyName",
      formData.identification.SelfieImageFile.FilePropertyName || 0,
    );
    data.append(
      "Identification.SelfieImageFile.FileType",
      formData.identification.SelfieImageFile.FileType,
    );
    data.append(
      "RegisteredAgentAddress.LineOne",
      formData.RegisteredAgentAddress.LineOne,
    );
    data.append(
      "RegisteredAgentAddress.LineTwo",
      formData.RegisteredAgentAddress.LineTwo || "",
    );
    data.append(
      "RegisteredAgentAddress.City",
      formData.RegisteredAgentAddress.City,
    );
    data.append(
      "RegisteredAgentAddress.State",
      formData.RegisteredAgentAddress.State,
    );
    data.append(
      "RegisteredAgentAddress.ZipCode",
      formData.RegisteredAgentAddress.ZipCode,
    );
    data.append(
      "EntityPrincipalAddress.LineOne",
      formData.EntityPrincipalAddress.LineOne,
    );
    data.append(
      "EntityPrincipalAddress.LineTwo",
      formData.EntityPrincipalAddress.LineTwo || "",
    );
    data.append(
      "EntityPrincipalAddress.City",
      formData.EntityPrincipalAddress.City,
    );
    data.append(
      "EntityPrincipalAddress.State",
      formData.EntityPrincipalAddress.State,
    );
    data.append(
      "EntityPrincipalAddress.ZipCode",
      formData.EntityPrincipalAddress.ZipCode,
    );

    data.append("ApplicationUserId", formData.ApplicationUserId);

    // Start Technical info
    data.append(
      "Entity.TechnicalSupportName",
      formData.Entity.TechnicalSupportName || "",
    );
    data.append(
      "Entity.TechnicalSupportPhoneNumber",
      formData.Entity.TechnicalSupportPhoneNumber,
    );
    data.append(
      "Entity.TechnicalSupportEmail",
      formData.Entity.TechnicalSupportEmail,
    );
    // End Technical info

    try {
      const response = await http.post(
        "/Identifications/CreateEntityWithIdentification",
        data,
      );
      setEntityId(response.data.entity.id);
      setCurrentEntity(response.data.entity);
      const res = await http.post(
        `/Authentication/GetToken/${response.data.entity.id}/1`,
      );
      setToken(res.data.token);
      setRefreshToken(res.data.refreshToken);
      Cookies.remove("userData");
      reset();
      navigate(`/setUpIndividualFinish`, {
        replace: true,
      });
    } catch (error) {
      if (
        error.message === "Please take a selfie" ||
        error.message === "Please select your identification"
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.message,
          life: 4000,
        });
      }
    }
    setIsLoading(false);
  };

  const handleCreatePractice = (formData) => {
    confirmDialog({
      className: "w-full md:w-[50vw]",
      message: (
        <p className="ml-3">
          <span className="font block font-Poppins font-bold">
            Do you really want to proceed with creating this{" "}
            <span className="text-light-purple">individual practice?</span>
          </span>
          <br />
          <span className="font-Poppins font-medium text-gray-500">
            By confirming, you will create a new individual practice, and all
            the provided information will be saved.
          </span>
        </p>
      ),
      icon: "pi pi-exclamation-triangle",
      position: "top",
      header: "Confirmation",
      acceptLabel: "Yes",
      rejectLabel: "No",
      accept: () => {
        onSubmit(formData);
      },
    });
  };
  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="flex h-full w-full items-center justify-center">
        <div className="w-full rounded-3xl bg-light-text p-10 shadow shadow-slate-300 sm:w-full md:w-3/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4">
          <div className="mb-5">
            <h4 className="text-center font-philosopher text-title font-black capitalize text-dark-purple">
              Set up my individual provider profile
            </h4>
            <div className="flex flex-col items-center sm:flex-row">
              <div className="sm:flex-shrink-0">
                <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-slate-100 sm:h-14 sm:w-14">
                  <MedicalHistoryIcon />
                </div>
              </div>
              <div className="mb-4 mt-5 text-justify sm:ml-5">
                <p className="w-full font-Poppins text-p leading-loose text-normal-text">
                  Welcome to the individual provider profile setup. Fill out the
                  required details to create an individual practice. If you wish
                  to create a group practice, please{" "}
                  <span
                    className="cursor-pointer text-blue-700 underline"
                    onClick={() => navigate(-1)}
                  >
                    go back{" "}
                  </span>
                  and select the{" "}
                  <span className="text-base font-medium text-light-purple">
                    'set up group practice'
                  </span>{" "}
                  option.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <label className="mb-1 mt-3 block font-Poppins text-base capitalize leading-loose text-gray-500">
              individual Logo/Trademark
            </label>

            <div className="flex items-center sm:w-1/2 md:w-1/3">
              <DocumentUpload
                name="logo"
                fileType="image"
                maxFileSizeInMB={2}
                onUpload={(file) => {
                  setValue("Entity.EntityImageFile.FileDetails", file);
                }}
                onClear={() => {
                  setValue("Entity.EntityImageFile.FileDetails", null);
                }}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(handleCreatePractice)}>
            <div className="form-container mt-3">
              <div className="border-b-2 pb-11">
                <label
                  htmlFor="ssn"
                  className="font-Poppins text-base capitalize leading-loose text-gray-500"
                >
                  individual Tax ID <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-right w-full">
                  <Controller
                    control={control}
                    name="Identification.TaxID"
                    rules={{
                      required: "Tax ID is required",
                      pattern: {
                        value: /^(?:\d-?){9}$/,
                        message: "Tax ID must be exactly 9 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputText
                        id="taxIDInput"
                        placeholder="Enter nine digit tax id number"
                        className="w-full pb-2 pt-2"
                        {...field}
                        maxLength={17}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.Identification && errors.Identification.TaxID && (
                    <p className="text-red-500">
                      {errors.Identification.TaxID.message}
                    </p>
                  )}
                </span>

                <div className="mt-6">
                  <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                    individual Home/Primary Address
                  </label>
                  <span className="p-input-icon-right w-full">
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      individual address line one{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      placeholder="Address Line One"
                      className="w-full pb-2 pt-2"
                      {...register("EntityPrincipalAddress.LineOne", {
                        required: "Address is required",
                        validate: (value) =>
                          trimValidation(value, "Address line one"),
                      })}
                    />
                  </span>
                  {errors.EntityPrincipalAddress &&
                    errors.EntityPrincipalAddress.LineOne && (
                      <p className="mt-1 text-red-500">
                        {errors.EntityPrincipalAddress.LineOne.message}
                      </p>
                    )}
                  <span className="p-input-icon-right mt-3 w-full">
                    <div className="flex justify-between">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        individual address line two
                      </label>
                      <p className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        {" "}
                        (optional)
                      </p>
                    </div>
                    <InputText
                      placeholder="Address Line Two"
                      className="w-full pb-2 pt-2"
                    />
                  </span>

                  <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                    <span className="col-span-1">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        city <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        placeholder="City"
                        className="w-full"
                        {...register("EntityPrincipalAddress.City", {
                          required: "City is required",
                          validate: (value) => trimValidation(value, "City"),
                        })}
                      />
                      {errors.EntityPrincipalAddress &&
                        errors.EntityPrincipalAddress.City && (
                          <p className="mt-1 text-red-500">
                            {errors.EntityPrincipalAddress.City.message}
                          </p>
                        )}
                    </span>

                    <span className="col-span-1">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        state <span className="text-red-500">*</span>
                      </label>
                      <Controller
                        name="EntityPrincipalAddress.State"
                        control={control}
                        rules={{ required: "Please select a state" }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            placeholder="State"
                            focusInputRef={field.ref}
                            options={selectedState.map((option) => ({
                              value: option.name,
                              label: option.name,
                            }))}
                            onChange={(e) => field.onChange(e.value)}
                            className="md:w-14rem w-full"
                          />
                        )}
                      />
                      {errors.EntityPrincipalAddress &&
                        errors.EntityPrincipalAddress.State && (
                          <p className="mt-1 text-red-500">
                            {errors.EntityPrincipalAddress.State.message}
                          </p>
                        )}
                    </span>

                    <span className="col-span-1">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        ZIP Code <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        placeholder="ZIP Code"
                        className="w-full"
                        maxLength={5}
                        keyfilter="num"
                        {...register("EntityPrincipalAddress.ZipCode", {
                          required: "ZIP Code is required",
                          pattern: {
                            value: /^\d{5}$/,
                            message: "ZIP Code must be exactly 5 digits",
                          },
                        })}
                      />
                      {errors.EntityPrincipalAddress &&
                        errors.EntityPrincipalAddress.ZipCode && (
                          <p className="mt-1 text-red-500">
                            {errors.EntityPrincipalAddress.ZipCode.message}
                          </p>
                        )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 border-b-2 pb-9">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                  individual Support Contact Information{" "}
                </label>
                <span className="w-full">
                  <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                    technical Support Name
                  </label>
                  <InputText
                    {...register("Entity.TechnicalSupportName")}
                    placeholder="Name"
                    className="w-full pb-2 pt-2"
                  />
                </span>
                <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                  technical Support phone{" "}
                  <span className="text-red-600">*</span>
                </label>
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-phone" />
                  <Controller
                    name="Entity.TechnicalSupportPhoneNumber"
                    control={control}
                    rules={{
                      required: "Phone number is required",
                      validate: (value) =>
                        trimValidation(value, "Technical Support phone"),
                    }}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="999-999-9999"
                        placeholder="Phone Number"
                        className={`w-full pb-2 pt-2 ${
                          errors?.Entity?.TechnicalSupportPhoneNumber
                            ? "p-invalid"
                            : ""
                        }`}
                        onClick={(e) => {
                          if (e.target.value === "___-___-____") {
                            e.target.setSelectionRange(0, 0);
                          }
                        }}
                      />
                    )}
                  />
                </span>

                {errors?.Entity &&
                  errors?.Entity?.TechnicalSupportPhoneNumber && (
                    <p className="mt-1 text-red-500">
                      {errors?.Entity?.TechnicalSupportPhoneNumber?.message}
                    </p>
                  )}
                <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                  technical Support Email{" "}
                  <span className="text-red-600">*</span>
                </label>
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-envelope" />
                  <Controller
                    name="Entity.TechnicalSupportEmail"
                    control={control}
                    rules={{
                      required: "Technical Support Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid Email Address",
                      },
                      validate: (value) =>
                        trimValidation(value, "Technical support email"),
                    }}
                    render={({ field }) => (
                      <InputText
                        {...field}
                        placeholder="Email"
                        className={`w-full pb-2 pt-2 ${
                          errors?.Entity?.TechnicalSupportEmail
                            ? "p-invalid"
                            : ""
                        }`}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </span>

                {errors &&
                  errors?.Entity &&
                  errors?.Entity?.TechnicalSupportEmail && (
                    <p className="mt-1 text-red-500">
                      {errors?.Entity?.TechnicalSupportEmail?.message}
                    </p>
                  )}

                <p className="text-md mt-6 text-gray-500">
                  * This contact information will be visible to providers that
                  order your services in the event that they have additional
                  questions.
                </p>
              </div>
              <div className="mt-5">
                <IdentityVerification setValue={setValue} />
              </div>

              <div className="mt-5 text-right">
                {isLoading ? (
                  <div className="text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="mt-9 justify-center rounded-full bg-light-purple px-6 py-3 font-inter font-normal capitalize text-light-text"
                  >
                    {" "}
                    next{" "}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default IndividualNextStep;
