import React, { useEffect, useRef, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { downloadFileFromResponse } from "../utility/Utils";
import { Toast } from "primereact/toast";

function OrderDetails() {
  const { orderId } = useParams();
  const { http } = useAxios();
  const [orderLocations, setOrderLocations] = useState([]);
  const [orderBillableItems, setOrderBillableItems] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterLoc, setGlobalFilterLoc] = useState("");
  const [isDownloadingSummary, setIsDownloadingSummary] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  const orderDetails = location.state;

  useEffect(() => {
    fetchOrder(orderId);
  }, [orderId]);

  const fetchOrder = async (orderId) => {
    try {
      const res = await http.get(`/Orders/GetOrderDetails/${orderId}`);
      setOrderLocations(res.data.orderLocations);
      setOrderBillableItems(res.data.orderBillableItems);
    } catch (error) {}
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };
  const onGlobalFilterLoc = (e) => {
    setGlobalFilterLoc(e.target.value);
  };

  const downloadOrderSummary = async () => {
    setIsDownloadingSummary(true);
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${orderId}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    }
    setIsDownloadingSummary(false);
  };
  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const header = (
    <div className="rounded-t-lg">
      <div className="flex items-center justify-between">
        <p className="py-5 font-inter font-semibold"> Order Billable Items </p>

        <div>
          <span className="p-input-icon-left min-w-[300px]">
            <i className="pi pi-search" />
            <InputText
              className="w-full"
              placeholder={"Search"}
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
            />
          </span>
        </div>
      </div>
    </div>
  );
  const billableColumns = [
    {
      field: "shortDescription",
      header: "Billable Item",
      body: descriptionWithTooltipTemplate,
      sortable: true,
      className: "w-64",
    },
    { field: "hcpcs", header: "HCPCS", sortable: true },
    { field: "mod1", header: "Mod1", sortable: true },
    { field: "mod2", header: "Mod2", sortable: true },
    { field: "mod3", header: "Mod3", sortable: true },
    { field: "mod4", header: "Mod4", sortable: true },
    {
      field: "attributeTypeValue",
      header: "Attribute Type Value",
      sortable: true,
    },
    { field: "attributeType", header: "Attribute Type", sortable: true },
    { field: "chargeAmount", header: "Charge Amount", sortable: true },
    { field: "totalCharge", header: "Total Charge", sortable: true },
    { field: "unit", header: "Unit", sortable: true },
  ];
  const LocationsColumns = [
    {
      field: "name",
      header: "Location Name",
      sortable: true,
      body: (row) => {
        return (
          <div>
            {row.name}
            {row.isPreferredByPatient ? (
              <span className="px-2 text-sm italic text-gray-500">
                (Preferred by Patient)
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "address.lineOne",
      header: "Line One",
      sortable: true,
    },
    { field: "address.city", header: "City", sortable: true },
    { field: "address.state", header: "State", sortable: true },
    { field: "address.zipCode", header: "ZIP Code", sortable: true },
  ];
  const locationHeader = (
    <div className="overflow-hidden rounded-t-lg">
      <div className="flex items-center justify-between">
        <p className="py-5 font-inter font-bold">Order Locations </p>

        <div>
          <span className="p-input-icon-left min-w-[300px]">
            <i className="pi pi-search" />
            <InputText
              className="w-full"
              placeholder={"Search"}
              value={globalFilterLoc}
              onChange={onGlobalFilterLoc}
            />
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex w-full flex-col gap-y-8 rounded-xl border-solid border-light-purple bg-white px-8 py-10">
      <Toast ref={toast} />

      <div>
        <h3 className="font-philosopher text-title font-extrabold capitalize text-dark-purple">
          Order
          {orderDetails?.orderNumber
            ? ` #${orderDetails?.orderNumber} `
            : ""}{" "}
          Details
        </h3>
        {orderDetails?.serviceName && (
          <p className="text-gray-500">{orderDetails?.serviceName}</p>
        )}
      </div>
      <div className="overflow-hidden rounded-lg">
        <DataTable
          value={orderBillableItems}
          globalFilter={globalFilterValue}
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          {billableColumns.map((column) => {
            return (
              <Column
                key={column.field}
                field={column.field}
                header={column.header}
                editor={column.editor}
                body={column.body}
                editable
                sortable={column.sortable}
                className={column.className}
                headerClassName={column.className}
              />
            );
          })}
        </DataTable>
      </div>
      <div className="overflow-hidden rounded-lg">
        <DataTable
          value={orderLocations}
          globalFilter={globalFilterLoc}
          tableStyle={{ minWidth: "50rem" }}
          header={locationHeader}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          {LocationsColumns.map((column) => {
            return (
              <Column
                key={column.field}
                field={column.field}
                header={column.header}
                editor={column.editor}
                body={column.body}
                editable
                sortable={column.sortable}
              />
            );
          })}
        </DataTable>
      </div>
      {!orderDetails?.inbound && (
        <div>
          <div className="flex flex-col gap-2  ">
            <h4 className="text-xl font-semibold capitalize text-dark-purple">
              <i className={`pi pi-book text mr-2 text-xl `} />
              Order Summary
            </h4>
            <div className="grid grid-cols-12 gap-2 rounded-lg border border-slate-200 p-6 ">
              <p className="text-md col-span-12 text-justify text-gray-500 md:col-span-8">
                Please download the order summary for your records. This
                document includes all the information provided during the
                ordering process, including patient details, responses, and any
                other relevant data.
              </p>
              <div className="col-span-12 flex items-center justify-center text-center md:col-span-4 md:justify-end">
                <Button
                  label="Download summary"
                  icon="pi pi-download"
                  outlined
                  loading={isDownloadingSummary}
                  severity="info"
                  onClick={downloadOrderSummary}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;
