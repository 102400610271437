import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import useAxios from "../../hooks/useAxios";
import { Divider } from "primereact/divider";

function ServiceCart({
  cost,
  isTesting,
  cptBucket,
  serviceFess,
  maxFeesEntity,
  locationsBucket,
  selectedLoc,
  setSelectedLoc,
  serviceId,
  orderDocs,
}) {
  const toast = useRef(null);

  const { http } = useAxios();
  const [locationData, setLocationData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [locationsBucket, cptBucket, orderDocs]);

  const fetchData = async () => {
    try {
      await Promise.all([
        isTesting ? getFinalDocs(orderDocs) : null,
        getLocations(),
      ]);
    } catch (error) {
      console.error(error);
    }
  };
  const getLocations = async () => {
    let locationsFormatted = locationsBucket.map((id) => id.replace(/'/g, '"'));
    try {
      const getLocationsUrl = !isTesting
        ? `/Locations/GetServiceEnabledLocations/${serviceId}`
        : serviceId && locationsFormatted.length > 0
          ? `/Locations/GetAllServiceLocations/${serviceId}`
          : "/Locations/GetLocationsByIds";
      const response = await http.put(getLocationsUrl, locationsFormatted);

      setLocationData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFinalDocs = async (docsArr) => {
    if (docsArr.length > 0) {
      try {
        const response = await http.put(
          "/EntityDocuments/GetDocumentsByIds",
          docsArr,
        );
        setDocumentsData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const locationBodyTemplate = (locationData) => {
    return (
      <div className="flex ">
        {!isTesting && (
          <div key={locationData.id} className="flex items-center pr-2">
            <RadioButton
              inputId={locationData.id}
              name="location"
              value={locationData.id}
              onChange={(e) => {
                setSelectedLoc(e.value);
              }}
              checked={selectedLoc === locationData.id}
            />
          </div>
        )}
        <div className="px-2">
          <h4>
            <span
              className={`font-medium capitalize ${locationData.isEnabled ? "text-gray-600" : "text-gray-400"}`}
            >
              {locationData.name}{" "}
            </span>
            {!locationData.isEnabled && (
              <span className="text-sm italic text-gray-400">(disabled)</span>
            )}
          </h4>
          <p className="font-normal capitalize text-gray-500">{`${
            locationData.address.lineOne
          }, ${
            locationData.address.lineTwo
              ? locationData.address.lineTwo + ","
              : ""
          } ${locationData.address.city}, ${locationData.address.state} ${
            locationData.address.zipCode
          }`}</p>
        </div>
      </div>
    );
  };

  let totalCost = cost + (serviceFess / 100) * cost;
  let totalCostWithMax = cost + maxFeesEntity;
  let TotalFee = parseFloat((totalCost - cost)?.toFixed(2));

  // Calculate the total original cost of all rows
  const totalOriginalCost = (rows) =>
    rows.reduce((sum, row) => sum + row.chargeAmount * row.units, 0);

  // Distribute fee based on ratio
  const distributeFee = (rows, fee) => {
    const totalCost = totalOriginalCost(rows);
    return rows.map((row) => {
      const rowCost = row.chargeAmount * row.units;
      const proportion = rowCost / totalCost;
      const distributedFee = fee * proportion;
      return {
        ...row,
        distributedFee: distributedFee,
      };
    });
  };

  // Determine the fee to distribute
  let feeToDistribute = TotalFee > maxFeesEntity ? maxFeesEntity : TotalFee;
  let rowsWithDistributedFee = distributeFee(cptBucket, feeToDistribute);

  //show mods template
  const modTemplate = (rowData) => {
    const mods = [
      rowData.mod1,
      rowData.mod2,
      rowData.mod3,
      rowData.mod4,
    ].filter((mod) => mod);
    return mods.length ? mods.join(" | ") : "- - - - ";
  };

  // Cost template function
  const costTemplate = (rowData) => {
    const totalCost = rowData.chargeAmount * rowData.units;
    const totalCostWithFees = totalCost + rowData.distributedFee;
    let cost = isTesting ? totalCost : totalCostWithFees;

    return (
      <div className="">
        <span>${cost > 0 ? cost.toFixed(2) : 0}</span>
      </div>
    );
  };
  return (
    <div className="mt-5">
      <Toast ref={toast} />

      <div className="mb-5 font-inter">
        <h4 className="text-title font-extrabold capitalize text-dark-purple">
          cart Information
        </h4>
        <p>
          Below, you will find the detailed breakdown of your order, including
          the specified services, associated locations based on your order form
          responses, and the total charge.
        </p>
      </div>

      <div className="card flex flex-col gap-5">
        <div>
          <DataTable
            value={rowsWithDistributedFee}
            rowGroupMode="rowspan"
            groupRowsBy="id"
            sortField="id"
            sortOrder={1}
          >
            <Column
              key="shortDescription"
              field="shortDescription"
              header="Description"
              body={descriptionWithTooltipTemplate}
            />
            <Column field="hcpcs" header="HCPCS"></Column>
            <Column header="Mods" body={modTemplate}></Column>
            <Column field="units" header="Units"></Column>
            <Column header="Cost" body={costTemplate}></Column>
          </DataTable>

          <div className="flex flex-col gap-1 p-4 text-end">
            <div>
              <span className="font-bold">
                {isTesting
                  ? "Total Cost with fees excluded: "
                  : "Total Cost with fees included: "}
              </span>
              <span>$</span>
              <span>
                {isTesting
                  ? cost
                  : isNaN(totalCost)
                    ? 0
                    : TotalFee > maxFeesEntity
                      ? totalCostWithMax?.toFixed(2)
                      : totalCost.toFixed(2)}
              </span>
            </div>
            {isTesting && (
              <span className="text-xs italic text-gray-500">
                (Fees may vary based on the ordering provider)
              </span>
            )}
          </div>
        </div>
        <div>
          {!isTesting && (
            <h4 className="text-title font-extrabold capitalize text-dark-purple">
              Select Your preferred location
            </h4>
          )}
          <DataTable value={locationData}>
            <Column
              header="Available Locations"
              body={locationBodyTemplate}
            ></Column>
          </DataTable>

          {isTesting && (
            <>
              <Divider />
              <div className=" w-full">
                <h2 className="p-2 font-bold">Available Documents</h2>
                <DataTable
                  showGridlines
                  scrollable
                  pt={{
                    loadingOverlay: {
                      style: {
                        backgroundColor: "#b9b5ff60",
                      },
                    },
                  }}
                  scrollHeight="400px"
                  className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
                  value={documentsData}
                  emptyMessage={<p className="text-center">No results found</p>}
                >
                  <Column
                    field="documentTitle"
                    header="Title"
                    align="center"
                    alignHeader="center"
                    style={{ width: "30%" }}
                  />
                  <Column
                    field="documentDescription"
                    header="Description"
                    align="center"
                    alignHeader="center"
                    style={{ width: "60%" }}
                  />
                  <Column
                    header="Actions"
                    align="center"
                    alignHeader="center"
                    style={{ width: "10%" }}
                    body={(rowData, options) => (
                      <i
                        className="doc-action pi pi-eye cursor-pointer text-gray-500"
                        data-pr-tooltip="View Document"
                        data-pr-position="top"
                        onClick={() => {
                          window.open(rowData.documentPath, "_blank");
                        }}
                      ></i>
                    )}
                  />
                </DataTable>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceCart;
