import { Skeleton } from "primereact/skeleton";
import React from "react";

export const ServicesCardSkeleton = () => {
  return (
    <div className='p-5 bg-white mb-3'>
      <div className='flex justify-between align-center'>
        <Skeleton className='p-4 mb-3' width='15%' />
        <Skeleton size='30px' shape='circle' />
      </div>
      <div>
        <Skeleton className='p-3 mb-2' width='100%' />
        <Skeleton className='p-3 mb-2' width='100%' />
        <Skeleton className='p-3 mb-3' width='15%' />
      </div>
      <div>
        <Skeleton className='inline-block p-3 mr-2 mb-2' width='10%' />
        <Skeleton className='inline-block p-3 mb-2' width='10%' />
      </div>
    </div>
  );
};
