import React from "react";
import { Skeleton } from "primereact/skeleton";

export const FavoritePracticesCardSkeleton = () => {
  return (
    <div className='grid grid-rows-2 p-5 bg-white mb-2'>
      <div className='flex flex-row w-[100%] justify-center items-center mb-2'>
        <div className='w-[80px]'>
          <Skeleton className='m-auto' size='60px' shape='circle' />
        </div>
        <div className='flex-1'>
          <Skeleton className='p-3 mb-2' width='30%' />
          <Skeleton className='p-3 mb-2' width='50%' />
        </div>
      </div>
      <div>
        <Skeleton className='p-3 mb-2' width='80%' />
        <Skeleton className='p-3 mb-2' width='50%' />
      </div>
    </div>
  );
};
