import React, { useRef, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { NoteSkeleton } from "../skeletons/NoteSkeleton";
import {
  downloadFileFromResponse,
  formatDate,
  ORDERING_DROPDOWN_OPTIONS,
} from "../utility/Utils";
import useDelayedInputChange from "../../hooks/useDelayedInputChange";
import ReusableDataList from "../ReusableDataList/ReusableDataList";
import useHandleResize from "../../hooks/useHandleResize";

function OrderingFinalized() {
  const [menuvisible, setMenuVisible] = useState(false);
  const buttonRef = useRef(null);
  const { entityId } = useTokenData();
  const { http } = useAxios();
  const toast = useRef(null);
  const [refetch, setRefetch] = useState();
  const [noteData, setNoteData] = useState([]);
  const [note, setNote] = useState();
  const [storedRowData, setStoredRowData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentNotes, setCurrentNotes] = useState();
  const [showReferenceDialog, setShowReferenceDialog] = useState(false);
  const [referenceNumber, setRefrenceNunmber] = useState();
  const menuRight = useRef({});
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );
  const { isMobile } = useHandleResize();

  const handleScroll = () => {
    setMenuVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: menuvisible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const getCurrentNotes = async (rowData) => {
    const res = await http.get(`/Orders/GetNotesByOrderId/${rowData.id}`);
    setNoteData(res.data);
  };
  const sendNotes = async (rowData) => {
    await http.post(`/Orders/AddNoteToOrder/${rowData.id}`, {
      noteBody: note,
    });
    setRefetch(() => !refetch);
  };
  const handleSendAnotherNote = () => {
    setStoredRowData(storedRowData);
    setVisible(true);
    setCurrentNotes(false);
  };

  const deleteOrder = (rowData) => {
    confirmDialog({
      message:
        "By click on 'delete' button, this order will be deleted permanently.",
      header: "Delete This Order?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: async () => {
        try {
          await http.delete(`/Orders/${rowData.id}`);
          setRefetch(() => !refetch);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Deleted Successfully",
            life: 3000,
          });
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error.message || "Something went wrong. Please try again later",
            life: 3000,
          });
        }
      },
    });
  };

  const downloadResults = async (rowData) => {
    try {
      const response = await http.get(
        `
       /Orders/DownloadOrderResultByOrderId/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };

  const reorder = (rowData) => {
    confirmDialog({
      message: "Are you sure you want to reorder?",
      header: "Reorder?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Confirm",
      rejectLabel: "Cancel",
      accept: () => {
        navigate(`/ordering/RequestService/${rowData.serviceId}`);
      },
    });
  };

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status === 11 ? (
          <div className="inline rounded-md bg-[#FEE2E2] p-2 text-center text-sm text-[#AF0000] sm:block sm:p-3 sm:text-base">
            Canceled
          </div>
        ) : rowData.status === 2 ? (
          <div className="inline rounded-md bg-[#E7E7E7] p-2 text-center text-sm text-gray-600 sm:block sm:p-3 sm:text-base">
            Denied
          </div>
        ) : rowData.status === 12 ? (
          <div className="inline rounded-md bg-[#BFE7D2] p-2 text-center text-sm text-green-800 sm:block sm:p-3 sm:text-base">
            Resulted
          </div>
        ) : rowData.status === 4 ? (
          <div className="inline rounded-md bg-[#FEE2E2] p-2 text-center text-sm text-[#AF0000] sm:block sm:p-3 sm:text-base">
            Canceled By Ordering Provider
          </div>
        ) : (
          <div className="inline whitespace-nowrap rounded-md bg-[#FDBA74] p-2 text-center text-sm text-orange-900 sm:block sm:whitespace-normal sm:p-3 sm:text-base">
            Recalled
          </div>
        )}
      </>
    );
  };
  const [isReferenceNull, setIsReferenceNull] = useState();
  const canceledByTemplate = (rowData) => {
    return rowData.cancelledBy ? (
      <div className="text-center">{rowData.cancelledBy}</div>
    ) : (
      <div className="border-t-[1px] border-gray-600"></div>
    );
  };
  let isReferenceKeyExist;
  const handleShowReferenceDialog = (rowData) => {
    isReferenceKeyExist = Object.keys(rowData).includes("reference");
    if (!isReferenceKeyExist) return;
    setRefrenceNunmber(rowData.reference);
    setShowReferenceDialog(true);
  };

  const downloadPatientInfo = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    }
  };
  const getMenuItems = (rowData) => {
    const items = [];

    if (
      rowData.status === 4 ||
      rowData.status === 10 ||
      rowData.status === 11
    ) {
      if (rowData.reference !== null && rowData.reference !== undefined) {
        items.push({
          label: "Show Reference",
          icon: "pi pi-eye",
          command: () => handleShowReferenceDialog(rowData),
        });
      }
    } else if (rowData.status === 12) {
      items.push({
        label: "Download Results",
        icon: "pi pi-download",
        command: () => downloadResults(rowData),
      });

      items.push({
        label: rowData.hasNotes ? "Show Notes" : "Send Notes",
        icon: rowData.hasNotes ? "pi pi-eye" : "pi pi-send",
        command: () => {
          if (rowData.hasNotes) {
            setStoredRowData(rowData);
            setCurrentNotes(true);
            getCurrentNotes(rowData);
          } else {
            setStoredRowData(rowData);
            setVisible(true);
          }
        },
      });

      items.push({
        label: "Reorder",
        icon: "pi pi-sync",
        command: () => reorder(rowData),
      });
    }
    items.push({
      label: "Download Order Summary",
      icon: "pi pi-download",
      command: () => downloadPatientInfo(rowData),
    });

    if (rowData.status === 2) {
      items.push({
        label: "Delete",
        icon: "pi pi-trash",
        command: () => deleteOrder(rowData),
      });
    }

    return items;
  };
  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setMenuVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={menuvisible ? "" : "hidden"}
        />
      </>
    );
  };

  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> $ {rowData.charge} </h4>
    </>
  );

  const dropdownValues = {
    options: ORDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-lg font-bold">{row.serviceName}</p>
          <p className="text-lg font-bold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Patient Name:&nbsp;
          </span>
          {row.patientName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Canceled By:&nbsp;
          </span>
          {row.canceledBy}
        </p>
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <div className="flex items-center gap-2 font-semibold">
              <span className="font-semibold text-slate-500">
                Status:&nbsp;
              </span>
              {statusTemplate(row)}
            </div>
            {actionTemplate(row)}
          </div>
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option}
                  onChange={(e) => dropdownValues?.setSelectedValue(e.value)}
                  checked={dropdownValues.selectedValue === option.name}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      {/* Notes Dialogs */}
      <Dialog
        blockScroll
        draggable={false}
        header="Show Sent Notes"
        style={{ width: "50vw" }}
        visible={currentNotes}
        onHide={() => {
          setNoteData([]);
          setNote("");
          setCurrentNotes(false);
        }}
      >
        {noteData && (
          <>
            <div className="mt-2 rounded-md border border-gray-400 p-5">
              <div className="grid grid-cols-[1.25fr_3fr] items-center gap-2 border-b p-2 ">
                <p>Sent By</p>
                <p>Notes</p>
              </div>
              {noteData[0] ? (
                noteData?.map((note, i) => (
                  <div
                    key={i}
                    className="mt-2 grid grid-cols-[1.25fr_3fr] items-center gap-2 border-b p-2"
                  >
                    <p className="font-semibold">{note.sendBy}</p>
                    <p className="font-semibold">{note.noteBody}</p>
                  </div>
                ))
              ) : (
                <NoteSkeleton />
              )}
            </div>
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => setCurrentNotes(false)}
                className="mr-4 p-4 font-semibold text-gray-600"
              >
                Cancel
              </button>
              <Button
                onClick={handleSendAnotherNote}
                label="Send another note"
              />
            </div>
          </>
        )}
      </Dialog>

      {/* Refrence Number Dialog */}
      <Dialog
        blockScroll
        draggable={false}
        header="Reference Number"
        style={{ width: "50vw" }}
        visible={showReferenceDialog}
        onHide={() => {
          setShowReferenceDialog(false);
        }}
      >
        {referenceNumber}
        <p className="mt-3 w-[23em] rounded-[1em] bg-yellow-600 p-2 text-white">
          It may take 5-10 business days for funds to settle.
        </p>
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        header="Send Notes"
        style={{ width: "50vw" }}
        visible={visible}
        onHide={() => {
          setNote("");
          setVisible(false);
          setNoteData([]);
        }}
      >
        <div className="w-full">
          <InputTextarea
            style={{ width: "100%" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <div className="flex justify-between">
          <Button
            onClick={() => {
              setNote("");
              setVisible(false);
            }}
            label="Cancel"
          />
          <Button
            onClick={() => {
              if (note && note.length >= 8) {
                sendNotes(storedRowData);
                setVisible(false);
                setNoteData([]);
                setNote("");
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Note Cannot Be Less Than 8 Characters",
                  life: 3000,
                });
              }
            }}
            label="Send Notes"
          />
        </div>
      </Dialog>
      <ReusableDataList
        title={"List of orders"}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dropdownValues={dropdownValues}
        dataLink={`/Orders/GetOrderingPracticeOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==2|4|6|7|10|11|12`}
        columns={[
          {
            name: "Order Number",
            value: "orderTrackingNumber",
            style: { width: "10%" },
          },
          { name: "Service", value: "serviceName", style: { width: "14%" } },
          {
            name: "Charge",
            value: "charge",
            template: chargeTemplate,
            style: { width: "10%" },
          },
          { name: "Ordered By", value: "orderedBy", style: { width: "14%" } },
          {
            name: "Assigned To",
            value: "assignedProviderName",
            style: { width: "14%" },
          },
          { name: "Offered By", value: "offeredBy", style: { width: "15%" } },
          { name: "Date", value: "orderedDate", style: { width: "13%" } },
          {
            name: "Patient Name",
            value: "patientName",
            style: { width: "15%" },
          },
        ]}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [
                {
                  template: statusTemplate,
                  header: "Status",
                  style: { width: "13%" },
                },
                {
                  template: canceledByTemplate,
                  header: "Cancelled By",
                  style: { width: "13%" },
                },
                { template: actionTemplate, header: "Actions" },
              ]
        }
        refetch={refetch}
        filterTemplate={filterTemplate}
        setIsReferenceNull={setIsReferenceNull}
      />
    </>
  );
}

export default OrderingFinalized;
