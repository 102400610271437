import { useEffect, useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";

import CameraCapture from "./CameraCapture";
import LogoIcon from "../iconComponents/LogoIcon";
import ExpiredLink from "./services/manageOrderByProvider/ExpiredLink";
import InvalidLink from "./services/manageOrderByProvider/InvalidLink";

function CaptureViaMobile() {
  const [sessionDetails, setSessionDetails] = useState({});
  const [sessionStatus, setSessionStatus] = useState("pending");
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("id");

  useEffect(() => {
    getCaptureSessionDetails();
  }, []);

  const getCaptureSessionDetails = async () => {
    try {
      const response = await axios.get(`
/Identifications/GetCaptureIdentityViaMobileSession/${sessionId}`);
      setSessionDetails(response.data);
      setSessionStatus("valid");
    } catch (error) {
      if (error.response.data?.title) {
        setSessionStatus("invalid");
      } else {
        setSessionStatus("expired");
      }
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-5 bg-slate-50">
      <div className="flexitems-center justify-center">
        {sessionStatus === "pending" ? (
          <></>
        ) : sessionStatus === "valid" ? (
          <>
            <div className="w-full border-b p-5 shadow-sm">
              <LogoIcon />
            </div>
            <CameraCapture sessionData={sessionDetails} />
          </>
        ) : sessionStatus === "invalid" ? (
          <div className="flex h-full w-full items-center justify-center">
            <InvalidLink />
          </div>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <ExpiredLink />
          </div>
        )}
      </div>
    </div>
  );
}

export default CaptureViaMobile;
