import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import * as signalR from "@microsoft/signalr";

import RoleChange from "./RoleChange";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useStoreRenderCounter,
  useSwitchOrdering,
  useTokenStore,
  useCapturedPhoto,
} from "../../store";
import axios from "axios";

const SignalR = () => {
  const menuRight = useRef(null);
  const localStorageRefreshToken = localStorage.getItem("refreshToken");
  const data = JSON.parse(localStorageRefreshToken);
  const refreshTokenData = data.state.refreshToken;
  const { http } = useAxios();
  const [menuItems, setMenuItems] = useState([]);
  const { countRender, setCountRender } = useStoreRenderCounter();
  const { userId, entityId, SystemAdministrator } = useTokenData();
  const { token, setToken } = useTokenStore();
  const { refreshToken: currentRefreshToken, setRefreshToken } =
    useRefreshTokenStore();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { handleRoleChange } = RoleChange();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [animationClass, setAnimationClass] = useState("notification-bell");
  const { isOrderingMode } = useSwitchOrdering();
  const { setCapturedPhoto } = useCapturedPhoto();

  let hubConnection = null;

  const startConnection = () => {
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notify", {
        accessTokenFactory: () => {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    hubConnection
      .start()
      .then(() => {})
      .catch((err) => {});
  };

  const addTransferChartDataListener = () => {
    hubConnection.on("NotifyOrderTracking", (newData) => {
      if (entityId === newData.notifyTo) {
        if (newData.view === "Ordering") {
          setAnimationClass("notification-bell");
        } else if (newData.view === "Rendering") {
          setCountRender(newData.count);
        }
      }
    });

    hubConnection.on("NotifyUserTracking", (newData) => {
      if (entityId === newData.notifyTo) {
        if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Updated Success From Admin",
            life: 4000,
          });
        } else if (newData.action === "LogOut") {
          handleActionsChange();
        }
      }
    });

    hubConnection.on("NotifyProviderTracking", (newData) => {
      if (userId === newData.notifyTo) {
        if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Your provider verification has been updated.",
            life: 4000,
          });
        } else if (newData.action === "DisplayImage") {
          const imageDataUrl = newData.source;
          const binaryData = atob(imageDataUrl.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/png" });
          const file = new File([blob], "mobileCapturedPhoto.png", {
            type: "image/png",
          });
          const url = URL.createObjectURL(blob);
          setCapturedPhoto({
            file: file,
            url: url,
            filePropertyName: newData.propertyName,
            driverLicenseType: newData.side,
          });
        }
      }
    });

    hubConnection.on("NotifyDueDiligenceTracking", (newData) => {
      if (entityId === newData.notifyTo) {
        if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Updated Success From Admin",
            life: 4000,
          });
        }
      } else if (newData.notifyTo === null) {
        if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Updated Success From Admin",
            life: 4000,
          });
        }
      }
    });

    hubConnection.on("NotifyDisablePractice", (newData) => {
      if (entityId === newData.notifyTo) {
        if (newData.action === "LogOut") {
          handleActionsChange();
        } else if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Updated Success From Admin",
            life: 4000,
          });
        }
      }
    });
    hubConnection.on("ProviderTrackingNotification ", (newData) => {
      if (userId === newData.notifyTo) {
        if (newData.action === "RefreshToken") {
          handleRoleChange(refreshTokenData);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User Data Updated",
            life: 4000,
          });
        }
      }
    });
  };

  useEffect(() => {
    startConnection();
    addTransferChartDataListener();
    return () => {
      if (hubConnection) {
        hubConnection.off("NotifyOrderTracking");
        hubConnection.off("NotifyUserTracking");
        hubConnection.off("NotifyProviderTracking");
        hubConnection.off("NotifyDueDiligenceTracking");
        hubConnection.off("NotifyDisablePractice");
        hubConnection.off("ProviderTrackingNotification");
        hubConnection.stop();
      }
    };
  }, [token]);

  let getCountOrder;
  if (SystemAdministrator !== "SystemAdministrator") {
    getCountOrder = async () => {
      try {
        const response = await http.get(
          `/Orders/GetHoldedOrdersCount/${entityId}`,
        );
        setCountRender(response.data);
      } catch (error) {}
    };
  }

  const handleRenderNotification = () => {
    navigate("/rendering/Orders/");
  };

  let handleOrderNotificationGet;
  if (SystemAdministrator !== "SystemAdministrator") {
    handleOrderNotificationGet = async () => {
      try {
        const response = await http.get(
          `/Orders/GetOrdersNotifications/${entityId}`,
        );
        const menuItems =
          response.data.length > 0
            ? response.data.map((data, index) => ({
                label: (
                  <div className="">
                    <span className="leading-5">
                      Order {data.orderTrackingNumber} has been{" "}
                      {data.status === 8 ? "Accepted" : "Rejected"} by{" "}
                      {data.offeredBy}
                    </span>
                    <div className="mt-2 text-end">
                      <Button onClick={() => handleMarkAsRead(data)} outlined>
                        Mark as Read
                      </Button>
                    </div>
                  </div>
                ),
              }))
            : [
                {
                  label: <div className="p-2">No notifications</div>,
                  disabled: true,
                },
              ];
        setMenuItems(menuItems);

        if (response.data.length > 0) {
          setAnimationClass("notification-bell");
        } else {
          setAnimationClass("");
        }
      } catch (error) {}
    };
  }

  const handleMarkAsRead = async (data) => {
    try {
      await http.put(`/Notifications/${data.orderNotificationId}`, {
        isRead: true,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Notification marked as read",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
    handleOrderNotificationGet();
  };

  useEffect(() => {
    if (SystemAdministrator !== "SystemAdministrator") {
      getCountOrder();
      handleOrderNotificationGet();
    }
  }, []);

  const handleClickBellOrder = (event) => {
    if (!menuRight.current.getElement()) {
      handleOrderNotificationGet();
    }
    setAnimationClass("");
    menuRight.current.toggle(event);
  };

  const handleActionsChange = async () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Admin take new action",
      life: 4000,
    });

    try {
      await axios.post(
        "/Authentication/LogOut",
        { refreshToken: currentRefreshToken },
        {
          headers: { Authorization: `Bearer ${token}` },
          "Content-Type": "application/json",
        },
      );
    } catch (error) {}
    setToken(null);
    setRefreshToken(null);
    setEntityId(null);
    // setEntityType(null);

    setTimeout(() => {
      navigate(`/login`, { replace: true });
    }, 40000);
  };

  return (
    <>
      <Toast ref={toast} />
      <span className="ml-1 rounded-md px-3 py-2 text-sm font-medium text-normal-text">
        {!isOrderingMode ? (
          <div
            className="relative p-1"
            onClick={() => {
              handleRenderNotification();
            }}
          >
            {countRender > 0 ? (
              <div className="absolute -right-1 top-0 h-5 w-5 rounded-full bg-rose-600 text-center text-white">
                {countRender}
              </div>
            ) : null}
            <i className="pi pi-bell text-xl"></i>
          </div>
        ) : (
          <div className="notification">
            <i
              className={`pi pi-bell relative text-lg ${animationClass}`}
              onClick={(event) => {
                handleClickBellOrder(event);
              }}
            >
              <Menu
                model={menuItems}
                popup
                ref={menuRight}
                id="popup_menu_left"
              />
            </i>
          </div>
        )}
      </span>
    </>
  );
};

export default SignalR;
