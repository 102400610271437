import React, { useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import {
  useCurrentEntity,
  useSetUpGroupNextStep,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../store";
import DocumentUpload from "../formElements/DocumentUpload";
import { trimValidation } from "../utility/Utils";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";

function GroupPracticeNextStep() {
  const [isLoading, setIsLoading] = useState(false);
  const { setToken } = useTokenStore();
  const navigate = useNavigate();
  const toast = useRef(null);
  const { setCurrentEntity } = useCurrentEntity();

  const { setIdentifierId } = useSetUpGroupNextStep();
  const params = useParams();
  const [checkTrue, setCheckTrue] = useState();
  const { userId } = useTokenData();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { http } = useAxios();

  //   fetch state data from api
  const [selectedState, setSelectedState] = useState([]);
  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setSelectedState(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  const defaultValues = {
    Entity: {
      Name: "",
      DoingBusinessAsName: "",
      RegisteredStateName: "",
      EntityType: Number(params.entityType),
      TechnicalSupportName: "",
      TechnicalSupportPhoneNumber: "",
      TechnicalSupportEmail: "",
      EntityImageFile: {
        FileDetails: null,
        FilePropertyName: 3,
        FileType: 1,
      },
      Description: "",
    },
    Identification: {
      TaxID: "",
    },
    EntityPrincipalAddress: {
      LineOne: "",
      LineTwo: "",
      State: "",
      City: null,
      ZipCode: "",
    },
    RegisteredAgentAddress: {
      LineOne: "",
      LineTwo: "",
      State: "",
      City: null,
      ZipCode: "",
    },
    ApplicationUserId: userId,
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const UploadEntityImg = watch("Entity.EntityImageFile.FileDetails");

  const [checked, setChecked] = useState(false);
  const EntityPrincipalAddressLineOne = watch("EntityPrincipalAddress.LineOne");
  const EntityPrincipalAddressLineTwo = watch("EntityPrincipalAddress.LineTwo");
  const EntityPrincipalAddressCity = watch("EntityPrincipalAddress.City");
  const EntityPrincipalAddressZipCode = watch("EntityPrincipalAddress.ZipCode");
  const EntityPrincipalAddressState = watch("EntityPrincipalAddress.State");

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setChecked(true);
      setValue("RegisteredAgentAddress.LineOne", EntityPrincipalAddressLineOne);
      setValue("RegisteredAgentAddress.LineTwo", EntityPrincipalAddressLineTwo);
      setValue("RegisteredAgentAddress.City", EntityPrincipalAddressCity);
      setValue("RegisteredAgentAddress.ZipCode", EntityPrincipalAddressZipCode);
      setValue("RegisteredAgentAddress.State", EntityPrincipalAddressState);
    } else {
      setChecked(false);
      setValue("RegisteredAgentAddress.LineOne", "");
      setValue("RegisteredAgentAddress.LineTwo", "");
      setValue("RegisteredAgentAddress.City", "");
      setValue("RegisteredAgentAddress.ZipCode", "");
      setValue("RegisteredAgentAddress.State", null);
    }
  };

  const onSubmit = async (formData) => {
    if (checked) {
      formData.RegisteredAgentAddress.LineOne =
        formData.EntityPrincipalAddress.LineOne;

      formData.RegisteredAgentAddress.LineTwo =
        formData.EntityPrincipalAddress.LineTwo;

      formData.RegisteredAgentAddress.City =
        formData.EntityPrincipalAddress.City;

      formData.RegisteredAgentAddress.ZipCode =
        formData.EntityPrincipalAddress.ZipCode;

      formData.RegisteredAgentAddress.State =
        formData.EntityPrincipalAddress.State;
    }

    setIsLoading(true);
    const data = new FormData();
    data.append("Entity.Name", formData.Entity.Name);
    data.append(
      "Entity.DoingBusinessAsName",
      formData.Entity.DoingBusinessAsName || "",
    );
    data.append(
      "Entity.RegisteredStateName",
      formData.Entity.RegisteredStateName,
    );
    data.append("Entity.EntityType", formData.Entity.EntityType);
    data.append(
      "Entity.TechnicalSupportName",
      formData.Entity.TechnicalSupportName,
    );
    data.append(
      "Entity.TechnicalSupportPhoneNumber",
      formData.Entity.TechnicalSupportPhoneNumber,
    );
    data.append(
      "Entity.TechnicalSupportEmail",
      formData.Entity.TechnicalSupportEmail,
    );
    data.append(
      "Entity.EntityImageFile.FileDetails",
      formData.Entity.EntityImageFile.FileDetails,
    );
    data.append(
      "Entity.EntityImageFile.FilePropertyName",
      formData.Entity.EntityImageFile.FilePropertyName,
    );
    data.append(
      "Entity.EntityImageFile.FileType",
      formData.Entity.EntityImageFile.FileType,
    );
    data.append("Entity.Description", formData.Entity.Description);
    data.append("Identification.TaxID", formData.Identification.TaxID);
    data.append(
      "RegisteredAgentAddress.LineOne",
      formData.RegisteredAgentAddress.LineOne,
    );
    data.append(
      "RegisteredAgentAddress.LineTwo",
      formData.RegisteredAgentAddress.LineTwo || "",
    );
    data.append(
      "RegisteredAgentAddress.City",
      formData.RegisteredAgentAddress.City,
    );
    data.append(
      "RegisteredAgentAddress.State",
      formData.RegisteredAgentAddress.State,
    );
    data.append(
      "RegisteredAgentAddress.ZipCode",
      formData.RegisteredAgentAddress.ZipCode,
    );
    data.append(
      "EntityPrincipalAddress.LineOne",
      formData.EntityPrincipalAddress.LineOne,
    );
    data.append(
      "EntityPrincipalAddress.LineTwo",
      formData.EntityPrincipalAddress.LineTwo || "",
    );
    data.append(
      "EntityPrincipalAddress.City",
      formData.EntityPrincipalAddress.City,
    );
    data.append(
      "EntityPrincipalAddress.State",
      formData.EntityPrincipalAddress.State,
    );
    data.append(
      "EntityPrincipalAddress.ZipCode",
      formData.EntityPrincipalAddress.ZipCode,
    );

    data.append("ApplicationUserId", formData.ApplicationUserId);
    try {
      const response = await http.post(
        "/Identifications/CreateEntityWithIdentification",
        data,
      );
      setIdentifierId(response.data.identification.id);
      setEntityId(response.data.entity.id);
      setCurrentEntity(response.data.entity);

      const res = await http.post(
        `/Authentication/GetToken/${response.data.entity.id}/1`,
      );
      setToken(res.data.token);
      reset();

      navigate(`/setUpGroupFinishStep`, {
        replace: true,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };
  const handleCreatePractice = (formData) => {
    confirmDialog({
      className: "w-full md:w-[50vw]",
      message: (
        <p className="ml-3">
          <span className="font block font-Poppins font-bold">
            Do you really want to proceed with creating this{" "}
            <span className="text-light-purple">group practice?</span>
          </span>
          <div className="mt-7 font-Poppins font-medium text-gray-500">
            By confirming, you consent to the following:
            <div className="mt-3">
              - Create a new group practice, and all the provided information
              will be saved.
            </div>
            <div>- The provided information is accurate and complete.</div>
          </div>
        </p>
      ),
      icon: "pi pi-exclamation-triangle",
      position: "top",
      header: "Confirmation",
      acceptLabel: "Yes",
      rejectLabel: "No",
      accept: () => {
        onSubmit(formData);
      },
    });
  };
  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="flex h-full w-full items-center justify-center">
        <div className=" w-full rounded-3xl bg-light-text p-10  shadow shadow-slate-300  sm:w-full md:w-3/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4">
          <div className="mb-5">
            <h4 className="text-center font-philosopher text-title font-black capitalize text-dark-purple">
              Set up my Group practice
            </h4>
            <div className="flex flex-col items-center sm:flex-row">
              <div className="sm:flex-shrink-0">
                <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-slate-100 sm:h-14 sm:w-14">
                  <MedicalHistoryIcon />
                </div>
              </div>
              <div className="mt-5 text-justify sm:ml-5">
                <p className="w-full font-Poppins text-p leading-loose text-normal-text">
                  Welcome to the group practice setup. Fill out the required
                  details to create an group practice. If you wish to create a
                  individual practice, please{" "}
                  <span
                    className="cursor-pointer text-blue-700 underline"
                    onClick={() => navigate(-1)}
                  >
                    go back{" "}
                  </span>
                  and select the{" "}
                  <span className="text-base font-medium text-light-purple">
                    'set up my individual practice'
                  </span>{" "}
                  option.
                </p>
              </div>
            </div>
          </div>

          <div>
            <label className="mb-1 block font-Poppins text-base capitalize leading-loose text-gray-500">
              group Logo/Trademark
            </label>

            <div className="flex items-center sm:w-1/2 ">
              <DocumentUpload
                name="practiceLogo"
                fileType="image"
                maxFileSizeInMB={10}
                onUpload={(file) => {
                  setValue("Entity.EntityImageFile.FileDetails", file);
                }}
                onClear={() => {
                  setValue("Entity.EntityImageFile.FileDetails", "");
                }}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(handleCreatePractice)}>
            <div className="form-container mt-7">
              <div className="">
                <label
                  htmlFor="ssn"
                  className="font-Poppins text-base capitalize leading-loose text-gray-500"
                >
                  group Tax ID <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-right w-full">
                  <InputText
                    id="taxIDInput"
                    placeholder="Enter nine digit tax id number"
                    className="w-full pb-2 pt-2"
                    maxLength={17}
                    {...register("Identification.TaxID", {
                      required: "Tax ID is required",
                      pattern: {
                        value: /^(?:\d-?){9}$/,
                        message: "Tax ID must be exactly 9 digits",
                      },
                    })}
                  />
                </span>
                {errors.Identification && errors.Identification.TaxID && (
                  <p className="text-red-500">
                    {errors.Identification.TaxID.message}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                  Legal Entity Name <span className="text-red-600">*</span>
                </label>
                <span className=" w-full ">
                  <InputText
                    {...register("Entity.Name", {
                      required: "Legal Entity Name is required",
                      minLength: {
                        value: 3,
                        message:
                          "Legal Entity Name must be at least 3 characters long",
                      },
                      validate: (value) => trimValidation(value, "Name"),
                    })}
                    placeholder="Entity Name"
                    className="w-full"
                  />
                </span>
                {errors.Entity && errors.Entity.Name && (
                  <p className="mt-1 text-red-500">
                    {errors.Entity.Name.message}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500 ">
                  Doing Business as Name
                </label>
                <span className=" w-full">
                  <InputText
                    {...register("Entity.DoingBusinessAsName")}
                    placeholder="Doing Business as Name "
                    className="w-full"
                  />
                </span>
              </div>

              <div className="mt-9">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                  Entity Principle Address{" "}
                  <span className="text-red-600">*</span>
                </label>
                <span className=" w-full">
                  <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                    group address line one{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    {...register("EntityPrincipalAddress.LineOne", {
                      required: "Address is required",
                      validate: (value) =>
                        trimValidation(value, "Address Line One"),
                    })}
                    placeholder="Address Line One"
                    className="w-full pb-2 pt-2"
                  />
                </span>
                {errors.EntityPrincipalAddress &&
                  errors.EntityPrincipalAddress.LineOne && (
                    <p className="mt-1 text-red-500">
                      {errors.EntityPrincipalAddress.LineOne.message}
                    </p>
                  )}
                <span className=" mt-3 w-full">
                  <div className="flex justify-between">
                    <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                      group address line two
                    </label>
                    <p className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                      {" "}
                      (optional)
                    </p>
                  </div>
                  <InputText
                    {...register("EntityPrincipalAddress.LineTwo")}
                    placeholder="Address Line Two"
                    className="mt-1 w-full pb-2 pt-2"
                  />
                </span>

                <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ">
                  <span className=" col-span-1">
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      City <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      {...register("EntityPrincipalAddress.City", {
                        required: "City is required",
                        validate: (value) => trimValidation(value, "City"),
                      })}
                      placeholder="City"
                      className="w-full"
                    />
                    {errors.EntityPrincipalAddress &&
                      errors.EntityPrincipalAddress.City && (
                        <p className="mt-1 text-red-500">
                          {errors.EntityPrincipalAddress.City.message}
                        </p>
                      )}
                  </span>

                  <span className=" col-span-1 ">
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      State <span className="text-red-500">*</span>
                    </label>
                    <Controller
                      name="EntityPrincipalAddress.State"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          placeholder="State"
                          focusInputRef={field.ref}
                          options={selectedState.map((option) => ({
                            value: option.name,
                            label: option.name,
                          }))}
                          onChange={(e) => field.onChange(e.value)}
                          className="md:w-14rem w-full "
                        />
                      )}
                    />
                    {errors.EntityPrincipalAddress &&
                      errors.EntityPrincipalAddress.State && (
                        <p className="mt-1 text-red-500">State is required</p>
                      )}
                  </span>

                  <span className=" col-span-1 ">
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      zip Code <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      placeholder="ZIP Code"
                      className="w-full"
                      maxLength={5}
                      keyfilter="num"
                      {...register("EntityPrincipalAddress.ZipCode", {
                        required: "ZIP Code is required",
                        pattern: {
                          value: /^\d{5}$/,
                          message: "ZIP Code must be exactly 5 digits",
                        },
                        validate: (value) => trimValidation(value, "Zib code"),
                      })}
                    />
                    {errors.EntityPrincipalAddress &&
                      errors.EntityPrincipalAddress.ZipCode && (
                        <p className="mt-1 text-red-500">
                          {errors.EntityPrincipalAddress.ZipCode.message}
                        </p>
                      )}
                  </span>
                </div>
              </div>
              <div className="mt-7">
                <Checkbox
                  name="checkboxField"
                  onChange={handleCheckboxChange}
                  checked={checked}
                  disabled={
                    EntityPrincipalAddressLineOne &&
                    EntityPrincipalAddressCity &&
                    EntityPrincipalAddressZipCode &&
                    EntityPrincipalAddressState !== ""
                      ? false
                      : true
                  }
                  className="pb-5"
                />
                <label
                  htmlFor="address"
                  className="ml-1 font-medium text-light-purple"
                >
                  Make registered agent address same as entity principal address{" "}
                </label>
              </div>

              {!checked ? (
                <div className="mt-7">
                  <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                    Registered Agent Address
                    <span className="text-red-600">*</span>
                    <span className="ml-3"></span>
                  </label>
                  <span className=" w-full">
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      group Address Line One{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      {...register("RegisteredAgentAddress.LineOne", {
                        required: "Address is required",
                        validate: (value) =>
                          trimValidation(value, "Address Line One"),
                      })}
                      placeholder="Registered agent address line one"
                      className="mt-2 w-full pb-2 pt-2"
                    />
                    {errors.RegisteredAgentAddress &&
                      errors.RegisteredAgentAddress.LineOne && (
                        <p className="mt-1 text-red-500">
                          {errors.RegisteredAgentAddress.LineOne.message}
                        </p>
                      )}
                  </span>
                  <span className=" mt-3 w-full">
                    <div className="flex justify-between">
                      <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                        group address line two
                      </label>
                      <p className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                        {" "}
                        (optional)
                      </p>
                    </div>
                    <InputText
                      {...register("RegisteredAgentAddress.LineTwo")}
                      placeholder="Registered agent address line two"
                      className="mt-1 w-full pb-2 pt-2"
                    />
                  </span>

                  <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ">
                    <span className=" col-span-1">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        City <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        {...register("RegisteredAgentAddress.City", {
                          required: "City is required",
                          validate: (value) => trimValidation(value, "City"),
                        })}
                        placeholder="City"
                        className="w-full"
                      />
                      {errors.RegisteredAgentAddress &&
                        errors.RegisteredAgentAddress.City && (
                          <p className="mt-1 text-red-500">
                            {errors.RegisteredAgentAddress.City.message}
                          </p>
                        )}
                    </span>
                    <span className=" col-span-1 ">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        State <span className="text-red-500">*</span>
                      </label>
                      <Controller
                        name="RegisteredAgentAddress.State"
                        control={control}
                        rules={{ required: "Please select a state" }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            placeholder="State"
                            focusInputRef={field.ref}
                            options={selectedState.map((option) => ({
                              value: option.name,
                              label: option.name,
                            }))}
                            onChange={(e) => field.onChange(e.value)}
                            className="md:w-14rem w-full "
                          />
                        )}
                      />
                      {errors.RegisteredAgentAddress &&
                        errors.RegisteredAgentAddress.State && (
                          <p className="mt-1 text-red-500">
                            {errors.RegisteredAgentAddress.State.message}
                          </p>
                        )}
                    </span>

                    <span className=" col-span-1 ">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        ZIP Code <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        placeholder="ZIP Code"
                        className="w-full"
                        keyfilter="num"
                        {...register("RegisteredAgentAddress.ZipCode", {
                          required: "ZIP Code is required",
                          pattern: {
                            value: /^\d{5}$/,
                            message: "ZIP Code must be exactly 5 digits",
                          },
                          validate: (value) =>
                            trimValidation(value, "ZIP Code "),
                        })}
                        maxLength={5}
                      />
                      {errors.RegisteredAgentAddress &&
                        errors.RegisteredAgentAddress.ZipCode && (
                          <p className="mt-1 text-red-500">
                            {errors.RegisteredAgentAddress.ZipCode.message}
                          </p>
                        )}
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="mt-9">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                  entity Support Contact Information{" "}
                  <span className="text-red-600">*</span>
                </label>
                <span className=" w-full">
                  <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                    Name
                  </label>
                  <span className="p-input-icon-left w-full ">
                    <i className="pi pi-user" />
                    <InputText
                      {...register("Entity.TechnicalSupportName", {
                        required: "Name is required",
                        validate: (value) => trimValidation(value, "Name"),
                      })}
                      placeholder="Name"
                      className="w-full pb-2 pt-2"
                    />
                  </span>
                  {errors.Entity && errors.Entity.TechnicalSupportName && (
                    <p className="mt-1 text-red-500">
                      {errors.Entity.TechnicalSupportName.message}
                    </p>
                  )}
                </span>
                <label className="mt-1 block font-inter font-normal capitalize leading-loose text-gray-500">
                  Phone Number
                </label>
                <span className="p-input-icon-left w-full ">
                  <i className="pi pi-phone" />
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Phone Number"
                    onClick={(e) => {
                      if (e.target.value === "___-___-____") {
                        e.target.setSelectionRange(0, 0);
                      }
                    }}
                    name="Entity.TechnicalSupportPhoneNumber"
                    {...register("Entity.TechnicalSupportPhoneNumber", {
                      required: "Phone number is required",
                    })}
                    className="w-full pb-2 pt-2"
                  />
                </span>
                {errors.Entity && errors.Entity.TechnicalSupportPhoneNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.Entity.TechnicalSupportPhoneNumber.message}
                  </p>
                )}
                <label className="mt-1 block font-inter font-normal capitalize leading-loose text-gray-500">
                  Email
                </label>
                <span className="p-input-icon-left w-full ">
                  <i className="pi pi-envelope" />
                  <InputText
                    placeholder="Email "
                    name="Entity.TechnicalSupportEmail"
                    {...register("Entity.TechnicalSupportEmail", {
                      required: "Please enter your email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                    className="w-full pb-2 pt-2"
                  />
                </span>

                {errors.Entity && errors.Entity.TechnicalSupportEmail && (
                  <p className="mt-1 text-red-500">
                    {errors.Entity.TechnicalSupportEmail.message}
                  </p>
                )}

                <p className="text-md mt-6 text-gray-500">
                  * This contact information will be visible to providers that
                  order your services in the event that they have additional
                  questions.
                </p>
              </div>
              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    className="mt-9 rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                  >
                    next
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GroupPracticeNextStep;
