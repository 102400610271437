import React from "react";

export default function AdminDashboard() {
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="width-50 text-red-500 font-bold	">
          Admin Dashboard UNDER DEVELOPMENT!!
        </div>
      </div>
    </>
  );
}
