import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Editor } from "primereact/editor";
const QuestionForm = ({ question, updateQuestionData, onSubmitSuccess }) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);

  const defaultValues = {
    type: "",
    label: "",
    description: "",
    options: [
      {
        label: "",
      },
      {
        label: "",
      },
    ],
  };

  const [parsedQuestions, setParsedQuestion] = useState(defaultValues);
  const [selectedType, setSelectedType] = useState("");
  const [numberOfInputs, setNumberOfInputs] = useState(2); // Initial number of inputs
  const [valid, setValid] = useState(true);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: parsedQuestions,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  useEffect(() => {
    if (question) {
      const parsedData = JSON.parse(question.question);
      reset(parsedData);
      setParsedQuestion(parsedData);
      setSelectedType(parsedData.type);
      setNumberOfInputs(parsedData.options.length);
    }
  }, []);

  const questionTypes = [
    { label: "Multiple Choice", value: "multipleChoice" },
    { label: "Single Choice", value: "dropDown" },
    { label: "Number (whole)", value: "number" },
    { label: "Number (decimal)", value: "decimal" },
    { label: "Yes or No", value: "yesNo" },
  ];

  const editorHeaderTemplate = (
    <span className="ql-formats">
      <select className="ql-size" aria-label="Font Size"></select>
      <select className="ql-font" aria-label="Font"></select>
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>
      <select className="ql-color" aria-label="Text Color"></select>
      <select className="ql-background" aria-label="Text Highlight"></select>
      <button
        className="ql-list"
        value="ordered"
        aria-label="Text Number Marker"
      ></button>
      <button
        className="ql-list"
        value="bullet"
        aria-label="Text Points Marker"
      ></button>
      <select className="ql-align" aria-label="Text Alignment"></select>
    </span>
  );

  const watchedValues = watch();
  useEffect(() => {
    let isValid = true;
    for (let i = 0; i < watchedValues.options.length; i++) {
      const labelValue = watchedValues.options[i].label;
      if (!labelValue.trim()) {
        isValid = false;
        break;
      }
    }
    setValid(isValid);
  }, [watchedValues]);

  const onSubmit = async (data) => {
    if (!valid) {
      return;
    }

    try {
      setIsLoading(true);
      let response;
      if (question) {
        response = await http.put(`/Questions/EditQuestion/${question.id}`, {
          question: `${JSON.stringify(data)}`,
          isEnabled: question.isEnabled,
        });
        updateQuestionData(response.data);
        onSubmitSuccess();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.message,
          life: 4000,
        });
      } else {
        response = await http.post(`/Questions/AddQuestion/${entityId}`, {
          question: `${JSON.stringify(data)}`,
        });
        navigate("/service/ManageServices");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsLoading(false);
  };

  const renderAnswerFields = () => {
    const errorMessage =
      !valid &&
      (numberOfInputs <= 2
        ? "Please enter at least two answers"
        : "Fill in all fields or remove the empty ones.");

    switch (selectedType) {
      case "dropDown":
      case "multipleChoice":
        return (
          <div className="p-field">
            <label htmlFor="answers">Answers</label>
            {fields.map((item, index) => (
              <div key={item.id} className="">
                <div className="flex items-center">
                  <Controller
                    name={`options[${index}].label`}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: index < 2 || !valid,
                    }}
                    render={({ field }) => {
                      return <InputText {...field} className="m-2" />;
                    }}
                  />
                  {index === numberOfInputs - 1 && valid && (
                    <i
                      className="p-button-success pi pi-plus ml-2 cursor-pointer text-green-400"
                      onClick={() => {
                        append({ label: "" });
                        setNumberOfInputs(numberOfInputs + 1);
                      }}
                    ></i>
                  )}
                  {index > 1 && (
                    <i
                      className="p-button-success pi pi-trash ml-2 cursor-pointer text-red-400"
                      onClick={() => {
                        remove(index);
                        setNumberOfInputs(numberOfInputs - 1);
                      }}
                    ></i>
                  )}
                </div>
              </div>
            ))}
            <div className="errors-div">
              {!valid && <Message severity="error" text={errorMessage} />}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="absolute z-10  flex h-full w-[84%] items-center justify-center overflow-hidden bg-white bg-opacity-60 ">
          <ProgressSpinner
            strokeWidth={4}
            style={{ width: "40px", height: "40px" }}
          />
        </div>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid bg-white p-5">
        <div className="p-field">
          <label htmlFor="question" className="mb-2 block">
            Question
          </label>
          <InputText id="label" {...register("label", { required: true })} />
          {errors.label && (
            <Message severity="error" text="Question is required" />
          )}
        </div>

        <div className="p-field mt-4">
          <label htmlFor="type" className="mb-2 block">
            Type
          </label>
          <Controller
            name="type"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                {...field}
                options={questionTypes}
                optionLabel="label"
                optionValue="value"
                placeholder="Select a type"
                className={errors.type ? "p-invalid" : ""}
                onChange={(e) => {
                  const newType = e.value;
                  setSelectedType(newType);
                  field.onChange(newType);
                  if (question) {
                    setValue("options", [{ label: "" }, { label: "" }]);
                    setNumberOfInputs(2);
                  }
                  if (e.target.value == "yesNo") {
                    setValue("options", [{ label: "Yes" }, { label: "No" }]);
                  }
                  if (e.target.value == "number") {
                    setValue("options", [{ label: "num" }, { label: "num" }]);
                  } else if (!question) {
                    setValue("options", [
                      { label: parsedQuestions.options[0].label || "" },
                      { label: parsedQuestions.options[1].label || "" },
                    ]);
                  }
                }}
              />
            )}
          />
          {errors.type && <Message severity="error" text="Type is required" />}
        </div>

        {renderAnswerFields()}
        <div className="card mt-5 flex flex-col gap-2">
          <label>Question Description</label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Editor
                {...field}
                value={parsedQuestions.description}
                onTextChange={(e) => {
                  field.onChange(e.htmlValue);
                }}
                style={{ height: "164px" }}
                headerTemplate={editorHeaderTemplate}
              />
            )}
          />
        </div>
        <div className="mt-5 flex justify-end">
          <div>
            <Button
              type="submit"
              label={
                question ? "Edit Question Template" : "Add Question Template"
              }
              className="w-fit  justify-center  bg-light-purple font-inter capitalize text-light-text"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default QuestionForm;
