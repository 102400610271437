import useRefreshTokenHook from "../../hooks/useRefreshTokenHook";
import useTokenData from "../../hooks/useTokenData";
import { useChecklist, useRefreshTokenStore, useTokenStore } from "../../store";

function RoleChange() {
  const { entityId } = useTokenData();
  const { token, setToken } = useTokenStore();
  const { setShouldUpdateProgress } = useChecklist();
  const { refreshToken, setRefreshToken } = useRefreshTokenStore();
  const { refresh } = useRefreshTokenHook();

  const handleRoleChange = async () => {
    const localStorageRefreshToken = localStorage.getItem("refreshToken");
    const data = JSON.parse(localStorageRefreshToken);
    const refreshTokenData = data.state.refreshToken;
    try {
      const response = await refresh.post(
        `/Authentication/RefreshToken/${entityId}`,
        {
          refreshToken: refreshTokenData,
        },
      );
      setRefreshToken(response.data.refreshToken);
      setToken(response.data.token);
      setShouldUpdateProgress(true);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  return { handleRoleChange, token, refreshToken };
}

export default RoleChange;
