import { TabPanel, TabView } from "primereact/tabview";
import { VerificationWorklist } from "./verificationWorklist/VerificationWorklist";
import { useState } from "react";
import RemitsPending from "./pendingRemitsWorkList/RemitsPending";

export const WorkLists = () => {
  const [headerActiveIndex, setHeaderActiveIndex] = useState(0);
  const headerMenuItems = [
    { label: "Verification Worklist" },
    { label: "R.P. Remits Worklist" },
  ];
  return (
    <div className="rounded-t-lg">
      <TabView>
        <TabPanel header="Verification Worklist">
          <VerificationWorklist />
        </TabPanel>
        <TabPanel header="R.P. Remits Worklist">
          <RemitsPending />
        </TabPanel>
      </TabView>
    </div>
  );
};
